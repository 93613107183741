import { Breadcrumbs, LayoutReact } from '@fairwindsops/ui-components';

import React, { useEffect, useMemo, useState } from 'react';
import { IRoute, IRouter, IStore, OptionType } from '~utils/global.types.react';
import { orgBreadcrumbsList } from './RightSizing.config.react';
import { getCurrentTimezone, handlePageChange, pushURL } from '~utils/global.helpers.react';
import { ActionMeta, SingleValue } from 'react-select';

import { sendRequest } from '~utils/request';

import './RightSizing.react.scss';
import { RIGHT_SIZING, RIGHT_SIZING_CLUSTER } from '~reactComponents/NavigationReact/Navigation.config.react';
import AggregatorTable from '../CumulativeCosts/Components/AggregatorTable/AggregatorTable.react';
import useCostsData from '../CumulativeCosts/hooks/useCostsData.react';
import { CostsOptions } from '../CumulativeCosts/Costs.types.react';
import logger from '~utils/logger';
import { baseRequest, buildColumnsQuery, formatCost } from '../CumulativeCosts/Costs.helpers.react';
import dayjs from 'dayjs';
import { strings } from '~utils/strings';
import AggregatorBar from '../CumulativeCosts/Components/AggregatorBarData/AggregatorBarData.react';
import PodCountChart, { PodCountChartData } from './PodCountChart.react';
import WorkloadSelector from './WorkloadSelector.react';
import { ResourcesSummaryTimeseriesResponse } from './RightSizing.types.react';

export const topContainersAggregators = [
  { value: strings.noTranslate.cluster, label: strings.general.cluster },
  { value: strings.noTranslate.namespace, label: strings.efficiency.namespace },
  { value: strings.noTranslate.kind, label: strings.efficiency.kind },
  { value: strings.noTranslate.workload, label: strings.efficiency.workload },
  { value: strings.noTranslate.container, label: strings.efficiency.container },
];

export type RightSizingProps = {
  route: IRoute;
  router: () => IRouter;
  store: () => IStore;
};

const RightSizing = ({ store, route, router }: RightSizingProps) => {
  const org = store().getters.organization?.Name;
  const cluster = store().getters.cluster?.Name;
  const timeZone = getCurrentTimezone();

  const [selectedAggregators] = useState<(OptionType | CostsOptions)[]>([
    { value: 'cluster', label: 'cluster' },
    { value: 'namespace', label: 'namespace' },
    { value: 'workload', label: 'workload' },
    { value: 'container', label: 'container' },
    { value: 'kind', label: 'kind' },
  ]);

  const [hideTable, setHideTable] = useState(true);

  const [isSummaryLoading, setIsSummaryLoading] = useState(true);
  const [isSavingsAvailableLoading, setIsSavingsAvailableLoading] = useState(true);
  const [resourcesSummaryTimeseries, setResourcesSummaryTimeseries] =
    useState<ResourcesSummaryTimeseriesResponse | null>();
  const [isResourcesSummaryTimeseriesLoading, setIsResourcesSummaryTimeseriesLoading] = useState(true);

  const [refreshKey, setRefreshKey] = useState(0);

  const searchParams = new URLSearchParams(window.location.search);
  const isWorkloadSelected = ['clusters', 'namespaces', 'kinds', 'workloads'].every((key) => searchParams.has(key));

  // const oneMonthAgo = '2024-07-01T00:00:00.000Z';
  // const now = '2024-07-31T23:59:59.000Z';
  const oneMonthAgo = timeZone
    ? dayjs().subtract(1, 'month').startOf('day').utc().tz(timeZone).toISOString()
    : dayjs().subtract(1, 'month').startOf('day').utc().toISOString();
  const now = timeZone
    ? dayjs().endOf('day').utc().tz(timeZone).toISOString()
    : dayjs().endOf('day').utc().toISOString();

  searchParams.set('startDate', oneMonthAgo);
  searchParams.set('endDate', now);

  if (cluster) {
    searchParams.set('clusters', cluster);
  }

  const handleSelectChange = async (newValue: SingleValue<OptionType>, actionMeta: ActionMeta<OptionType>) => {
    if (!actionMeta.name) {
      return;
    }

    if (newValue) {
      const params = new URLSearchParams({
        [actionMeta.name]: newValue.value,
      });

      params.forEach((value, key) => {
        searchParams.set(key, value);
      });
    } else {
      // remove the key from the search params
      searchParams.delete(actionMeta.name);
    }

    setIsSummaryLoading(true);
    setIsSavingsAvailableLoading(true);

    setRefreshKey((cur) => cur + 1);

    pushURL({
      route,
      nameToMatch: cluster ? RIGHT_SIZING_CLUSTER : RIGHT_SIZING,
      searchParams: searchParams.toString(),
      router,
    });
  };

  const fetchFilterListOfValues = async (inputValue: string, type: string) => {
    const data = await sendRequest(
      'GET',
      `/v0/organizations/${org}/resources-filters?${searchParams.toString()}`,
      { showSuccessAlert: false, cache: false },
      null,
    );

    return data[type]
      .filter((data: string) => data.toLowerCase().includes(inputValue.toLowerCase()))
      .map((data: string) => ({
        label: data,
        value: data,
      }));
  };

  const {
    paramsWithAggregators,
    pageSize,
    orderBy,
    currentPage,
    setTableState,
    summaries,
    totals,
    selectFilters,
    selectAggregators,
    setResources,
    savingsAvailable,
    setSavingsAvailable,
  } = useCostsData(cluster, route);

  searchParams.set('pageSize', pageSize.toString());
  searchParams.set('orderBy', orderBy.orderBy);
  searchParams.set('orderByDesc', orderBy.orderByDesc);
  searchParams.set('currentPage', currentPage.toString());

  const finalTotals = useMemo(() => {
    const retrievedSavingsAvailable = savingsAvailable?.savingsAvailable || 0;
    const totalCost = totals?.totalCost || 0;

    return {
      avgBilled: totalCost / (totals?.numberOfRows || 1),
      totalBilled: totalCost,
      totalRecCosts: `${formatCost(retrievedSavingsAvailable)} (${
        retrievedSavingsAvailable !== 0 && totalCost !== 0
          ? Math.round((retrievedSavingsAvailable / totalCost) * 100)
          : 0
      }%)`,
      totalWithSavings: totalCost + retrievedSavingsAvailable,
    };
  }, [totals, savingsAvailable]);

  const fetchSummaryData = async () => {
    const dates = {
      start: searchParams.get('startDate') || oneMonthAgo,
      end: searchParams.get('endDate') || now,
    };

    const _searchParams = paramsWithAggregators(topContainersAggregators, {}, dates);
    const searchParamsWithColumns = buildColumnsQuery(new URLSearchParams(_searchParams));

    // merge searchParams into searchParamsWithColumns
    for (const [key, value] of searchParams) {
      searchParamsWithColumns.set(key, value);
    }

    let response;
    try {
      response = await baseRequest('resources-summary', org, searchParamsWithColumns);
    } catch (e) {
      logger.logError('error_retrieving_costs_summary', e);
    }

    setResources({
      summaries: response.resources || [],
      totals: response.totals || {
        totalCost: 0,
        costRecommendationTotal: 0,
        numberOfRows: 0,
      },
    });
    setIsSummaryLoading(false);
  };

  const formattedPodCountChartData: PodCountChartData[] = useMemo(() => {
    if (!resourcesSummaryTimeseries) {
      return [];
    }

    if (!isWorkloadSelected) {
      return [];
    }

    const timeseries = resourcesSummaryTimeseries.resources?.flatMap((r) => r.timeseries) || [];

    // average pod
    const avgDataSeries = timeseries.map((series) => {
      return {
        x: dayjs(series.time).format('YYYY-MM-DD'),
        y: series.resources.averagePodCount,
      };
    });
    return [
      {
        id: 'Average',
        data: avgDataSeries,
      },
    ];
  }, [resourcesSummaryTimeseries]);

  async function fetchCostsData() {
    searchParams.set('savingsAvailableOnly', 'true');
    const savings = await baseRequest('resources-total-costs', org, searchParams);
    setSavingsAvailable(savings);
    setIsSavingsAvailableLoading(false);
  }

  async function fetchResourcesSummaryTimeseries() {
    if (!isWorkloadSelected) {
      return;
    }

    const params = new URLSearchParams(searchParams);

    params.set('page', '0');
    params.set('pageSize', '99'); // fetch virtually all data for the time range
    params.append('aggregators', 'cluster');
    params.append('aggregators', 'namespace');
    params.append('aggregators', 'kind');
    params.append('aggregators', 'workload');
    params.set('addNetworkAndStorage', 'true');
    params.set('period', 'daily');

    try {
      const root = (await baseRequest(
        'resources-summary-timeseries',
        org,
        params,
      )) as ResourcesSummaryTimeseriesResponse;
      setResourcesSummaryTimeseries(root);
      setIsResourcesSummaryTimeseriesLoading(false);
    } catch (e) {
      logger.logError('error_resources_summary_timeseries', e);
    }
  }

  useEffect(() => {
    setIsSummaryLoading(true);
    setIsSavingsAvailableLoading(true);
    pushURL({
      route,
      nameToMatch: cluster ? RIGHT_SIZING_CLUSTER : RIGHT_SIZING,
      searchParams: searchParams.toString(),
      router,
    });
  }, [orderBy.orderBy, orderBy.orderByDesc, currentPage, pageSize]);

  const handleClearAll = () => {
    const allFilters = ['clusters', 'namespaces', 'kinds', 'workloads', 'containers'];

    allFilters.forEach((filter) => searchParams.delete(filter));

    setIsSummaryLoading(true);
    setIsSavingsAvailableLoading(true);

    pushURL({
      route,
      nameToMatch: cluster ? RIGHT_SIZING_CLUSTER : RIGHT_SIZING,
      searchParams: searchParams.toString(),
      router,
    });
  };

  useEffect(() => {
    Promise.all([fetchSummaryData(), fetchCostsData(), fetchResourcesSummaryTimeseries()]);
  }, [window.location.search]);

  return (
    <LayoutReact className="costs-settings">
      <Breadcrumbs
        data={orgBreadcrumbsList(org, cluster)}
        onClick={(route: string) => {
          handlePageChange(router, route);
        }}
      />
      <WorkloadSelector
        searchParams={searchParams}
        refreshKey={refreshKey}
        onLoadOptions={fetchFilterListOfValues}
        onSelectChange={handleSelectChange}
        onClearAll={handleClearAll}
      />
      <span>
        These filters match <b>{totals.numberOfRows}</b> running workloads (
        <a onClick={() => setHideTable((prev) => !prev)}>{hideTable ? 'show' : 'hide'}</a>). Narrow your criteria for
        more accurate recommendations.
      </span>
      {!hideTable && (
        <div className="costs__chart-section">
          <AggregatorTable
            route={route}
            router={router}
            aggregators={selectedAggregators}
            summaries={summaries}
            pageSize={pageSize}
            pageIndex={currentPage}
            setAggregators={selectAggregators}
            setFilters={selectFilters}
            setTableState={setTableState}
            totalResults={totals?.numberOfRows || 0}
            isLoading={isSummaryLoading}
          />
        </div>
      )}
      <div className="costs__chart-section">
        <AggregatorBar data={finalTotals} isLoading={isSavingsAvailableLoading} />
      </div>
      <div className="pods_count__chart-section">
        {isWorkloadSelected && (
          <PodCountChart data={formattedPodCountChartData} isLoading={isResourcesSummaryTimeseriesLoading} />
        )}
      </div>
    </LayoutReact>
  );
};

export default RightSizing;
