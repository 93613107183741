import React from 'react';
import { Controlled as CodeMirror } from '@leifandersen/react-codemirror2';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { Column } from 'react-table';
import { StylesConfig } from 'react-select';
import Tag from '~reactComponents/Tags/Tag.react';

import { Execution, Description } from './AutomationLogs.types.react';
import { showLogs } from './AutomationLogs.helpers.react';

import { formatStatus, getCurrentTimezone } from '~reactHelpers';

import 'codemirror/mode/javascript/javascript';
import { OptionType } from '~globalTypes';
import { strings } from '~utils/strings';

export const PAGE_SIZE = 25;

export const PAGE_SIZE_OPTIONS = [10, 25, 50, 100, 250];

dayjs.extend(timezone);

export const SELECT_STYLES: StylesConfig<OptionType, true> = {
  menu: (provided) => ({
    ...provided,
    width: 'fit-content',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: 0,
  }),
  control: (base) => ({
    ...base,
    borderRadius: 0,
  }),
  menuList: (base) => ({
    ...base,
    // kill the white space on first and last option
    padding: 0,
  }),
  multiValueRemove: (base) => ({ ...base, display: 'none' }),
};

export const TABLE_COLUMNS = (
  ColumnFiltering: any,
  ExecutionDatePicker: any,
): Column<Execution>[] => {
  return [
    {
      Header: 'Rule',
      accessor: 'name',
      className: 'no-overflow',
      Filter: ColumnFiltering,
      Cell: ({ value }: { value: string }) => <span title={value}>{value}</span>,
      width: 350,
      minWidth: 150,
      maxWidth: 500,
    },
    {
      Header: strings.Status,
      accessor: 'status',
      className: 'no-overflow',
      Filter: ColumnFiltering,
      Cell: ({ value }: { value: string }) => {
        const formattedStatus = formatStatus(value);
        return <span title={formattedStatus}>{formattedStatus}</span>;
      },
      width: 150,
      minWidth: 130,
      maxWidth: 200,
    },
    {
      Header: 'Date',
      accessor: 'startDate',
      className: 'no-overflow',
      Filter: ExecutionDatePicker,
      Cell: ({ value }: { value: string }) => {
        const timeZone = getCurrentTimezone();
        const formattedDate = timeZone
          ? dayjs(value).tz(timeZone).format('MM/DD/YYYY [at] HH:mm')
          : dayjs(value).format('MM/DD/YYYY [at] HH:mm');
        return <span title={formattedDate}>{formattedDate}</span>;
      },
      width: 200,
      minWidth: 130,
      maxWidth: 300,
    },
    {
      Header: 'Cluster',
      accessor: 'cluster',
      className: 'no-overflow',
      Filter: ColumnFiltering,
      Cell: ({ value }: { value: string }) => <span title={value}>{value}</span>,
      width: 150,
      minWidth: 130,
      maxWidth: 200,
    },
    {
      Header: 'Context',
      accessor: 'context',
      className: 'no-overflow',
      Filter: ColumnFiltering,
      Cell: ({ value }: { value: string }) => <span title={value}>{value}</span>,
      width: 150,
      minWidth: 130,
      maxWidth: 200,
    },
    {
      Header: 'Kind',
      accessor: 'kind',
      className: 'tag',
      Filter: ColumnFiltering,
      Cell: ({ value }: { value: string }) => <Tag tagClassNames="tag-ellipsis">{value}</Tag>,
      width: 170,
      minWidth: 130,
      maxWidth: 185,
    },
    {
      Header: 'Namespace',
      accessor: 'namespace',
      className: 'tag',
      Filter: ColumnFiltering,
      Cell: ({ value }: { value: string }) => <Tag tagClassNames="tag-ellipsis">{value}</Tag>,
      width: 170,
      minWidth: 130,
      maxWidth: 185,
    },
    {
      Header: 'Report',
      accessor: 'reportType',
      className: 'tag',
      Filter: ColumnFiltering,
      Cell: ({ value }: { value: string }) => <Tag tagClassNames="tag-ellipsis">{value}</Tag>,
      width: 170,
      minWidth: 130,
      maxWidth: 185,
    },
    {
      Header: 'Event Type',
      accessor: 'eventType',
      className: 'tag',
      Filter: ColumnFiltering,
      Cell: ({ value }: { value: string }) => <Tag tagClassNames="tag-ellipsis">{value}</Tag>,
      width: 170,
      minWidth: 130,
      maxWidth: 185,
    },
  ];
};

export const TOP_ROW = (execution: Execution, description: Description) => [
  { title: 'Report', content: execution.reportType },
  { title: 'Namespace', content: execution.namespace },
  { title: 'Kind', content: execution.kind },
  { title: 'Resource Name', content: description?.actionItem?.resource?.name },
  { title: 'Container', content: description?.actionItem?.resource?.container },
  { title: 'Fields Modified', content: description?.modifiedFields },
  { title: 'Events', content: description?.events },
];

export const CONTENT = (description: Description | undefined | null) => [
  {
    title: 'Logs',
    content: (
      <CodeMirror
        value={showLogs(description?.logs) as string}
        options={{
          tabSize: 4,
          mode: 'javascript',
          theme: 'material',
          lineNumbers: false,
          line: true,
          readOnly: true,
          autoFocus: true,
        }}
        onBeforeChange={() => {}}
      />
    ),
    shouldShow: description?.logs?.length,
  },
];

export const LOG_EVENT_TYPES = ['javascript_console_log', 'rule_runtime_error'];
