import React from 'react';
import { Button } from 'react-bootstrap';
import { Column, TableInstance } from 'react-table';
import { StylesConfig } from 'react-select';

import PillBadge from '~reactComponents/PillBadge/PillBadge.react';
import Tag from '~reactComponents/Tags/Tag.react';

import { RepositoryActionItem, RepoMetaDataParams } from './ActionItemsTable.types.react';

import { CommonLabels } from '~globalTypes';

import { strings } from '~utils/strings';
import { formatResolutionString } from '~views/organization/actionItems/ActionItems.helpers.react';
import dayjs from 'dayjs';
import { getCurrentTimezone } from '~utils/global.helpers.react';

export const PAGE_SIZE = 25;

export const PAGE_SIZE_OPTIONS = [10, 25, 50, 100, 250];

export const SELECT_STYLES: StylesConfig<unknown, true> = {
  menu: (provided) => ({
    ...provided,
    width: 'fit-content',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: 0,
  }),
  control: (base) => ({
    ...base,
    borderRadius: 0,
  }),
  menuList: (base) => ({
    ...base,
    // kill the white space on first and last option
    padding: 0,
  }),
  multiValueRemove: (base) => ({ ...base, display: 'none' }),
};

export const TABLE_COLUMNS = (
  ColumnFiltering: (tableInstance: TableInstance) => JSX.Element,
  showCreateTicketModal: (actionItem: RepositoryActionItem) => void,
): Column<RepositoryActionItem>[] => [
  {
    Header: 'Repository',
    accessor: 'repositoryName',
    className: 'no-overflow',
    Filter: ColumnFiltering,
    Cell: ({ value }: { value: string }) => <span title={value}>{value}</span>,
    width: 350,
    minWidth: 150,
    maxWidth: 500,
  },
  {
    Header: 'Severity',
    accessor: 'severityLabel',
    className: 'no-overflow',
    Filter: ColumnFiltering,
    Cell: ({ value }: { value: string }) => <PillBadge text={value} />,
    width: 150,
    minWidth: 130,
    maxWidth: 200,
  },
  {
    Header: 'Title',
    accessor: 'title',
    className: 'no-overflow',
    Filter: ColumnFiltering,
    Cell: ({ value }: { value: string }) => <span title={value}>{value}</span>,
    width: 300,
    minWidth: 130,
    maxWidth: 300,
  },
  {
    Header: 'Report',
    accessor: 'reportType',
    className: 'tag',
    Filter: ColumnFiltering,
    Cell: ({ value }: { value: string }) => <Tag tagClassNames="tag-ellipsis">{value}</Tag>,
    width: 150,
    minWidth: 130,
    maxWidth: 200,
  },
  {
    Header: 'Container',
    accessor: 'resourceContainer',
    className: 'no-overflow',
    Filter: ColumnFiltering,
    Cell: ({ value }: { value: string }) => <span title={value}>{value}</span>,
    width: 150,
    minWidth: 130,
    maxWidth: 200,
  },
  {
    Header: 'Kind',
    accessor: 'resourceKind',
    className: 'no-overflow',
    Filter: ColumnFiltering,
    Cell: ({ value }: { value: string }) => <span title={value}>{value}</span>,
    width: 170,
    minWidth: 130,
    maxWidth: 185,
  },
  {
    Header: 'Name',
    accessor: 'resourceName',
    className: 'no-overflow',
    Filter: ColumnFiltering,
    Cell: ({ value }: { value: string }) => <span title={value}>{value}</span>,
    width: 170,
    minWidth: 130,
    maxWidth: 185,
  },
  {
    Header: 'File Name',
    accessor: 'resourceFilename',
    className: 'no-overflow',
    Filter: ColumnFiltering,
    Cell: ({ value }: { value: string }) => <span title={value}>{value ? value : 'N/A'}</span>,
    width: 150,
    minWidth: 130,
    maxWidth: 200,
  },
  {
    Header: strings.actionItemsPage.filters.Resolution,
    accessor: strings.repository.historicalResolution,
    className: 'no-overflow',
    Filter: ColumnFiltering,
    Cell: ({ value }: { value: string }) => (
      <span title={formatResolutionString(value)}>{formatResolutionString(value)}</span>
    ),
    width: 150,
    minWidth: 130,
    maxWidth: 200,
  },
  {
    Header: 'Ticket',
    accessor: 'ticketCreated',
    Filter: ColumnFiltering,
    Cell: (instance: TableInstance) => {
      const { row } = instance;
      if (row.original.ticketCreated) {
        return (
          <Button
            variant="outline-primary"
            href={row.original.ticketLink}
            target="_blank"
            className="ai-create-ticket--actions"
          >
            View Ticket
          </Button>
        );
      } else {
        return (
          <Button
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              showCreateTicketModal(row.original);
            }}
            className="ai-create-ticket--actions"
          >
            Create Ticket
          </Button>
        );
      }
    },
    width: 175,
    minWidth: 130,
    maxWidth: 200,
  },
];

export const COLUMNS_ORDER = [
  strings.general.selection,
  'Repository',
  'Severity',
  'Title',
  'Report',
  'Container',
  'Kind',
  'Resource',
];

export const TOP_ROW = ({
  selectedActionItem,
  isMainBranch,
  latestActivities,
}: RepoMetaDataParams) => [
  { title: 'Repository', content: selectedActionItem.repositoryName },
  { title: 'Report', content: selectedActionItem.reportType },
  {
    title: 'Fixed',
    content: getFixedValue({ selectedActionItem, isMainBranch, latestActivities }),
    isHidden: isMainBranch,
  },
  { title: 'Kind', content: selectedActionItem.resourceKind },
  { title: 'Name', content: selectedActionItem.resourceName },
  { title: 'File Name', content: selectedActionItem.resourceFilename },
  {
    title: 'Resolution',
    content: formatResolutionString(selectedActionItem.historicalResolution),
  },
];

const getFixedValue = ({
  selectedActionItem,
  isMainBranch,
  latestActivities,
}: RepoMetaDataParams) => {
  if (isMainBranch) return CommonLabels.Empty;
  if (!latestActivities?.length) return CommonLabels.False;
  return latestActivities[0].FixedActionItems?.find((item) => item.ID === selectedActionItem.ID)
    ? CommonLabels.True
    : CommonLabels.False;
};

export const CONTENT = (selectedActionItem: RepositoryActionItem) => {
  const contents = [];

  let resolutionStatus = formatResolutionString(selectedActionItem.resolution);

  if (selectedActionItem.resolution === strings.snoozedResolution) {
    const timeZone = getCurrentTimezone();
    const snoozeUntil = timeZone
      ? dayjs(selectedActionItem.snoozeUntil).tz(timeZone).format('YYYY-MM-DD')
      : dayjs(selectedActionItem.snoozeUntil).format('YYYY-MM-DD');
    resolutionStatus += ` (until ${snoozeUntil})`;
  }

  if (selectedActionItem.resolution != '') {
    contents.push({
      title: strings.general.message,
      content: `${strings.columnTitles.ResolvedRepositoryMessage} **${resolutionStatus}**`,
    });
  }
  contents.push({ title: 'Description', content: selectedActionItem.description });
  contents.push({ title: 'Remediation', content: selectedActionItem.remediation });

  return contents;
};

export const EXPORT_ACTION_ITEMS_OPTIONS = [
  'Export all Action Items to CSV',
  'Export filtered Action Items to CSV',
];

export const EXPORT_FIELDS_MAP: Record<string, string> = {
  repositoryName: 'Repository',
  severityLabel: 'Severity',
  title: 'Title',
  reportType: 'Report',
  resourceContainer: 'Container',
  resourceKind: 'Kind',
  resourceName: 'Name',
  ticketCreated: 'Ticket',
};

export const POLLING_SCAN_STATUS_INTERVAL_TIME = 5000;
export const POLLING_SCAN_STATUS_TIMEOUT = 1800000;

export const SCAN_STATUS_MAP: Record<string, { title: string; cssClass: string }> = {
  not_processed: {
    title: 'Not Processed',
    cssClass: 'not-processed',
  },
  in_progress: {
    title: 'In Progress',
    cssClass: 'in-progress',
  },
  completed: {
    title: 'Completed',
    cssClass: 'completed',
  },
  error: {
    title: 'Error',
    cssClass: 'error',
  },
  abandoned: {
    title: 'Abandoned',
    cssClass: 'abandoned',
  },
};

export const STOP_POLLING_SCAN_STATUSES = ['completed', 'error', 'abandoned'];
