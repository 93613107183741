import React from 'react';
import Select, { StylesConfig } from 'react-select';
import clsx from 'clsx';

import { COLORS } from '~utils/styling';
import { strings } from '~utils/strings';
import { BasicText } from '~utils/texts.react';

import './SelectDropdown.react.scss';

type OptionType = {
  value: string;
  label: string;
};

type SelectProps = {
  label: string;
  options: OptionType[];
  handleChartTypeChange: (selected: unknown) => void;
  defaultValue?: OptionType;
};

const SelectDropdown = ({ label, options, handleChartTypeChange, defaultValue }: SelectProps) => {
  const customSelectStyles: StylesConfig<unknown, true> = {
    control: (styles) => ({ ...styles, borderStyle: 'none' }),
    singleValue: (styles) => ({ ...styles, color: COLORS.CORE.PRIMARY }),
    indicatorSeparator: () => ({ display: 'none' }),
    dropdownIndicator: (styles) => ({ ...styles, color: `${COLORS.CORE.PRIMARY} !important` }),
  };

  return (
    <Select
      aria-label={label}
      className={clsx(
        'select-dropdown-component',
        BasicText({ size: strings.textStyling.md, color: strings.textStyling.primary }),
      )}
      defaultValue={defaultValue || options[0]}
      options={options}
      isSearchable={false}
      onChange={handleChartTypeChange}
      name="chart-dropdown"
      styles={customSelectStyles}
    />
  );
};

export default SelectDropdown;
