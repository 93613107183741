<template>
  <component :is="component" v-bind:org="org"></component>
</template>

<script>
import Organization from '@/views/organization/index.vue';
import ErrorPage from '@/views/error/error.vue';

export default {
  props: {
    org: {
      type: String,
      required: true,
    },
  },
  computed: {
    component() {
      if (this.$store.getters.organization === null) {
        return 'ErrorPage';
      }

      return 'Organization';
    },
  },
  components: {
    Organization,
    ErrorPage,
  },
};
</script>
