import React, { useMemo } from 'react';

import { IStore, IRouter } from '~globalTypes';

import { strings } from '~utils/strings';
import { IS_PLG_UPGRADE_MODAL_SHOW } from '~utils/constants';
import { Breadcrumbs, LayoutReact } from '@fairwindsops/ui-components';
import { handlePageChange } from '~reactHelpers';
import { NEW_ORG } from '~reactComponents/NavigationReact/Navigation.config.react';

import './OrganizationsList.react.scss';

type OrganizationsListProps = {
  router: () => IRouter;
  store: () => IStore;
};

const OrganizationsList = ({ router, store }: OrganizationsListProps) => {
  const user = store().getters.user;
  const memberships = store().getters.memberships;
  const organization = store().getters.organization;

  const isNewButtonShown = useMemo(
    () => organization?.Tier !== 0 && !localStorage.getItem(IS_PLG_UPGRADE_MODAL_SHOW),
    [organization, store],
  );

  const getOrganizationRoute = (org: string) => {
    if (!org) {
      return null;
    }

    return router().resolve({
      name: 'default-org',
      params: { org },
    }).href;
  };

  const newOrganizationRoute = useMemo(() => router().resolve({ name: NEW_ORG }).href, [router]);

  const breadcrumbsList = [
    {
      id: 'last',
      label: strings.orgs.allOrganizations,
      href: ``,
      isActive: true,
    },
  ];

  return (
    <LayoutReact className="orgs">
      <div className="container-fluid top-nav sr-only">
        <h1>{strings.organizations.myOrganizations}</h1>
      </div>
      <div className="container-fluid pr-2rem">
        <div className="orgs__breadcrumbs">
          <Breadcrumbs
            data={breadcrumbsList}
            onClick={(route: string) => {
              handlePageChange(router, route);
            }}
          />
        </div>
        {!memberships?.length && user && (
          <div>
            <p>
              <b>{strings.organizations.youAreNotAMemberOfAnyOrg}</b>
            </p>
            <p>
              <span>{strings.organizations.askYourOrg.replace('$userEmail', user.Email)}</span>
              <a href={newOrganizationRoute}>{strings.organizations.createANewOrg.toLowerCase()}</a>
              <span>.</span>
            </p>
          </div>
        )}

        <div className="row gap-4">
          {memberships?.length ? (
            memberships.map((membership) => (
              <div className="col-12 col-md-4 col-lg-3 p-0">
                <div className="card mini-card mb-0">
                  <div className="card-body mixin">
                    <h2 className="card-title">
                      <a
                        className="details-link organization-name"
                        href={getOrganizationRoute(membership.Organization)}
                      >
                        {membership.Organization}
                      </a>
                    </h2>
                    <span className="float-right"></span>
                    {membership.IsOwner ? (
                      <i className="fa fa-fw fa-user-edit" aria-hidden="true"></i>
                    ) : (
                      <i className="fa fa-fw fa-user" aria-hidden="true"></i>
                    )}
                    <span className="ml-2">
                      {membership.IsOwner
                        ? strings.organizations.admin
                        : strings.organizations.viewer}
                    </span>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <></>
          )}
          {isNewButtonShown && (
            <div className="col-12 col-md-4 col-lg-3 p-0">
              <div className="card mini-card">
                <div className="card-body mixin">
                  <h2 className="card-title">
                    <a href={newOrganizationRoute} className="details-link">
                      <i className="fa fa-plus" aria-hidden="true"></i> {strings.organizations.new}
                    </a>
                  </h2>
                  <p className="card-text" data-cy="create-new-org">
                    {strings.organizations.createANewOrg}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </LayoutReact>
  );
};

export default OrganizationsList;
