import React, { useState, useEffect, useMemo } from 'react';
import { BulletTooltipProps, ResponsiveBullet } from '@nivo/bullet';

import { dropdownOptions, timeperiodOptions } from '../components.config.react';

import { Card } from '@fairwindsops/ui-components';
import SelectDropdown from '~reactComponents/SelectDropdown/SelectDropdown.react';
import LoadingSpinner from '~reactComponents/LoadingSpinner/LoadingSpinner.react';
import Legend from './Legend/Legend.react';
import ClusterBar from '../ClusterBar/ClusterBar.react';

import { BulletProps, ReportType } from '../../../Efficiency.types.react';
import { OptionType } from '~globalTypes';

import {
  findSingleClusterAverages,
  getAverage,
  formatDates,
  handleChartTypeChange,
  formatData,
  DisplaySyncData,
} from '../../../Efficiency.helpers.react';

import {
  CLUSTER_REPORT_HUB,
  NODE_EFFICIENCY,
  REPORT_HUB,
} from '~reactComponents/NavigationReact/Navigation.config.react';

import { sendRequest } from '~utils/request';
import { sortDescending } from '~utils/helpers';
import { hasKey } from '~reactHelpers';
import logger from '~logger';
import { strings } from '~utils/strings';
import { COLORS } from '~utils/styling';

import './ClusterBulletChart.react.scss';

const ClusterBulletChart = ({ data, router, route, baseURL }: BulletProps): JSX.Element => {
  const cluster = route?.params?.cluster;
  const org = route?.params?.org;
  const scaleOptions = [
    { value: 'consistent', label: strings.efficiency.consistent },
    { value: 'proportional', label: strings.efficiency.proportional },
  ];
  const [selectedDisplay, setSelectedDisplay] = useState<string>('cpu');
  const [timeperiod, setTimeperiod] = useState<string>('current');
  const [height, setHeight] = useState<number>(100);
  const [highestCount, setHighestCount] = useState<number>(0);
  const [syncDate, setSyncDate] = useState<string>('');
  const [prometheusDate, setPrometheusDate] = useState<string>('');
  const [awsDate, setAWSDate] = useState<string>('');
  const [cloudCostsDate, setCloudCostsDate] = useState<string>('');
  const [scale, setScale] = useState<OptionType>({ value: 'consistent', label: 'Consistent' });

  useEffect(() => {
    const getDate = async (report: string) => {
      let response = null;
      try {
        response = await sendRequest('GET', `${baseURL}/reports/${report}/detail`, {}, null);
      } catch (e) {
        logger.logError(`error_retrieving_${report}_report`, e);
      }
      return response;
    };

    const getWorkloadsDate = async () => {
      let response = null;
      try {
        response = await sendRequest('GET', `/v0/organizations/${org}/reports/latest`, {}, null);
      } catch (e) {
        logger.logError(`error_retrieving_latest_reports`, e);
      }
      return response;
    };

    const updateSyncData = async () => {
      const allReports = await getWorkloadsDate();
      const filteredReports = allReports.filter((report) => report.ReportType === strings.workloads);

      if (!cluster) {
        if (!filteredReports.length) {
          setSyncDate(strings.efficiency.installWorkloads);
        } else {
          const datesSorted = filteredReports.sort((a, b) => sortDescending(a.UpdateTime, b.UpdateTime));
          const { day, time } = formatDates(datesSorted[0].UpdateTime);
          setSyncDate(`${day} at ${time}`);
        }
      }

      if (cluster) {
        const prometheus: ReportType = await getDate('prometheus-metrics');
        const aws: ReportType = await getDate('awscosts');
        const cloudCosts: ReportType = await getDate('cloudcosts');
        const reportTime = filteredReports.filter((report) => report.Cluster === cluster);

        if (!reportTime[0]) {
          setSyncDate(strings.efficiency.installWorkloads);
        } else {
          const { day, time } = formatDates(reportTime[0].UpdateTime);
          setSyncDate(`${day} at ${time}`);
        }

        if (prometheus && prometheus?.Timestamp) {
          const { day, time } = formatDates(prometheus.Timestamp);
          setPrometheusDate(`${day} at ${time}`);
        } else {
          setPrometheusDate(strings.efficiency.installPrometheusReport);
        }

        if (aws && aws?.Timestamp) {
          const { day, time } = formatDates(aws.Timestamp);
          setAWSDate(`${day} at ${time}`);
        }

        if (cloudCosts && cloudCosts?.Timestamp) {
          const { day, time } = formatDates(cloudCosts.Timestamp);
          setCloudCostsDate(`${day} at ${time}`);
        } else {
          setCloudCostsDate(strings.efficiency.cloudCostsNotInstalled);
        }
      }
    };

    updateSyncData();
  }, [data, cluster]);

  const clusterData = useMemo(() => {
    const keys: string[] = Object.keys(data);

    if (timeperiod === 'average') {
      return keys.map((clusterName) => {
        // gets an array of daily averages for what is needed for this chart and the cluster chart & bar
        const averages = findSingleClusterAverages(data[clusterName], selectedDisplay);
        // creates monthly averages out of daily averages
        let usageAvg, capacityAvg, requestsAvg, limitsAvg, costsAvg;

        if (Array.isArray(averages.inUse)) usageAvg = getAverage(averages.inUse);
        if (Array.isArray(averages.available)) capacityAvg = getAverage(averages.available);
        if (Array.isArray(averages.requests)) requestsAvg = getAverage(averages.requests);
        if (Array.isArray(averages.limits)) limitsAvg = getAverage(averages.limits);
        // don't need to do anything else, below provides total cost for a month
        if (Array.isArray(averages.cost)) {
          costsAvg = averages.cost.length ? averages.cost.reduce((sum, day) => sum + day) : 0;
        } else {
          costsAvg = 0;
        }

        return {
          id: clusterName,
          costs: costsAvg,
          requests: requestsAvg || 0,
          inUse: usageAvg || 0,
          limits: limitsAvg || 0,
          available: capacityAvg || 0,
          date: averages.date,
        };
      });
    } else if (cluster && timeperiod === 'current') {
      const mostRecentData = data[cluster][data[cluster].length - 1];

      if (mostRecentData) {
        return [
          {
            id: cluster,
            costs: mostRecentData.nodes.totalCost || 0,
            requests: hasKey(mostRecentData.requests, selectedDisplay)
              ? mostRecentData.requests[selectedDisplay].raw
              : 0,
            inUse: hasKey(mostRecentData.inUse, selectedDisplay) ? mostRecentData.inUse[selectedDisplay].raw : 0,
            limits: hasKey(mostRecentData.limits, selectedDisplay) ? mostRecentData.limits[selectedDisplay].raw : 0,
            available: hasKey(mostRecentData.allocatable, selectedDisplay)
              ? mostRecentData.allocatable[selectedDisplay].raw
              : 0,
            date: mostRecentData.reportTimestamp,
          },
        ];
      }
    }
    return [];
  }, [cluster, data, selectedDisplay, timeperiod]);

  const formattedData = useMemo(() => {
    const keys: string[] = Object.keys(data);
    let maxAllocatable = 0;
    let allData: any[] = [];
    setHeight(cluster ? 120 : 100 * keys.length);

    allData = keys.map((clusterName) => {
      if (hasKey(data, clusterName)) {
        if (timeperiod === 'average') {
          const foundCluster = clusterData.find((datum) => datum.id === clusterName);

          if (foundCluster && foundCluster.available > 0) {
            if (foundCluster.available > maxAllocatable) {
              maxAllocatable = foundCluster.available;
            } else if (foundCluster.limits / 30 > maxAllocatable) {
              maxAllocatable = foundCluster.limits / 30;
            }

            setHighestCount(formatData(maxAllocatable * 1.1, selectedDisplay));

            return {
              id: clusterName,
              ranges: [
                0,
                foundCluster.inUse > 1 ? formatData(foundCluster.inUse / 30, selectedDisplay) : -1,
                formatData(foundCluster.available, selectedDisplay),
              ],
              measures: [formatData(foundCluster.requests / 30, selectedDisplay)],
              markers: [formatData(foundCluster.limits / 30, selectedDisplay)],
            };
          } else {
            setHighestCount(100);
            return {
              id: clusterName,
              ranges: [0, -1, -2, scale.value === 'proportional' ? 1 : 0],
              measures: [],
              markers: [],
            };
          }
        } else {
          const clusterData = data[clusterName];
          const latestClusterData = clusterData[clusterData.length - 1];

          if (
            latestClusterData &&
            hasKey(latestClusterData.allocatable, selectedDisplay) &&
            clusterData.length &&
            latestClusterData.allocatable[selectedDisplay].raw > maxAllocatable
          ) {
            maxAllocatable = latestClusterData?.allocatable[selectedDisplay]?.raw;
          }
          if (
            latestClusterData &&
            hasKey(latestClusterData.limits, selectedDisplay) &&
            clusterData.length &&
            latestClusterData.limits[selectedDisplay].raw > maxAllocatable
          ) {
            maxAllocatable = latestClusterData.limits[selectedDisplay].raw;
          }
          setHighestCount(maxAllocatable > 100 ? formatData(maxAllocatable * 1.1, selectedDisplay) : 100);

          let response;

          if (clusterData.length) {
            let usageMinimum = 0;
            if (hasKey(latestClusterData.inUse, selectedDisplay))
              usageMinimum =
                latestClusterData.inUse[selectedDisplay].raw > 0 ? latestClusterData.inUse[selectedDisplay].raw : 1;
            if (
              hasKey(latestClusterData.allocatable, selectedDisplay) &&
              latestClusterData.allocatable[selectedDisplay].raw > 0
            ) {
              response = {
                id: clusterName,
                ranges: [
                  0,
                  usageMinimum > 1 ? formatData(latestClusterData.inUse[selectedDisplay].raw, selectedDisplay) : -1,
                  formatData(latestClusterData.allocatable[selectedDisplay].raw, selectedDisplay),
                ],
                measures: [formatData(latestClusterData.requests[selectedDisplay].raw, selectedDisplay)],
                markers: [formatData(latestClusterData.limits[selectedDisplay].raw, selectedDisplay)],
              };
            }
          } else {
            response = {
              id: clusterName,
              ranges: [0, -1, -2, scale.value === 'proportional' ? 1 : 0],
              measures: [],
              markers: [],
            };
          }

          return response;
        }
      }
    });

    return allData;
  }, [data, selectedDisplay, cluster, timeperiod, scale.value]);

  const CustomTooltip = (point: BulletTooltipProps) => {
    if (!point) {
      return null;
    }
    let displayedTooltip;
    const units = selectedDisplay === 'cpu' ? strings.general.CPU : strings.general.GB;
    let label;

    if (point.v0 === 0) {
      label = `${strings.efficiency.Usage}${strings.punctuation.colon} `;
    } else if (point.color === COLORS.CHARTS.BULLET.RANGE_3) {
      label = strings.general.na;
    } else {
      label = `${strings.navigation.Capacity}${strings.punctuation.colon} `;
    }
    if (point.v1 && label !== 'N/A') {
      displayedTooltip = (
        <span>
          <strong>{label}</strong>
          <strong>{`${point.v1.toFixed(2)} ${units}`}</strong>
        </span>
      );
    } else if (!data.v1 && point.color === COLORS.CORE.DANGER) {
      displayedTooltip = (
        <strong>{`${strings.general.Limits}${strings.punctuation.colon} ${point.v0.toFixed(2)}${units}`}</strong>
      );
    } else if (!data.v1 && point.color === COLORS.CHARTS.BULLET.MEASURE) {
      displayedTooltip = (
        <strong>{`${strings.general.Requests}${strings.punctuation.colon} ${point.v0.toFixed(2)}${units}`}</strong>
      );
    } else {
      displayedTooltip = <strong>{label}</strong>;
    }
    return (
      <div className="resource-usage__tooltip">
        <div className="tooltip-container">
          <div className="node-bullet-legend__icon" style={{ backgroundColor: point.color }} />
          <div>{displayedTooltip}</div>
        </div>
      </div>
    );
  };

  const BulletChart = (): JSX.Element => {
    return (
      <ResponsiveBullet
        data={formattedData.map((datum) => {
          if (datum) {
            const nodeEfficiencyRoute =
              !cluster &&
              router().resolve({
                name: NODE_EFFICIENCY,
                params: { cluster: datum.id, org: route?.params?.org },
              }).href;

            const formattedTitle: JSX.Element[] = [];
            let title = '';
            const formattedSubtitle: JSX.Element[] = [];
            const splitTitle = datum.id.slice(0, 24).split('');

            splitTitle.forEach((letter: string, index: number) => {
              if (index > 0 && index % 24 === 0) {
                title += letter;
                formattedTitle.push(
                  <text dy={100} className="bullet-title" key={`${datum.id}-${index}`}>
                    <a
                      href={nodeEfficiencyRoute}
                      title={datum.id}
                      style={{ fill: COLORS.CORE.PRIMARY, fontWeight: 500 }}
                    >
                      {title}
                    </a>
                  </text>,
                );
                title = '';
              } else if (index > 0 && index === splitTitle.length - 1) {
                const ellipsis = datum.id.length > 24 ? '...' : '';
                title += letter + ellipsis;

                formattedTitle.push(
                  <text dy={5} dx={-70} className="bullet-title" key={`${datum.id}-${index + 1}`}>
                    <a
                      href={nodeEfficiencyRoute}
                      title={datum.id}
                      style={{ fill: COLORS.CORE.PRIMARY, fontWeight: 500 }}
                    >
                      {title}
                    </a>
                  </text>,
                );
              } else {
                title += letter;
              }
              if (!datum.measures.length && !datum.markers.length) {
                formattedSubtitle.push(
                  <text dy={5} dx={200} key={`${datum.id}-${index}-no-data`}>
                    <tspan>{strings.efficiency.notEnoughData}</tspan>
                  </text>,
                );
              }
            });
            return {
              ...datum,
              title: (
                <>
                  {formattedTitle}
                  {formattedSubtitle}
                </>
              ),
            };
          }
        })}
        maxValue={scale.value === 'consistent' ? highestCount : 'auto'}
        margin={{ top: 35, right: 20, bottom: 50, left: 240 }}
        spacing={45}
        titleAlign="start"
        titleOffsetX={-150}
        measureSize={0.2}
        rangeColors={[COLORS.CHARTS.BULLET.RANGE_1, COLORS.CHARTS.BULLET.RANGE_2, COLORS.CHARTS.BULLET.RANGE_3]}
        measureColors={COLORS.CHARTS.BULLET.MEASURE}
        markerColors={COLORS.CORE.DANGER}
        markerSize={0.8}
        tooltip={CustomTooltip}
      />
    );
  };

  const syncData = useMemo(() => {
    const data = [
      {
        date: syncDate,
        report: 'Workloads',
        paramReport: strings.workloads,
        name: REPORT_HUB,
      },
      {
        date: prometheusDate,
        report: 'Prometheus',
        paramReport: 'prometheus-metrics',
        name: REPORT_HUB,
      },
    ];

    if (awsDate) {
      data.push({ date: awsDate, report: 'AWS-Costs', paramReport: '', name: CLUSTER_REPORT_HUB });
    } else if (cloudCostsDate) {
      data.push({
        date: cloudCostsDate,
        report: 'Cloud-Costs',
        paramReport: '',
        name: CLUSTER_REPORT_HUB,
      });
    }

    return data;
  }, [syncDate, prometheusDate, awsDate, cloudCostsDate]);

  return (
    <>
      {cluster && (
        <div>
          {syncDate && (cloudCostsDate || awsDate) && prometheusDate && (
            <div className="synced-div">
              <DisplaySyncData syncData={syncData} route={route} router={router} />
            </div>
          )}
          <Card data-cy="efficiency-cluster-card">
            <Card.Body style={{ padding: '0 0 1rem 0' }}>
              <Card.Header
                style={{
                  borderBottom: COLORS.BORDER.TABLE_HEADER,
                  padding: '0.5rem 0 0.5rem 1.25rem',
                }}
              >
                <h1 className="node-bullet-chart-title">
                  {cluster ? strings.efficiency.clusterResources : strings.efficiency.clusterComparison}
                </h1>
                <div className="node-bullet-chart-dropdown">
                  <div className="node-bullet-chart-dropdown__scale-container">
                    <SelectDropdown
                      label={strings.ariaLabels.scaleDropdown}
                      options={scaleOptions}
                      handleChartTypeChange={(value) => setScale(value as OptionType)}
                    />
                  </div>
                  <div className="node-bullet-chart-dropdown__timeperiod-container">
                    <SelectDropdown
                      label={strings.ariaLabels.timeperiodDropdown}
                      options={timeperiodOptions}
                      handleChartTypeChange={(value) => handleChartTypeChange(value, setTimeperiod)}
                    />
                  </div>
                  <div className="node-bullet-chart-dropdown__container">
                    <SelectDropdown
                      label={strings.ariaLabels.dataDropdown}
                      options={dropdownOptions}
                      handleChartTypeChange={(value) => handleChartTypeChange(value, setSelectedDisplay)}
                    />
                  </div>
                </div>
              </Card.Header>
              {(!syncDate || !cloudCostsDate || !prometheusDate) && (
                <div className="node-bullet-chart-loading-spinner">
                  <LoadingSpinner />
                </div>
              )}
              {formattedData.length > 0 && syncDate && (cloudCostsDate || awsDate) && prometheusDate && (
                <div className="node-bullet-chart-container">
                  <div
                    style={{
                      height: `${height}px`,
                      width: 'auto',
                      overflow: 'auto',
                      marginBottom: '2.5rem',
                    }}
                    aria-label={strings.ariaLabels.clusterBulletChart}
                    role="img"
                  >
                    <BulletChart />
                  </div>
                  <Legend display={selectedDisplay} />
                </div>
              )}
            </Card.Body>
            {clusterData.length > 0 && clusterData[0].available > 0 && (
              <ClusterBar data={clusterData[0]} display={selectedDisplay} timeperiod={timeperiod} />
            )}
          </Card>
        </div>
      )}
      {!cluster && (
        <div>
          {syncDate && (
            <div className="synced-div">
              <DisplaySyncData
                syncData={[
                  {
                    date: syncDate,
                    report: 'Workloads',
                    paramReport: strings.workloads,
                    name: REPORT_HUB,
                  },
                ]}
                route={route}
                router={router}
              />
            </div>
          )}
          <Card data-cy="efficiency-cluster-card">
            <Card.Body style={{ padding: '0 0 1rem 0' }}>
              <Card.Header
                style={{
                  borderBottom: COLORS.BORDER.TABLE_HEADER,
                  padding: '0.5rem 0 0.5rem 1.25rem',
                }}
              >
                <h1 className="node-bullet-chart-title">
                  {cluster ? strings.efficiency.clusterResources : strings.efficiency.clusterComparison}
                </h1>
                <div className="node-bullet-chart-dropdown">
                  <div className="node-bullet-chart-dropdown__scale-container">
                    <SelectDropdown
                      label={strings.ariaLabels.scaleDropdown}
                      options={scaleOptions}
                      handleChartTypeChange={(value) => setScale(value as OptionType)}
                    />
                  </div>
                  <div className="node-bullet-chart-dropdown__timeperiod-container">
                    <SelectDropdown
                      label={strings.ariaLabels.timeperiodDropdown}
                      options={timeperiodOptions}
                      handleChartTypeChange={(value) => handleChartTypeChange(value, setTimeperiod)}
                    />
                  </div>
                  <div className="node-bullet-chart-dropdown__container">
                    <SelectDropdown
                      label={strings.ariaLabels.dataDropdown}
                      options={dropdownOptions}
                      handleChartTypeChange={(value) => handleChartTypeChange(value, setSelectedDisplay)}
                    />
                  </div>
                </div>
              </Card.Header>
              {!formattedData.length && (
                <div className="node-bullet-chart-loading-spinner">
                  <LoadingSpinner />
                </div>
              )}
              {formattedData.length > 0 && (
                <div className="node-bullet-chart-container">
                  <div
                    style={{
                      height: `${height}px`,
                      width: 'auto',
                      overflow: 'auto',
                      marginBottom: '2.5rem',
                    }}
                    aria-label={strings.ariaLabels.clusterBulletChart}
                    role="img"
                  >
                    <BulletChart />
                  </div>
                  <Legend display={selectedDisplay} />
                </div>
              )}
            </Card.Body>
          </Card>
        </div>
      )}
    </>
  );
};

export default ClusterBulletChart;
