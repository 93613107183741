import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
  Column,
  FilterTypes,
  Row,
  TableInstance,
  useAsyncDebounce,
  useColumnOrder,
  useFilters,
  useFlexLayout,
  usePagination,
  useResizeColumns,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import dayjs, { Dayjs } from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import toast, { Toaster } from 'react-hot-toast';
import { TriggerEvent, useContextMenu } from 'react-contexify';

import ContextMenu from '../ContextMenu/ContextMenu.react';

import { REACT_VULNERABILITIES_ALL_IMAGES } from '~reactComponents/NavigationReact/Navigation.config.react';
import { Card } from '@fairwindsops/ui-components';
import ConfirmationDialog from '~reactComponents/ConfirmationDialog/ConfirmationDialog.react';
import CreateTicketModal from '~reactComponents/createTicketModal/CreateTicketModal.react';
import EmptyData from '~reactComponents/EmptyData/EmptyData.react';
import Pagination from '~reactComponents/pagination/pagination.react';
import Table from '~reactComponents/Table/Table.react';

import SelectColumnFilter, {
  oneOrMoreValueToLabel,
  vulnerabilitiesValueToLabel,
} from '../SelectColumnFilter/SelectColumnFilter.react';
import TableCardHeader from '../TableCardHeader/TableCardHeader.react';

import {
  Cluster,
  CommonLabels,
  DateType,
  FiltersMapType,
  IndeterminateCheckboxProps,
  IRoute,
  IRouter,
  Organization,
} from '~globalTypes';

import { PAGES, VULN_ITEMS_TABLE_ALL_IMAGES_CONTEXT_MENU_ID, getCurrentTimezone } from '~reactHelpers';
import { sendRequest } from '~utils/request';
import logger from '~logger';

import { ChartLabels, Labels, Member, VulnerabilitiesActionItems } from './VulnerabilitiesItemsTable.types.react';
import {
  convertFiltersToURLForAllImages,
  handleAssigneeSelection,
  handleResolutionSelection,
} from './VulnerabilitiesItemsTable.helpers.react';
import { COLUMNS_ORDER, PAGE_SIZE, TABLE_COLUMNS } from './VulnerabilitiesItemsTable.config.react';

import {
  exportActionItems,
  exportCSV,
  getExportURLSearchParams,
  getTrivyReports,
  PAGES as EXPORT_PAGES,
  transformAllImagesVulnActionItems,
} from '../../../ReactVulnerabilities.helpers.react';
import {
  isSingleSelectionAction,
  MultiSelectedAction,
  SingleSelectedAction,
} from '../../../ReactVulnerabilities.types.react';

import { strings } from '~utils/strings';

import './VulnerabilitiesItemsTable.react.scss';
import Datepicker from '~reactComponents/ReactDatepicker/Datepicker.react';
import { AppGroup } from '~views/organization/actionItems/ActionItems.types.react';
import MultipleConfirmationDialog from '~reactComponents/MultipleConfirmationDialog/MultipleConfirmationDialog.react';
import { VulnerabilityTicketMode } from '~reactComponents/createTicketModal/CreateTicketModal.types.react';
import { vexillaShould } from '~utils/feature-flags';

dayjs.extend(relativeTime);
dayjs.extend(timezone);

type VulnerabilitiesItemsTableProps = {
  baseURL: string;
  cluster?: Cluster;
  organization: Organization;
  route: IRoute;
  savedSelectionsKey?: string;
  router: () => IRouter;
};

const oneOrMoreValues = [
  { label: '1 or More', value: 'true' },
  { label: 'None', value: 'false' },
];

type DateRange = {
  start?: string;
  end?: string;
};

const VulnerabilitiesItemsTable = ({
  baseURL,
  cluster,
  organization,
  route,
  savedSelectionsKey,
  router,
}: VulnerabilitiesItemsTableProps) => {
  const [vulnActionItems, setVulnActionItems] = useState<VulnerabilitiesActionItems[]>([]);
  const [selectedVulnActionItem, setSelectedVulnActionItem] = useState<VulnerabilitiesActionItems | null>(null);
  const vulnActionItemFilters = useRef<FiltersMapType>({});
  const initialFilters = useRef<any[]>([]);
  const [totalRowCount, setTotalRowCount] = useState<number>(0);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [globalSearch, setGlobalSearch] = useState<string | null>(null);
  const [selectedFlatRows, setSelectedFlatRows] = useState<Row<VulnerabilitiesActionItems>[]>([]);
  const [isResolvedShown, setIsResolvedShown] = useState<boolean>(
    new URLSearchParams(window.location.search).get(strings.noTranslate.resolved) === 'true',
  );
  const [firstSeenRange, setFirstSeenRange] = useState<DateType | null>(
    (() => {
      const searchParams = new URLSearchParams(window.location.search);
      const firstSeenStart = searchParams.get('firstSeen');
      const firstSeenEnd = searchParams.get('firstSeenEnd');
      return firstSeenStart && firstSeenEnd ? { start: firstSeenStart, end: firstSeenEnd } : null;
    })(),
  );
  const [members, setMembers] = useState<Member[]>([]);
  const [isCreateTicketModalShown, setIsCreateTicketModalShown] = useState<boolean>(false);
  const [syncedDate, setSyncedDate] = useState('');
  const [isConfirmModalShown, setIsConfirmModalShown] = useState<boolean>(false);
  const [selectedAppGroup, setSelectedAppGroup] = useState<AppGroup | null>(
    (() => {
      const appGroups = new URLSearchParams(window.location.search).get('appGroups');
      return appGroups ? ({ name: appGroups } as AppGroup) : null;
    })(),
  );
  const [appGroups, setAppGroups] = useState<AppGroup[]>([]);

  const skipTableResetRef = useRef<boolean>();
  const sortBy = useRef<{ orderBy: string; desc: boolean }>({ orderBy: '', desc: false });
  const selectedVulnActionItems = useRef<VulnerabilitiesActionItems[]>();
  const isMounted = useRef<boolean>(false);
  // store the number of total items. It will be used to export all/filtered items.
  const totalExportItems = useRef<number>(0);
  const totalExportFilteredItems = useRef<number>(0);
  const isFirstLoaded = useRef<boolean>(true);
  // store the current action (create tickets, update assignee, or update resolution)
  const selectedAction = useRef<SingleSelectedAction | MultiSelectedAction | null>();
  // store the selected resolution and selected email
  const selectedResolution = useRef<string>('');
  const selectedEmail = useRef<string>('');
  // number of modified action items;
  const nModifiedActionItems = useRef<string>('');

  const vulnerabilityTicketMode = useRef<VulnerabilityTicketMode>('per_action_item');

  const { show: showContextMenu } = useContextMenu({
    id: VULN_ITEMS_TABLE_ALL_IMAGES_CONTEXT_MENU_ID,
  });

  const timeZone = getCurrentTimezone();

  const [lastScannedRange, setLastScannedRange] = useState<DateRange | null>(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const lastScanned = searchParams.get('lastScanned');

    if (!lastScanned) {
      return null;
    }

    const [start, end] = lastScanned.split(';');
    return { start, end };
  });

  useEffect(() => {
    isMounted.current = true;
    initQueries();
    getMembers();
    getAppGroups();
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    getTrivyReports(baseURL, cluster!, (lastSyncedDate: string) => {
      setSyncedDate(lastSyncedDate);
    });
  }, [cluster]);

  useEffect(() => {
    fetchVulnActionItemsDebounced();
  }, [globalSearch, isResolvedShown, firstSeenRange?.start, firstSeenRange?.end, cluster, selectedAppGroup]);

  useEffect(() => {
    // After the table has updated, always remove the flag
    skipTableResetRef.current = false;
  });

  // Restores user selection for hidden/ordered columns
  const tableColumnsStorage: Record<string, string[]> = useMemo(
    () => (savedSelectionsKey ? JSON.parse(localStorage.getItem(savedSelectionsKey) || '{}') : {}),
    [],
  );

  const tableHiddenColumns = useMemo(() => {
    return tableColumnsStorage?.hiddenColumns || ['repositoriesCount', 'package'];
  }, []);

  const data = React.useMemo(() => {
    return vulnActionItems;
  }, [vulnActionItems]);

  const getMembers = async (): Promise<void> => {
    try {
      const retrievedMembers = await sendRequest('GET', `${baseURL}/memberships`, { showErrorAlert: false }, null);
      setMembers(retrievedMembers);
    } catch (e) {
      logger.logError('error_get_memberships_vulnerabilities_items_table', e);
    }
  };

  const getAppGroups = async (): Promise<void> => {
    try {
      const appGroups = (await sendRequest('GET', `${baseURL}/app-groups`, { showErrorAlert: false }, null)) as
        | AppGroup[]
        | null;
      setAppGroups(appGroups || []);
    } catch (e) {
      logger.logError('error_get_app_groups_vulnerabilities_items_table', e);
    }
  };

  const ColumnFiltering = (tableInstance: TableInstance) => (
    <SelectColumnFilter filters={vulnActionItemFilters.current} setLoaded={setLoaded} tableInstance={tableInstance} />
  );

  const ColumnDateRangeFiltering = (tableInstance: TableInstance) => {
    const {
      column: { setFilter },
    } = tableInstance;

    const filterByDateFirstSeen = (start: Dayjs, end: Date | Dayjs) => {
      if (!start && !end) {
        setLastScannedRange(null);
        setFilter([]);
        setLoaded && setLoaded(false);
        return;
      }
      start = dayjs(start);
      end = dayjs(end);

      const val = { start: start.toISOString(), end: end.toISOString() };
      setLastScannedRange(val);

      const formattedLabel = `${start.format('MM/DD/YYYY')} - ${end.format('MM/DD/YYYY')}`;
      const formattedValue = `${start.toISOString() + ';' + end.toISOString()}`;
      setFilter([{ label: formattedLabel, value: formattedValue }]);
      setLoaded && setLoaded(false);
    };

    return (
      <>
        <div className="vulnerabilities-datepicker-container">
          <Datepicker
            filter={filterByDateFirstSeen}
            startDate={lastScannedRange?.start}
            endDate={lastScannedRange?.end}
            minDate={120}
            noValueLabel="Filter"
            className="action-items-reports__date-dropdown"
          />
        </div>
      </>
    );
  };

  const columns = React.useMemo<Column<VulnerabilitiesActionItems>[]>(
    () => TABLE_COLUMNS(ColumnFiltering, ColumnDateRangeFiltering, cluster),
    [cluster, vulnActionItems, vulnActionItemFilters.current],
  );

  const filterTypes = React.useMemo<FilterTypes<VulnerabilitiesActionItems>>(
    () => ({
      text: (rows, ids, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[ids[0]];
          return rowValue !== undefined
            ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    [],
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      filterTypes,
      initialState: {
        pageSize: PAGE_SIZE,
        filters: initialFilters.current,
        columnOrder: tableColumnsStorage?.columnOrder || COLUMNS_ORDER,
        hiddenColumns: tableHiddenColumns,
      },
      manualFilters: true,
      manualPagination: true,
      autoResetHiddenColumns: false,
      manualSortBy: true,
      autoResetFilters: !skipTableResetRef.current,
      autoResetSortBy: !skipTableResetRef.current,
      autoResetPage: !skipTableResetRef.current,
      autoResetExpanded: !skipTableResetRef.current,
      autoResetGroupBy: !skipTableResetRef.current,
      autoResetSelectedRows: !skipTableResetRef.current,
      autoResetRowState: !skipTableResetRef.current,
    },
    useFilters,
    useSortBy,
    usePagination,
    useResizeColumns,
    useRowSelect,
    useFlexLayout,
    useColumnOrder,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          // important to not change
          id: strings.general.selection,
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }: { row: Row<VulnerabilitiesActionItems> }) => (
            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
          ),
        },
        ...columns,
      ]);
    },
  );

  useEffect(() => {
    if (savedSelectionsKey) {
      localStorage.setItem(
        savedSelectionsKey,
        JSON.stringify({
          hiddenColumns: tableInstance.state.hiddenColumns,
          columnOrder: tableInstance.state.columnOrder,
        }),
      );
    }
  }, [tableInstance.state.hiddenColumns, tableInstance.state.columnOrder]);

  useEffect(() => {
    if (initialFilters?.current.length > 0 && loaded) {
      tableInstance.setAllFilters(initialFilters.current);
    }
  }, [initialFilters.current, loaded]);

  useEffect(() => {
    const url = `${baseURL}/images/vulnerabilities/summaries-filters?resolved=${isResolvedShown}`;
    if (selectedAppGroup) {
      url.concat(`&appGroups=${selectedAppGroup.name}`);
    }
    const fetchData = async () => {
      try {
        const filters = await sendRequest('GET', url, { showErrorAlert: false }, null);
        vulnActionItemFilters.current = {
          ...vulnActionItemFilters.current,
          ...transformFilters(filters),
        };
      } catch (e) {
        console.error('ERROR: ', e);
        logger.logError('error_loading_action_items_repositories_page: ', e);
        toast.error('Error loading page. Please try again!');
      }
    };
    fetchData();
  }, [organization, isResolvedShown, selectedAppGroup]);

  const transformFilters = (filters: FiltersMapType) => {
    if (!filters) return;
    for (const key in filters) {
      if (key === Labels.severityLabels) {
        filters[key] = (filters[key] as string[])?.map((filterValue) => filterValue.toLowerCase());
      }
    }

    // hard-coded list of values for vulnerabilitiesCount
    filters[Labels.vulnerabilityCounts] = [
      { label: '<25', value: '25' },
      { label: '<50', value: '50' },
      { label: '<100', value: '100' },
      { label: '101+', value: '101' },
    ];

    filters[Labels.repositoryCounts] = oneOrMoreValues;
    filters[Labels.workloadCounts] = oneOrMoreValues;
    return filters;
  };

  const initQueries = () => {
    const navigation = window.performance.getEntriesByType('navigation') as any;
    if ((navigation[0].type === 'reload' || navigation[0].type === 'navigate') && window.location.search) {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set('redirect', 'true');
      router()
        .replace({ path: `${route.path}?${searchParams.toString()}` })
        .catch(() => {});
    }
  };

  const findLabelByValue = (id: string, value: string) => {
    if (id != 'vulnerabilitiesCount') return value;
    const label = vulnerabilitiesValueToLabel[value as keyof typeof vulnerabilitiesValueToLabel];
    return label ? label : value;
  };

  const hydrateColumnFilters = (searchParams: URLSearchParams) => {
    let columnFilters: any[] = [];
    for (const item of searchParams.entries()) {
      if (tableInstance.allColumns.some((col) => col.id === item[0])) {
        const filterIdx = columnFilters.findIndex((filter) => filter.id === item[0]);
        if (filterIdx >= 0) {
          columnFilters[filterIdx].value.push({
            value: item[1],
            label: item[1],
          });
        } else {
          const label = findLabelByValue(item[0], item[1]);
          columnFilters.push({
            id: item[0],
            value: [
              {
                value: item[1],
                label: label,
              },
            ],
          });
        }
      } else {
        columnFilters = hydrateSecondaryFilters(item, columnFilters);
      }
    }
    initialFilters.current = columnFilters;
  };

  const hydrateSecondaryFilters = (item: [string, string], columnFilters: any[]): any[] => {
    switch (item[0]) {
      case 'search':
        setGlobalSearch(item[1]);
        break;
      case 'pageSize':
        tableInstance.state.pageSize = Number(item[1]) || PAGE_SIZE;
        break;
      case 'page': {
        tableInstance.state.pageIndex = Number(item[1]) || 0;
        tableInstance.gotoPage(Number(item[1]) || 0);
        break;
      }
      case 'orderBy': {
        sortBy.current = {
          ...sortBy.current,
          orderBy: item[0] === 'orderBy' ? item[1] : '',
        };
        break;
      }
      case 'desc': {
        sortBy.current = {
          ...sortBy.current,
          desc: item[1] === 'true',
        };
        if (sortBy.current.orderBy) {
          tableInstance.setSortBy([
            {
              id: sortBy.current.orderBy,
              desc: sortBy.current.desc,
            },
          ]);
        }
        break;
      }
      case 'hasRepositories':
        return [
          ...columnFilters,
          {
            id: 'repositoriesCount',
            value: [
              {
                value: item[1],
                label: oneOrMoreValueToLabel[item[1] as keyof typeof oneOrMoreValueToLabel],
              },
            ],
          },
        ];
      case 'hasWorkloads':
        return [
          ...columnFilters,
          {
            id: 'workloadsCount',
            value: [
              {
                value: item[1],
                label: oneOrMoreValueToLabel[item[1] as keyof typeof oneOrMoreValueToLabel],
              },
            ],
          },
        ];
    }
    return columnFilters;
  };

  const fetchVulnActionItems = useCallback(async () => {
    const { pageIndex, pageSize, sortBy, filters } = tableInstance.state;
    // When data gets updated with this function, set a flag
    // to disable all of the auto resetting
    skipTableResetRef.current = true;

    let searchParams: URLSearchParams;

    if (!window.location.search.includes('redirect')) {
      searchParams = new URLSearchParams();
      searchParams.set('page', pageIndex.toString());
      searchParams.set('desc', 'true');
    } else {
      searchParams = new URLSearchParams(window.location.search);
      searchParams.delete('redirect');
      searchParams.set('page', searchParams.get('page') ? (searchParams.get('page') as string) : pageIndex.toString());
    }

    if (!searchParams.has('pageSize')) {
      searchParams.set('pageSize', pageSize.toString());
    }

    if (firstSeenRange?.start) {
      searchParams.set(
        'firstSeen',
        `${timeZone ? dayjs(firstSeenRange.start).tz(timeZone).toISOString() : dayjs(firstSeenRange.start).toISOString()}`,
      );
    }
    if (firstSeenRange?.end) {
      searchParams.set(
        'firstSeenEnd',
        `${timeZone ? dayjs(firstSeenRange.end).tz(timeZone).toISOString() : dayjs(firstSeenRange.end).toISOString()}`,
      );
    }

    if (!loaded && isMounted.current) {
      hydrateColumnFilters(searchParams);
    }

    if (globalSearch) {
      searchParams.set('search', globalSearch);
    } else {
      searchParams.delete('search');
    }

    searchParams.set(strings.noTranslate.resolved, String(isResolvedShown));

    searchParams = convertFiltersToURLForAllImages(filters, searchParams);

    if (selectedAppGroup) {
      searchParams.set('appGroups', selectedAppGroup.name);
    } else {
      searchParams.delete('appGroups');
    }

    if (sortBy[0]) {
      searchParams.set('orderBy', getSortByValue(sortBy[0].id));
      searchParams.set('desc', `${sortBy[0].desc}`);
    } else {
      searchParams.delete('orderBy');
      searchParams.delete('desc');
    }

    if (globalSearch) {
      searchParams.set('search', globalSearch);
    }

    if (cluster) {
      searchParams.set('cluster', cluster.Name);
    }

    try {
      const response = await sendRequest(
        'GET',
        `${baseURL}/images/vulnerabilities/summaries?${searchParams.toString()}`,
        { returnHeaders: true },
        null,
      );
      const data = response.data;
      if (isMounted.current) {
        setVulnActionItems(transformAllImagesVulnActionItems(data?.summaries));
        setTotalRowCount(data.total);
        if (isFirstLoaded.current) {
          totalExportItems.current = data.total;
          isFirstLoaded.current = false;
        }
        totalExportFilteredItems.current = data.total;
        tableInstance.pageCount = data.total;
        setLoaded(true);
        const routeName = router().currentRoute?.name;
        if (routeName && routeName.includes(REACT_VULNERABILITIES_ALL_IMAGES)) {
          router()
            .replace({ path: `${window.location.pathname}?${searchParams.toString()}` })
            .catch(() => {});
        }
      }
    } catch (e) {
      console.error(e);
      logger.logError('error_fetching_action_items: ', e);
      toast.error('Error fetching Action Items');
    }
  }, [loaded, globalSearch, isResolvedShown, firstSeenRange?.start, firstSeenRange?.end, cluster, selectedAppGroup]);

  const getSortByValue = (sortById: string) => {
    switch (sortById) {
      case Labels.Cluster:
        return Labels.Clusters;
      case Labels.Package:
        return Labels.Packages;
      default:
        return sortById;
    }
  };

  const fetchVulnActionItemsDebounced = useAsyncDebounce(fetchVulnActionItems, 250);

  const IndeterminateCheckbox = React.forwardRef<HTMLInputElement, IndeterminateCheckboxProps>(
    ({ indeterminate, ...rest }, ref: React.Ref<HTMLInputElement>) => {
      const defaultRef = React.useRef(null);
      const resolvedRef = ref || defaultRef;

      React.useEffect(() => {
        if (typeof resolvedRef === 'object' && resolvedRef.current) {
          resolvedRef.current.indeterminate = Boolean(indeterminate);
        }
      }, [resolvedRef, indeterminate]);

      return (
        <label>
          <input
            type="checkbox"
            ref={ref}
            {...rest}
            // Need to stop propagation otherwise row event is called again
            onClick={(e) => e.stopPropagation()}
          />
          <span />
        </label>
      );
    },
  );

  const totalPage = useMemo(
    () => Math.ceil(totalRowCount / tableInstance.state.pageSize),
    [totalRowCount, tableInstance.state.pageSize],
  );

  const handlePaginationChange = (page: number): void => {
    if (page < totalPage && tableInstance.state.pageIndex !== page) {
      tableInstance.gotoPage(page);
      setLoaded(false);
    }
  };

  const toggleContextMenu = (e: TriggerEvent, actionItem: VulnerabilitiesActionItems) => {
    showContextMenu({ event: e, props: { actionItem } });
    // Need to stop the propagation otherwise the row is selected
    window.addEventListener(
      'keydown',
      function (event) {
        event.stopPropagation();
      },
      true,
    );
  };

  const onShowResolvedClicked = () => {
    setIsResolvedShown((prevStatus) => !prevStatus);
  };

  const onVulnActionItemSelected = (selectedVulnActionItem: VulnerabilitiesActionItems) => {
    if (!selectedVulnActionItem) return;
    setSelectedVulnActionItem(selectedVulnActionItem);
    logger.logEvent('react-vulnerabilities-all-images:select-item', selectedVulnActionItem);
    router().push({
      name: `react-vulnerabilities-image-detail`,
      params: {
        org: organization.Name,
        selectedImage: selectedVulnActionItem.sha,
      },
      query: {
        resolved: isResolvedShown,
        tag: selectedVulnActionItem.tag,
        imageId: selectedVulnActionItem.id,
      },
    });
  };

  const showCreateTicketModal = async (actionItem: VulnerabilitiesActionItems) => {
    if (isResolvedShown) {
      toast.error(strings.general.cantCreateTicketForResolvedVulnerability);
      return;
    }
    if (!setSelectedVulnActionItemsRef(actionItem)) {
      return;
    }
    let nModifiedImages;
    [nModifiedActionItems.current, nModifiedImages] = await Promise.all([
      getCreateTicketNumberOfModifiedAI('per_action_item'),
      getCreateTicketNumberOfModifiedAI('per_image'),
    ]);
    const perActionItemAction = () => {
      setIsConfirmModalShown(false);
      setIsCreateTicketModalShown(true);
      vulnerabilityTicketMode.current = 'per_action_item';
    };
    const perImageAction = () => {
      setIsConfirmModalShown(false);
      setIsCreateTicketModalShown(true);
      vulnerabilityTicketMode.current = 'per_image';
    };
    if (!vexillaShould(strings.featureFlags.imageVulnsTicketPerImage)) {
      selectedAction.current = {
        title: CommonLabels.CreateTicketsConfirmTitle,
        content: CommonLabels.CreateTicketsConfirmContent.replace('#', nModifiedActionItems.current),
        confirmButtonText: CommonLabels.CreateTickets,
        action: perActionItemAction,
      };
    } else {
      const content = `${CommonLabels.CreateTicketsMultipleConfirmContent.replace('#1', nModifiedImages).replace('#2', nModifiedActionItems.current)}`;
      selectedAction.current = {
        title: CommonLabels.CreateTicketsConfirmTitle,
        content: content,
        confirmationButtons: [
          {
            text: CommonLabels.CreateTicketsPerImage,
            action: perImageAction,
          },
          {
            text: CommonLabels.CreateTicketsPerActionItem,
            action: perActionItemAction,
          },
        ],
      };
    }

    setIsConfirmModalShown(true);
  };

  const getCreateTicketNumberOfModifiedAI = async (mode: string): Promise<string> => {
    let response;
    try {
      let url = `/v0/organizations/${organization.Name}/images/vulnerabilities/tickets?dryRun=true`;
      if (mode) {
        url = `${url}&mode=${mode}`;
      }
      response = await sendRequest(
        'PATCH',
        url,
        {
          data: {
            Provider: 'Jira',
            ProjectName: '',
            Labels: ['bug'],
            imageSHAs: selectedVulnActionItems?.current?.map((actionItem) => actionItem.sha),
          },
        },
        null,
      );
    } catch (e) {
      logger.logError(
        'react-vulnerabilities-all-images:error_getting_number_of_modified_action_items_for_creating_tickets',
        e,
      );
      toast.error(<b>Error getting the number of modified Action Items. Please try again.</b>);
    }
    return String(response.TotalAffected);
  };

  const onResolutionHandled = async (
    actionItem: VulnerabilitiesActionItems,
    resolution: { value: string; label: string },
  ) => {
    if (!setSelectedVulnActionItemsRef(actionItem)) {
      return;
    }
    await getNumberOfModifiedAIUR();
    selectedAction.current = {
      title: CommonLabels.ResolveItemsConfirmTitle,
      content: CommonLabels.ResolveItemsConfirmContent.replace(CommonLabels.Hash, nModifiedActionItems.current),
      confirmButtonText: CommonLabels.UpdateResolution,
      action: () => {
        setIsConfirmModalShown(false);
        handleResolution();
      },
    };
    selectedResolution.current = resolution.value;
    setIsConfirmModalShown(true);
  };

  const getNumberOfModifiedAIUR = async () => {
    let response;
    try {
      response = await handleResolutionSelection(
        selectedResolution.current,
        selectedVulnActionItems.current!,
        baseURL,
        true,
      );
    } catch (e) {
      logger.logError(
        'react-vulnerabilities-all-images:error_getting_number_of_modified_action_items_for_updating-resolution',
        e,
      );
      toast.error(<b>Error getting the number of modified Action Items. Please try again.</b>);
    }
    nModifiedActionItems.current = String(response.TotalAffected);
  };

  const handleResolution = async () => {
    try {
      const response = await handleResolutionSelection(
        selectedResolution.current,
        selectedVulnActionItems.current!,
        baseURL,
      );
      logger.logEvent('react-vulnerabilities-all-images:resolve-item', {
        imageSHAs: selectedVulnActionItems.current?.map((row) => row.sha),
        resolution: selectedResolution.current,
      });
      toast.success(
        <b>{CommonLabels.UpdateResolveSuccessMessage.replace(CommonLabels.Hash, String(response.TotalAffected))}</b>,
      );
    } catch (e) {
      logger.logError('error_updating_vuln_resolution', e);
      toast.error(<b>Error updating the resolution. Please try again.</b>);
    }
  };

  const onAssigneeHandled = async (actionItem: VulnerabilitiesActionItems, email: string) => {
    if (!setSelectedVulnActionItemsRef(actionItem)) {
      return;
    }
    await getNumberOfModifiedAIUA();
    selectedAction.current = {
      title: CommonLabels.AssignItemsConfirmTitle,
      content: CommonLabels.AssignItemsConfirmContent.replace(CommonLabels.Hash, nModifiedActionItems.current),
      confirmButtonText: CommonLabels.UpdateAssignee,
      action: () => {
        setIsConfirmModalShown(false);
        handleAssignee();
      },
    };
    selectedEmail.current = email;
    setIsConfirmModalShown(true);
  };

  const getNumberOfModifiedAIUA = async () => {
    let response;
    try {
      response = await handleAssigneeSelection(selectedEmail.current, selectedVulnActionItems.current!, baseURL, true);
    } catch (e) {
      logger.logError(
        'react-vulnerabilities-all-images:error_getting_number_of_modified_action_items_for_updating-assignee',
        e,
      );
      toast.error(<b>Error getting the number of modified Action Items. Please try again.</b>);
    }
    nModifiedActionItems.current = String(response.TotalAffected);
  };

  const handleAssignee = async () => {
    try {
      const response = await handleAssigneeSelection(selectedEmail.current, selectedVulnActionItems.current!, baseURL);
      if (selectedEmail.current) {
        toast.success(
          <b>{CommonLabels.UpdateAssigneeSuccessMessage.replace(CommonLabels.Hash, String(response.TotalAffected))}</b>,
        );
      } else {
        toast.success(<b>Action item has been unassigned</b>);
      }
      logger.logEvent('react-vulnerabilities-all-images:assign-item', {
        imageSHAs: selectedVulnActionItems.current?.map((row) => row.sha),
        assigneeEmail: selectedEmail.current?.length ? selectedEmail.current : null,
      });
      fetchVulnActionItemsDebounced && fetchVulnActionItemsDebounced();
    } catch (e) {
      toast.error(<b>Error updating the assignee. Please try again.</b>);
      logger.logError('error_updating_vuln_action_item_assignee', e);
    }
  };

  const setSelectedVulnActionItemsRef = (actionItem: VulnerabilitiesActionItems) => {
    selectedVulnActionItems.current = getSelectedVulnActionItems(actionItem);
    if (!selectedVulnActionItems.current.length) {
      toast.error(Labels.NoImagesSelectedErrMessage);
      return false;
    }
    return true;
  };

  const getSelectedVulnActionItems = (actionItem: VulnerabilitiesActionItems) => {
    const selectedActionItems = selectedFlatRows?.map((row) => row.original);
    if (actionItem) {
      const isExisted = selectedActionItems.find((item: VulnerabilitiesActionItems) => item.id === actionItem.id);
      return isExisted ? selectedActionItems : [...selectedActionItems, actionItem];
    }
    return selectedActionItems;
  };

  const handleExport = async (action: string) => {
    const searchParams = new URLSearchParams(window.location.search);
    exportCSV({
      action,
      exportAllCallback: () =>
        exportActionItems({
          from: EXPORT_PAGES.ALL_IMAGES,
          apiEndpoint: `${baseURL}/images/vulnerabilities/summaries?${getExportURLSearchParams(
            cluster!,
            totalExportItems.current,
          ).toString()}`,
          orgName: organization.Name,
        }),
      exportFilteredCallback: () =>
        exportActionItems({
          from: EXPORT_PAGES.ALL_IMAGES,
          apiEndpoint: `${baseURL}/images/vulnerabilities/summaries?${searchParams.toString()}`,
          orgName: organization.Name,
        }),
      totalExportFilteredItems: totalExportFilteredItems.current,
      searchParams,
      logEventKey: 'react-vulnerabilities-all-images',
      cluster: cluster?.Name,
    });
  };

  return (
    <>
      <Toaster />
      <div>
        <div className="vulnerabilities--charts-container" aria-hidden="true">
          <Card
            className="card-deck"
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              boxShadow: 'none',
            }}
          >
            {(Object.keys(ChartLabels) as Array<keyof typeof ChartLabels>).map((label) => {
              const ChartComponent = ChartLabels[label].component;
              return (
                <Card style={{ width: '32%' }}>
                  <Card.Header className="vulnerabilities__card-header">
                    <h2>{ChartLabels[label].title}</h2>
                  </Card.Header>
                  <Card.Body className="vulnerabilities__chart-container" padded>
                    <ChartComponent
                      noData={<EmptyData label={CommonLabels.NoData} />}
                      baseURL={baseURL}
                      filters={tableInstance.state.filters}
                      setFilter={tableInstance.setFilter}
                      cluster={cluster}
                      isResolvedShown={isResolvedShown}
                      selectedAppGroup={selectedAppGroup}
                      firstSeenRange={firstSeenRange}
                    />
                  </Card.Body>
                </Card>
              );
            })}
          </Card>
          <div className="repo-action-items--container">
            <Card className="repo-action-items" data-cy="vulnerabilities-card">
              <TableCardHeader
                firstSeenRange={firstSeenRange}
                globalSearch={globalSearch}
                handleExport={handleExport}
                isHidingColsSupported
                isResolvedShown={isResolvedShown}
                selectedAppGroup={selectedAppGroup}
                setSelectedAppGroup={setSelectedAppGroup}
                appGroups={appGroups}
                loaded={loaded}
                onShowResolvedClicked={onShowResolvedClicked}
                selectedVulnActionItem={selectedVulnActionItem}
                setFirstSeenRange={setFirstSeenRange}
                setGlobalSearch={setGlobalSearch}
                setLoaded={setLoaded}
                syncedDate={syncedDate}
                tableInstance={tableInstance}
                totalRowCount={totalRowCount}
              />
              <Table
                className="vulnerabilities-all-images-items-table"
                caption={Labels.AllImagesVulnerabilitiesItems}
                tableInstance={tableInstance}
                pagination={
                  <Pagination
                    currentPage={tableInstance.state.pageIndex}
                    pageChange={handlePaginationChange}
                    totalCount={totalPage}
                  />
                }
                onDataFetched={fetchVulnActionItemsDebounced}
                onItemSelected={onVulnActionItemSelected}
                setSelectedFlatRows={setSelectedFlatRows}
                loaded={loaded}
                onContextMenuClicked={toggleContextMenu}
                contextMenu={
                  <ContextMenu
                    members={members}
                    handleOpenTicketModal={showCreateTicketModal}
                    onResolutionHandled={onResolutionHandled}
                    onAssigneeHandled={onAssigneeHandled}
                  />
                }
              />
            </Card>
            {isSingleSelectionAction(selectedAction.current) ? (
              <ConfirmationDialog
                cancelButtonClasses="custom-cancel-button"
                cancelButtonText={CommonLabels.Cancel}
                confirmButtonText={selectedAction.current.confirmButtonText}
                isModalShown={isConfirmModalShown}
                modalBodyClasses="custom-confirm-modal-body"
                modalContent={selectedAction.current.content}
                modalContentClasses="custom-confirm-modal-content"
                modalTitle={selectedAction.current.title}
                onConfirmClicked={selectedAction.current.action}
                onModalHidden={(isModalShown?: boolean) => setIsConfirmModalShown(isModalShown ? true : false)}
              />
            ) : (
              selectedAction.current && (
                <MultipleConfirmationDialog
                  modelSize="lg"
                  cancelButtonClasses="custom-cancel-button"
                  cancelButtonText={CommonLabels.Cancel}
                  isModalShown={isConfirmModalShown}
                  modalBodyClasses="custom-confirm-modal-body"
                  modalContent={selectedAction.current.content}
                  modalContentClasses="custom-confirm-modal-content"
                  modalTitle={selectedAction.current.title}
                  confirmationButtons={selectedAction.current.confirmationButtons?.map((action) => ({
                    text: action.text,
                    onClick: action.action,
                  }))}
                  onModalHidden={(isModalShown?: boolean) => setIsConfirmModalShown(isModalShown ? true : false)}
                />
              )
            )}
            {isCreateTicketModalShown && (
              <CreateTicketModal
                router={router}
                closeModal={() => setIsCreateTicketModalShown(false)}
                imageSHAs={selectedVulnActionItems?.current?.map((actionItem) => actionItem.sha)}
                isFrom={PAGES.VulnerabilitiesAllImages}
                vulnerabilityTicketMode={vulnerabilityTicketMode.current}
                logEventKey="react-vulnerabilities-all-images:create-ticket"
                route={route}
                showModal={isCreateTicketModalShown}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default VulnerabilitiesItemsTable;
