import { FiltersMapType, RepoActivity } from '~globalTypes';
import { strings } from '~utils/strings';

export interface RepositoryActionItem {
  ID: number;
  repositoryName: string | undefined;
  repositoryID: number | undefined;
  severityLabel: string;
  title: string;
  reportType: string;
  resourceContainer: string;
  resourceKind: string;
  resourceName: string;
  resolution: string;
  historicalResolution: string;
  snoozeUntil?: string;
  autoScan: boolean;
  description: string;
  remediation: string;
  category: string;
  lastScan: string | null | undefined;
  firstScan: string | null | undefined;
  ticketLink?: string;
  ticketCreated?: boolean;
  resourceFilename: string;
  canCreatePullRequest?: boolean;
}

export const Labels = {
  Repository: 'Repository',
  SeveritiesBreakdown: 'Severities Breakdown',
  TopRepositories: 'Top Repositories',
  TopIssues: 'Top Issues',
} as const;

export const sortingFields = [
  'repositoryNames',
  'severityLabels',
  'titles',
  'reportTypes',
  'resourceContainerNames',
  'resourceKinds',
  'resourceNames',
  'resourceFilenames',
  'resolutions',
];

export const filtersMap: FiltersMapType = {
  repositoryNames: 'repositoryName',
  severityLabels: 'severityLabel',
  titles: 'title',
  reportTypes: 'reportType',
  resourceContainerNames: 'resourceContainer',
  resourceKinds: 'resourceKind',
  resourceNames: 'resourceName',
  resourceFilenames: 'resourceFilename',
  resolutions: strings.repository.resolution,
  historicalResolutions: strings.repository.historicalResolution,
};

export interface DescriptionTopRow {
  title: string;
  content: string | boolean | undefined;
  isHidden?: boolean;
}

export interface RepoMetaDataParams {
  selectedActionItem: RepositoryActionItem;
  isMainBranch: boolean | undefined;
  latestActivities: RepoActivity[] | undefined;
}
