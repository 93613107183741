import React, { useState, useEffect, useRef } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { EditModalProps } from '../../ActionItems.types.react';
import CopyIcon from '~assetIcons/copy.svg';
import toast, { Toaster } from 'react-hot-toast';
import './EditList.react.scss';

const EditModal = ({
  show,
  item,
  importedList,
  setImportedList,
  listName,
  setListName,
  listNames,
  ImportListOptions,
  query,
  setQuery,
  editList,
  deleteListModal,
  router,
  org,
  getLists,
  closeModal,
  loadActionItems,
}: EditModalProps): JSX.Element => {
  const [showLink, setShowLink] = useState<boolean>(false);
  const [invalidMessage, setInvalidMessage] = useState<string>('');
  const initialListName = useRef<string | undefined>(item?.Name);
  const initialQuery = useRef<string>(query);
  const initialImportedList = useRef<string>(importedList);

  useEffect(() => {
    if (item?.Name) {
      setListName(item.Name);
    }
  }, [item]);

  const showSelectedList = () => {
    getLists();
    router().replace({ name: `action-items-lists`, query: { name: item?.Name } });
    closeModal();
  };

  const copySelectedURL = async () => {
    const searchParams = new URLSearchParams(`name=${item?.Name}`);
    const link = `${location.origin}/orgs/${org}/action-items/lists?${searchParams.toString()}`;
    await navigator.clipboard.writeText(link);
    toast.success(<b>Link copied!</b>);
  };

  const edit = async () => {
    if (item) {
      await loadActionItems(item);
    }
    if (item?.Type === 'query') {
      editQueryList();
    } else {
      editManualList();
    }
  };

  const editQueryList = () => {
    if (isQueryFormChanged()) {
      if (isListNameChanged() && validateListName()) return;
      editList();
    }
    closeModal();
  };

  const editManualList = () => {
    if (isManualFormChanged()) {
      if (isListNameChanged() && validateListName()) return;
      editList();
    }
    closeModal();
  };

  const onListNameChanged = (value: string) => {
    setListName(value);
    validateListName(value);
  };

  const isQueryFormChanged = () => isListNameChanged() || isQueryChanged();

  const isManualFormChanged = () => isListNameChanged() || isImportedListChanged();

  const isListNameChanged = () => listName !== initialListName.current;

  const isQueryChanged = () => query !== initialQuery.current;

  const isImportedListChanged = () => importedList !== initialImportedList.current;

  const validateListName = (value = listName) => {
    if (value === '') {
      setInvalidMessage('A list name is required');
      return true;
    }
    if (listNames.includes(value || '') && value !== initialListName.current) {
      setInvalidMessage('Sorry, that name already exists. Please choose another name');
      return true;
    }
    setInvalidMessage('');
    return false;
  };

  const setList = (e: React.FocusEvent<HTMLSelectElement>) => {
    setImportedList(e.target.value as string);
  };

  return (
    <Modal show={show} onHide={closeModal} size="lg">
      <Modal.Header closeButton>
        <div className="header-div">
          <Modal.Title>{item?.Name}</Modal.Title>
          <Button onClick={() => setShowLink(!showLink)}>
            <img src={CopyIcon} className="share-icon" alt="share icon" />
            Share List
          </Button>
        </div>
      </Modal.Header>
      <Modal.Body>
        {showLink && (
          <div className="d-flex justify-content-between flex-row mb-4">
            <button onClick={showSelectedList} className="share-link">
              Show {item?.Name} list
            </button>
            <Button onClick={copySelectedURL}>Copy Link</Button>
          </div>
        )}
        {item?.isOwner && (
          <Form.Group>
            <Form.Label>List Name</Form.Label>
            <Form.Control
              type="text"
              defaultValue={item?.Name}
              onChange={(event) => onListNameChanged(event.target.value)}
              isInvalid={invalidMessage !== ''}
              required
              data-cy="list-name-text-input"
            />
            <Form.Control.Feedback type="invalid">{invalidMessage}</Form.Control.Feedback>
          </Form.Group>
        )}
        {!item?.isOwner && (
          <Form.Group>
            <Form.Label>List Name</Form.Label>
            <Form.Control type="text" placeholder={item?.Name} disabled />
          </Form.Group>
        )}
        {item?.isOwner && item?.Type === 'manual' && (
          <Form.Group className="import-dropdown">
            <Form.Label>Import List</Form.Label>
            <Form.Control as="select" onBlur={setList}>
              <option>List</option>
              <ImportListOptions name={item.Name} />
            </Form.Control>
          </Form.Group>
        )}
        {item?.isOwner && item?.Type === 'query' && (
          <Form.Group>
            <div className="query-form">
              <Form.Label className="query-label">Action Item Query</Form.Label>
            </div>
            <Form.Control
              className="query-input"
              as="textarea"
              rows={3}
              defaultValue={query}
              onChange={(event) => setQuery(event?.target.value)}
              data-cy="query-text-input"
            />
          </Form.Group>
        )}
        {!item?.isOwner && item?.Type === 'query' && (
          <div>
            <div className="query-form">
              <h2 className="query-label" style={{ fontSize: '1rem' }}>
                Action Item Query
              </h2>
            </div>
            <div className="readonly-query">{query}</div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="footer">
        <div>
          <Button
            className="footer-btn"
            onClick={() => deleteListModal(item ? item.ID : 0)}
            variant="danger"
            disabled={!item?.isOwner}
          >
            Delete List
          </Button>
          <Button onClick={edit} className="footer-btn" disabled={!item?.isOwner} data-cy="submit-update-list-button">
            Update List
          </Button>
        </div>
      </Modal.Footer>

      <Toaster />
    </Modal>
  );
};

export default EditModal;
