import React, { useMemo, useState } from 'react';
import { MultiValue } from 'react-select';

import LoadingSpinner from '~reactComponents/LoadingSpinner/LoadingSpinner.react';

import { Card } from '@fairwindsops/ui-components';
import AggregatorBar from '../AggregatorBarData/AggregatorBarData.react';
import BarChart from './BarChartComponent.react';

import { totalColumnsOptions, aggregatorOptions } from '../../Costs.config.react';

import { formatCost, findLabel, getResourceTypeOptions } from '../../Costs.helpers.react';

import {
  ResourceSummary,
  AggregatorBarData,
  SelectedFilters,
  CostsOptions,
  ResourcesTotals,
  SavingsAvailable,
  TableAction,
} from '../../Costs.types.react';
import { OptionType } from '~globalTypes';

import { hasKey } from '~utils/global.helpers.react';
import { strings } from '~utils/strings';

import './AggregatorBarChart.react.scss';

type BarChartProps = {
  data: ResourceSummary[];
  aggregators: MultiValue<OptionType>;
  setFilters: (wasClicked: boolean, option: SelectedFilters, removedValue: boolean) => void;
  totals: ResourcesTotals;
  savingsAvailable: SavingsAvailable;
  selectedFilters: SelectedFilters;
  isLoading: boolean;
  isSavingsAvailableLoading: boolean;
  setTableState: React.Dispatch<TableAction>;
};

const AggregatorBarChart = ({
  data,
  aggregators,
  setFilters,
  totals,
  savingsAvailable,
  selectedFilters,
  isLoading,
  isSavingsAvailableLoading,
  setTableState,
}: BarChartProps): JSX.Element => {
  const [maxCost, setMaxCost] = useState<number>(0);

  const chartTitle = useMemo(() => {
    if (aggregators.length === 1) {
      return aggregators[0].label;
    } else if (aggregators.length > 1) {
      return aggregators
        .map((aggregator) =>
          aggregator?.section
            ? `${aggregator.section}${strings.punctuation.plural} ${strings.general.Label}`
            : aggregator.label,
        )
        .join(', ');
    }
    return '';
  }, [aggregators]);

  const costsData = useMemo(() => {
    let finalData: Pick<AggregatorBarData, 'aggregator' | 'totalCost' | 'height'>[] = [];
    if (data) {
      const allData = [...data]
        .sort((a, b) => b.costs.actual.total - a.costs.actual.total)
        .map((aggregatorData) => {
          const allAggregators = {};
          const currentAggregators =
            aggregators.length > 0
              ? aggregators
              : aggregatorOptions.concat([{ value: 'pod', label: 'pod' }]);
          currentAggregators.forEach((aggregator) => {
            const key = aggregatorData[aggregator.value]
              ? aggregator.value
              : findLabel(aggregator.section);
            allAggregators[key] = aggregatorData[key];
          });

          const aggregator = currentAggregators
            .map((aggregator) => {
              const label = findLabel(aggregator.section);
              if (aggregatorData[aggregator.value]) {
                return aggregators.length === 1
                  ? `${aggregatorData[aggregator.value]}`
                  : aggregatorData[aggregator.value];
              } else if (aggregatorData[label]) {
                return aggregatorData[label].map((_label) => _label.value);
              }
              return '';
            })
            .join('-');

          let finalObject = {
            aggregator,
            totalCost: aggregatorData.costs.actual.total,
            ...allAggregators,
          };

          const resourceTypeOptions = getResourceTypeOptions(aggregatorData);

          if (!selectedFilters.resourceTypes) {
            Object.values(totalColumnsOptions).forEach(
              (type) =>
                (finalObject = {
                  ...finalObject,
                  ...resourceTypeOptions[type],
                }),
            );
          } else {
            selectedFilters.resourceTypes.forEach((type) => {
              const typeKey = totalColumnsOptions[type.value];
              finalObject = { ...finalObject, ...resourceTypeOptions[typeKey] };
            });
          }

          return finalObject;
        });

      if (allData.length) {
        setMaxCost(allData[0].totalCost || 0);
        finalData = allData.map((datum) => {
          datum.height =
            datum.totalCost > 0.15 ? datum.totalCost : (allData[0].totalCost || 0) * 0.003;
          return datum;
        }) as Pick<AggregatorBarData, 'aggregator' | 'totalCost' | 'height'>[];
      }
    }

    return finalData;
  }, [data]);

  const finalTotals = useMemo(() => {
    const retrievedSavingsAvailable = savingsAvailable?.savingsAvailable || 0;
    const totalCost = totals?.totalCost || 0;

    return {
      avgBilled: totalCost / (totals?.numberOfRows || 1),
      totalBilled: totalCost,
      totalRecCosts: `${formatCost(retrievedSavingsAvailable)} (${
        retrievedSavingsAvailable !== 0 && totalCost !== 0
          ? Math.round((retrievedSavingsAvailable / totalCost) * 100)
          : 0
      }%)`,
      totalWithSavings: totalCost + retrievedSavingsAvailable,
    };
  }, [totals, savingsAvailable]);

  const handleChartClick = ({ data }: { data: AggregatorBarData }) => {
    const allFilters: Record<string, CostsOptions[]> = {};
    const currentAggregators =
      aggregators.length > 0
        ? aggregators
        : aggregatorOptions.concat([{ value: 'pod', label: strings.efficiency.Pod }]);
    currentAggregators.forEach((aggregator) => {
      if (aggregator.value !== 'pod') {
        const formattedFilterType = `${aggregator.value}s`;
        if (hasKey(data, aggregator.value) && !hasKey(allFilters, formattedFilterType)) {
          allFilters[formattedFilterType] = [
            {
              label: data[aggregator.value],
              value: data[aggregator.value],
              section: formattedFilterType,
            },
          ];
        } else if (hasKey(data, aggregator.value) && hasKey(allFilters, formattedFilterType)) {
          allFilters[formattedFilterType].push({
            label: data[aggregator.value],
            value: data[aggregator.value],
            section: formattedFilterType,
          });
        }
      }
    });
    setFilters(true, allFilters, false);
  };

  return (
    <div>
      <Card className="costs-aggregator-bar-chart">
        <Card.Title className="costs-aggregator-bar-chart__title">
          {chartTitle ? (
            <>
              {strings.efficiency.costBy}{' '}
              <span className="costs-aggregator-bar-chart__aggregators">{chartTitle}</span>
            </>
          ) : (
            `${strings.efficiency.costBy} ${strings.efficiency.Pod}`
          )}
        </Card.Title>
        <Card.Body>
          {isLoading && (
            <LoadingSpinner containerClassNames="costs-aggregator-bar-chart__spinner" />
          )}
          {!isLoading && costsData.length > 0 && maxCost !== 0 && (
            <div className="costs-aggregator-bar-chart__chart">
              <BarChart
                costsData={costsData}
                maxCost={maxCost}
                handleChartClick={handleChartClick}
                aggregators={aggregators}
              />
            </div>
          )}
          {!isLoading && (!data || maxCost === 0) && (
            <div className="costs-aggregator-bar-chart__no-aggregator">
              {strings.efficiency.noDataToDisplay}
            </div>
          )}
        </Card.Body>
      </Card>
      <AggregatorBar data={finalTotals} isLoading={isSavingsAvailableLoading} />
    </div>
  );
};

export default AggregatorBarChart;
