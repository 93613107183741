import React, { useState, useEffect, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { Card } from '@fairwindsops/ui-components';
import SelectDropdown from '~reactComponents/SelectDropdown/SelectDropdown.react';
import LoadingSpinner from '~reactComponents/LoadingSpinner/LoadingSpinner.react';
import LineChart from '~reactComponents/charts/LineChart/LineChart.react';

import { ChartDataType, LineChartProps } from '../../../Efficiency.types.react';

import {
  aggregateDataByTimePeriod,
  findAvgByDay,
  handleChartTypeChange,
  formatData,
} from '../../../Efficiency.helpers.react';
import { colors, dropdownOptions } from '../components.config.react';

import { REPORT_HUB } from '~reactComponents/NavigationReact/Navigation.config.react';
import { getCurrentTimezone } from '~reactHelpers';
import { COLORS } from '~utils/styling';
import { strings } from '~utils/strings';

import './LineChart.react.scss';

dayjs.extend(utc);
dayjs.extend(timezone);

const ResourceLineChart = ({ data, title, router, route }: LineChartProps) => {
  const timeZone = getCurrentTimezone();
  const dataKey = title === 'Capacity' ? 'allocatable' : 'inUse';
  const cluster = route.params.cluster;
  const [selectedDisplay, setSelectedDisplay] = useState<string>('CPU');
  const [highestPoint, setHighestPoint] = useState<number>(0);
  const [status, setStatus] = useState<string>('idle');

  const returnDataToSum = (datum: any): number => {
    if (dataKey === 'inUse') {
      return (
        (datum[dataKey][selectedDisplay.toLowerCase()].raw /
          datum.allocatable[selectedDisplay.toLowerCase()].raw) *
        100
      );
    }
    return datum[dataKey][selectedDisplay.toLowerCase()].raw;
  };

  const formattedData = useMemo(() => {
    if (!Object.keys(data).length) return [];

    setStatus('pending');
    const keys: string[] = Object.keys(data);
    let foundHighest = 0;
    const allData: ChartDataType[] = [];
    const valuesByTimePeriod = aggregateDataByTimePeriod(data, 'day');
    const avgByDay = findAvgByDay(valuesByTimePeriod, { getValue: returnDataToSum });

    if (keys.length) {
      keys.forEach((clusterName) => {
        if (Object.keys(valuesByTimePeriod).includes(clusterName)) {
          const clusterData = valuesByTimePeriod[clusterName];
          const formattedDatapoints = Object.keys(clusterData).map((datum) => {
            const allClusters = Object.keys(avgByDay);
            const match: string | undefined = allClusters.find(
              (cluster) => cluster === clusterName,
            );
            let yPoint;
            if (match) {
              if (dataKey === 'inUse') {
                yPoint = avgByDay[clusterName][datum];
              } else {
                yPoint = formatData(avgByDay[clusterName][datum], selectedDisplay);
              }
            } else {
              yPoint = 0;
            }

            if (yPoint > foundHighest) foundHighest = yPoint;

            return {
              x: datum,
              y: yPoint,
            };
          });

          setHighestPoint(foundHighest * 1.1);

          const finalData = formattedDatapoints.filter(
            (point) => point !== undefined && point.y > 0,
          );

          if (finalData.length) {
            allData.push({
              id: clusterName,
              data: finalData,
            });
          } else {
            return;
          }
        }
      });
    }
    return allData;
  }, [data, selectedDisplay]);

  useEffect(() => {
    if (Object.keys(data).length && !formattedData.length) {
      setStatus('noData');
    } else if (Object.keys(data).length && formattedData.length) {
      setStatus(strings.noTranslate.resolved);
    }
  }, [formattedData]);

  const yAxisLegend =
    selectedDisplay.toLowerCase() === 'cpu' ? strings.general.CPU : strings.general.GB;

  const tooltip = (point: any) => {
    const unit =
      dataKey === 'allocatable'
        ? selectedDisplay.toLowerCase() === 'cpu'
          ? strings.general.CPU
          : strings.general.GB
        : strings.punctuation.percent;
    return (
      <div className="line-chart-tooltip">
        <div className="line-chart-tooltip__content">
          <div
            className="line-chart-tooltip__square"
            style={{
              backgroundColor: point.serieColor,
            }}
          ></div>
          <strong>{point.serieId}</strong>
        </div>
        <strong>
          {timeZone
            ? dayjs(point.data.x).tz(timeZone).format('MM/DD')
            : dayjs(point.data.x).format('MM/DD')}
          :
        </strong>
        {` ${point.data.yFormatted}${unit}`}
      </div>
    );
  };

  const lineChartProps = useMemo(() => {
    if (highestPoint > 0) {
      return {
        data: formattedData,
        margin: { top: 5, right: 50, bottom: 50, left: 60 },
        xScale: {
          type: 'time',
          format: '%Y-%m-%d',
          useUTC: false,
          precision: 'day',
        },
        xFormat: 'time:%Y-%m-%d',
        yScale: {
          type: 'linear',
          min: 0,
          max: dataKey === 'allocatable' || highestPoint > 100 ? highestPoint : 100,
          stacked: false,
          reverse: false,
        },
        axisBottom: {
          format: '%m/%d',
          tickValues: 'every 3 days',
        },
        axisLeft: {
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendOffset: -45,
          legend: yAxisLegend,
          legendPosition: 'middle',
          format: (value: number) => {
            if (dataKey === 'allocatable') {
              return value;
            } else {
              return `${value}%`;
            }
          },
        },
        tooltip: tooltip,
        colors: colors,
      };
    }

    return { data: [] };
  }, [formattedData, selectedDisplay, highestPoint]);

  return (
    <Card style={{ marginTop: '2rem', width: '49%' }} data-cy="line-chart-card">
      <Card.Body style={{ padding: '0' }}>
        <Card.Header
          style={{ borderBottom: COLORS.BORDER.TABLE_HEADER, padding: '0.5rem 0 0.5rem 1.25rem' }}
        >
          <h1 className="line-chart-title" title={title}>
            {title}
          </h1>
          <div className="line-chart-dropdown">
            <div className="line-chart-dropdown__container">
              <SelectDropdown
                label={strings.ariaLabels.dataDropdown}
                options={dropdownOptions}
                handleChartTypeChange={(value) => handleChartTypeChange(value, setSelectedDisplay)}
              />
            </div>
          </div>
        </Card.Header>
        {status === 'pending' && (
          <div className="line-chart-loading-spinner">
            <LoadingSpinner />
          </div>
        )}
        {status === 'noData' && (
          <div className="line-chart-no-data">
            <h1>{strings.efficiency.installPrometheusForData}</h1>
            {cluster && (
              <Button
                variant="primary"
                onClick={() =>
                  router().push({
                    name: REPORT_HUB,
                    params: { cluster, org: route.params.org, report: 'prometheus-metrics' },
                  })
                }
              >
                {strings.efficiency.installPrometheus}
              </Button>
            )}
          </div>
        )}
        {formattedData?.length > 0 && (
          <div
            style={{ width: '100%', height: '350px', paddingTop: '1.5rem' }}
            aria-label={strings.ariaLabels.lineChart.replace('#chartName', title)}
            role="img"
          >
            <LineChart {...lineChartProps} />
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default ResourceLineChart;
