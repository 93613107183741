import React, { useEffect, useMemo, useState } from 'react';
import { useTable, useSortBy, useResizeColumns, useFlexLayout } from 'react-table';
import toast, { Toaster } from 'react-hot-toast';

import { AddButton, Card } from '@fairwindsops/ui-components';

import Table from '~reactComponents/Table/Table.react';
import ConfirmationDialog from '~reactComponents/ConfirmationDialog/ConfirmationDialog.react';
import { NEW_TEAM } from '~reactComponents/NavigationReact/Navigation.config.react';

import TeamModal from '../teamModal/TeamModal.react';

import { IRoute, IRouter, IStore, Team, TeamMembership } from '~utils/global.types.react';
import { strings } from '~utils/strings';
import { sendRequest } from '~utils/request';
import logger from '~utils/logger';

import { getAllTeamsTableColumns } from './AllTeamsTable.config.react';

import {
  AllTeamsTableConfiguration,
  AllTeamsTableMembership,
  AllTeamsTableRowData,
} from './AllTeamsTable.types.react';

import './AllTeamsTable.react.scss';

type AllTeamsTableProps = {
  route: IRoute;
  router: () => IRouter;
  store: () => IStore;
};

const AllTeamsTable = ({ route, router, store }: AllTeamsTableProps) => {
  const [teams, setTeams] = useState<Team[]>([]);
  const [memberships, setMemberships] = useState<TeamMembership[]>([]);
  const [isConfirmDeleteModalShown, setIsConfirmDeleteModalShown] = useState<boolean>(false);
  const [selectedTeam, setSelectedTeam] = useState<Team | null>(null);
  const [selectedTeamModal, setSelectedTeamModal] = useState<AllTeamsTableRowData | null>(null);

  const organization = store().getters.organization;
  const user = store().getters.user;
  const isOrgOwner = store().getters.isOrgOwner;

  const baseUrl = `/v0/organizations/${organization.Name}`;

  const getTeams = async () => {
    try {
      setTeams((await sendRequest('GET', `${baseUrl}/teams`, {}, null)) || []);
    } catch (e) {
      logger.logError('error_getting_teams_team-management', e);
    }
  };

  const getMemberShips = async () => {
    try {
      setMemberships((await sendRequest('GET', `${baseUrl}/memberships`, {}, null)) || []);
    } catch (e) {
      logger.logError('error_getting_teams_team-management', e);
    }
  };

  const getData = async () => {
    await getTeams();
    await getMemberShips();
  };

  useEffect(() => {
    getData();
  }, []);

  const getTeamById = (teamId: number) => {
    return teams.find((team) => team.ID === teamId);
  };

  const onTeamDeleted = (teamId: number) => {
    if (!teamId) {
      return;
    }

    const team = getTeamById(teamId);

    if (!team) {
      return;
    }

    setSelectedTeam(team);
    setIsConfirmDeleteModalShown(true);
  };

  const updateTeamAccess = async (team: Team) => {
    if (!team) {
      return;
    }

    try {
      await sendRequest(
        'PATCH',
        `${baseUrl}/teams`,
        {
          data: team,
          showSuccessAlert: false,
        },
        null,
      );

      toast.success(strings.teamManagement.updateTeamAccessSuccess);

      await getData();
    } catch (e) {
      toast.error(strings.teamManagement.updateTeamAccessFailed);
      logger.logError('error_updating_team_access_team-management', e);
    }
  };

  const onClusterChanged = async (teamId: number, cluster: AllTeamsTableConfiguration) => {
    if (!teamId || !cluster) {
      return;
    }

    const team = getTeamById(teamId);

    if (!team) {
      return;
    }

    if (cluster.isAllowed) {
      team.Clusters =
        cluster.value === null ? [] : team.Clusters.filter((clus) => clus !== cluster.value);
    } else {
      team.DisallowedClusters = team.DisallowedClusters.filter((clus) => clus !== cluster.value);
    }

    await updateTeamAccess(team);
  };

  const onNamespaceChanged = async (teamId: number, namespace: AllTeamsTableConfiguration) => {
    if (!teamId || !namespace) {
      return;
    }

    const team = getTeamById(teamId);

    if (!team) {
      return;
    }

    if (namespace.isAllowed) {
      team.Namespaces =
        namespace.value === null
          ? []
          : team.Namespaces.filter((names) => names !== namespace.value);
    } else {
      team.DisallowedNamespaces = team.DisallowedNamespaces.filter(
        (names) => names !== namespace.value,
      );
    }

    await updateTeamAccess(team);
  };

  const onRepositoryChanged = async (teamId: number, repository: AllTeamsTableConfiguration) => {
    if (!teamId || !repository) {
      return;
    }

    const team = getTeamById(teamId);

    if (!team) {
      return;
    }

    if (repository.isAllowed) {
      team.Repositories =
        repository.value === null
          ? []
          : team.Repositories.filter((repo) => repo !== repository.value);
    } else {
      team.DisallowedRepositories = team.DisallowedRepositories.filter(
        (repo) => repo !== repository.value,
      );
    }

    await updateTeamAccess(team);
  };

  const onExpandIconClicked = (teamId: number) => {
    if (!teamId) {
      return;
    }

    setSelectedTeamModal(data.find((team) => team.ID === teamId) || null);
  };

  const columns = useMemo(
    () =>
      getAllTeamsTableColumns({
        isOrgOwner,
        onTeamDeleted,
        onClusterChanged,
        onNamespaceChanged,
        onRepositoryChanged,
        onExpandIconClicked,
      }),
    [teams, memberships],
  );

  const getAccountTeamRole = (team: Team) => {
    return team.Memberships.find((membership) => membership.UserID === user.Email)?.Role || null;
  };

  const getTeamMemberships = (team: Team) => {
    const teamMemberships = team.Memberships;

    if (!teamMemberships?.length || !memberships?.length) {
      return [];
    }

    const transformedTeamMemberships: AllTeamsTableMembership[] = [];

    for (const membership of teamMemberships) {
      const membershipInfo = memberships.find((member) => member.Email === membership.UserID);

      transformedTeamMemberships.push(
        membershipInfo?.FirstName
          ? { firstName: membershipInfo.FirstName, lastName: membershipInfo.LastName }
          : { firstName: '', lastName: '' },
      );
    }

    return transformedTeamMemberships;
  };

  const getTeamClusters = (team: Team) => {
    const transformedClusters: AllTeamsTableConfiguration[] = [];

    if (team?.Clusters === null) {
      transformedClusters.push({ value: null, isAllowed: true });
    } else if (team?.Clusters?.length) {
      for (const cluster of team.Clusters) {
        transformedClusters.push({ value: cluster, isAllowed: true });
      }
    }

    if (team?.DisallowedClusters?.length) {
      for (const cluster of team.DisallowedClusters) {
        transformedClusters.push({ value: cluster, isAllowed: false });
      }
    }

    return transformedClusters;
  };

  const getTeamNamespaces = (team: Team) => {
    const transformedNamespaces: AllTeamsTableConfiguration[] = [];

    if (team?.Namespaces === null) {
      transformedNamespaces.push({ value: null, isAllowed: true });
    } else if (team?.Namespaces?.length) {
      for (const namespace of team.Namespaces) {
        transformedNamespaces.push({ value: namespace, isAllowed: true });
      }
    }

    if (team?.DisallowedNamespaces?.length) {
      for (const namespace of team.DisallowedNamespaces) {
        transformedNamespaces.push({ value: namespace, isAllowed: false });
      }
    }

    return transformedNamespaces;
  };

  const getTeamRepositories = (team: Team) => {
    const transformedRepositories: AllTeamsTableConfiguration[] = [];

    if (team?.Repositories === null) {
      transformedRepositories.push({ value: null, isAllowed: true });
    } else if (team?.Repositories?.length) {
      for (const repository of team.Repositories) {
        transformedRepositories.push({ value: repository, isAllowed: true });
      }
    }

    if (team?.DisallowedRepositories?.length) {
      for (const repository of team.DisallowedRepositories) {
        transformedRepositories.push({ value: repository, isAllowed: false });
      }
    }

    return transformedRepositories;
  };

  const isTeamAdmin = (team: Team) =>
    isOrgOwner ||
    (team.Memberships &&
      team.Memberships.some(
        (membership) => membership.UserID === user.Email && membership.Role === 'admin',
      ));

  const buildTableRowData = (team: Team): AllTeamsTableRowData => ({
    ID: team.ID,
    Team: team.Name,
    MyRole: getAccountTeamRole(team),
    Memberships: getTeamMemberships(team),
    Clusters: getTeamClusters(team),
    Namespaces: getTeamNamespaces(team),
    Repositories: getTeamRepositories(team),
    IsTeamAdmin: isTeamAdmin(team),
  });

  const getTableRowsData = () => {
    const tableRowsData: AllTeamsTableRowData[] = [];

    for (const team of teams) {
      tableRowsData.push(buildTableRowData(team));
    }

    return tableRowsData;
  };

  const data = useMemo(() => getTableRowsData(), [teams, memberships, user]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useFlexLayout,
    useSortBy,
    useResizeColumns,
  );

  const onAddTeamClicked = () => {
    router().push({ name: NEW_TEAM });
  };

  const onTeamDeleteConfirmed = async () => {
    if (!selectedTeam) {
      return;
    }

    try {
      await sendRequest('DELETE', `${baseUrl}/teams/${selectedTeam.ID}`, {}, null);
      await getData();

      toast.success(strings.teamManagement.deleteTeamSuccess);
    } catch (e) {
      toast.error(strings.teamManagement.deleteTeamFailed);
      logger.logError('error_deleting_team_team-management', e);
    }

    setIsConfirmDeleteModalShown(false);
    setSelectedTeam(null);
  };

  return (
    <>
      <Card className="all-teams-table">
        <Card.Title className="all-teams-table__title">
          {strings.teamManagement.allTeams}
        </Card.Title>
        <Card.Body>
          <div className="all-teams-table__body">
            {isOrgOwner ? (
              <AddButton
                action={onAddTeamClicked}
                buttonText={strings.teamManagement.addTeam}
                class="all-teams-table__add-button"
                testLabel="add-team-button"
              />
            ) : null}
            <div className="all-teams-table__body-table">
              <Table
                aria-hidden="true"
                dataCy="all-teams-table"
                tableInstance={tableInstance}
                caption={strings.teamManagement.allTeamsTable}
              />
            </div>
          </div>
        </Card.Body>
      </Card>
      <ConfirmationDialog
        cancelButtonClasses="custom-cancel-button"
        cancelButtonText={strings.general.Cancel}
        confirmButtonText={strings.general.confirm}
        isModalShown={isConfirmDeleteModalShown}
        modalBodyClasses="custom-confirm-modal-body"
        modalContent={strings.teamManagement.deleteTeamModalContent.replace(
          '$TEAM',
          selectedTeam?.Name || '',
        )}
        modalContentClasses="custom-confirm-modal-content"
        modalTitle={strings.teamManagement.confirmTeamRemoval}
        onConfirmClicked={onTeamDeleteConfirmed}
        onModalHidden={(isModalShown: boolean | undefined) => {
          setIsConfirmDeleteModalShown(!!isModalShown);
          setSelectedTeam(null);
        }}
      />
      {selectedTeamModal ? (
        <TeamModal
          isModalShown={!!selectedTeamModal.Team}
          modalTitle={selectedTeamModal.Team}
          onModalHidden={() => setSelectedTeamModal(null)}
          route={route}
          router={router}
          store={store}
          teamId={String(selectedTeamModal.ID)}
        />
      ) : null}
      <Toaster />
    </>
  );
};

export default AllTeamsTable;
