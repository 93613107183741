import { ResponsiveLine } from '@nivo/line';
import React from 'react';
import { Card } from 'react-bootstrap';
import { LoadingSpinner } from '@fairwindsops/ui-components';
import { strings } from '~utils/strings';

import './PodCountChart.react.scss';
import { getCurrentTimezone } from '~utils/global.helpers.react';
import dayjs from 'dayjs';

export type PodCountChartData = {
  id: string;
  data: PodCountChartPoint[];
};

export type PodCountChartPoint = {
  x: string;
  y: number;
};

export type PodCountChartProps = {
  data: PodCountChartData[];
  isLoading: boolean;
};

const PodCountChart = ({ data, isLoading }: PodCountChartProps) => {
  const timeZone = getCurrentTimezone();

  // this is needed to display the correct order of the legends
  const legendsDatum = [...data].map((data, index) => ({
    id: data.id,
    label: data.id,
    color: ['#B45F35', '#BA388C', '#932D4C'][index],
  }));

  const hasData = data.some((d) => d.data.length > 0);

  return (
    <Card>
      <Card.Title className="pods-count-over-time-chart__title">Pod Count</Card.Title>
      <Card.Body>
        <div
          aria-label="Line chart displaying the pods counts over time"
          role="img"
          className="pods-count-over-time-chart__chart"
        >
          {isLoading && <LoadingSpinner containerClassNames="pods-count-over-time-chart__spinner" />}
          {!isLoading && hasData ? (
            <ResponsiveLine
              role="figure"
              curve="monotoneX"
              data={data}
              margin={{ top: 25, right: 25, bottom: 50, left: 50 }}
              xScale={{
                type: 'time',
                format: '%Y-%m-%d',
                precision: 'day',
                useUTC: false,
              }}
              xFormat="time:%Y-%m-%d"
              yScale={{ type: 'linear', min: 'auto', max: 'auto' }}
              yFormat=">-.2f"
              axisBottom={{
                format: (value: Date) => {
                  const formatter = timeZone ? dayjs(value).tz(timeZone) : dayjs(value);
                  return formatter.format('M/D');
                },
                legendOffset: 36,
                legendPosition: 'middle',
              }}
              axisLeft={{ format: '>-.2f' }}
              useMesh={true}
              enableSlices="x"
              colors={['#B45F35', '#BA388C', '#932D4C']} // avg, max, min
              legends={[
                {
                  anchor: 'bottom-left',
                  direction: 'row',
                  itemWidth: 80,
                  itemHeight: 20,
                  translateY: 50,
                  data: legendsDatum,
                },
              ]}
            />
          ) : (
            <div className="pods-count-over-time-chart__no-data">{strings.general.noDataToDisplay}</div>
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

export default PodCountChart;
