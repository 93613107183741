import React, { useState, useEffect, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import dayjs, { ManipulateType } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { Card } from '@fairwindsops/ui-components';
import SelectDropdown from '~reactComponents/SelectDropdown/SelectDropdown.react';
import LineChart from '~reactComponents/charts/LineChart/LineChart.react';
import LoadingSpinner from '~reactComponents/LoadingSpinner/LoadingSpinner.react';

import {
  NodeCostsType,
  ChartDataType,
  PointType,
  CostChartProps,
} from '../../../Efficiency.types.react';
import { OptionType } from '~globalTypes';

import {
  getClusterData,
  aggregateDataByTimePeriod,
  findAvgByDay,
} from '../../../Efficiency.helpers.react';
import { colors, costDropdownOptions } from '../components.config.react';

import { REPORT_HUB } from '~reactComponents/NavigationReact/Navigation.config.react';
import { getCurrentTimezone } from '~reactHelpers';
import { strings } from '~utils/strings';
import { COLORS } from '~utils/styling';

import './CostChart.react.scss';

dayjs.extend(utc);
dayjs.extend(timezone);

const CostChart = ({ baseURL, router, route }: CostChartProps) => {
  const timeZone = getCurrentTimezone();
  const cluster = route.params.cluster;

  const [selectedDisplay, setSelectedDisplay] = useState<OptionType>({
    value: 'month',
    label: strings.dateOptions.lastMonth,
  });
  const [highestCost, setHighestCost] = useState<number>(0);
  const [data, setData] = useState<NodeCostsType>({});
  const [status, setStatus] = useState<string>('idle');
  const [noCost, setNoCost] = useState<boolean>(false);

  const getData = async () => {
    const end = timeZone ? dayjs().tz(timeZone) : dayjs();
    const start = end
      .clone()
      .subtract(
        1,
        (selectedDisplay.value !== 'hour' ? selectedDisplay.value : 'day') as ManipulateType,
      )
      .toDate();
    const response = await getClusterData(
      baseURL,
      start,
      end.toDate(),
      selectedDisplay.value === 'hour' ? strings.noTranslate.hourly : strings.noTranslate.daily,
      cluster,
    );

    setData(response);
  };

  useEffect(() => {
    setStatus('pending');
    getData();
  }, [selectedDisplay.value]);

  const handleDropdownChange = (selected: unknown) => {
    setHighestCost(0);
    setData({});
    setSelectedDisplay(selected as OptionType);
  };

  const formattedData = useMemo(() => {
    const allData: ChartDataType[] = [];
    const valuesByTimePeriod = aggregateDataByTimePeriod(
      data,
      selectedDisplay.value === 'hour' ? 'hour' : 'day',
    );
    let currentHighestCost = 0;
    const avgByDay = findAvgByDay(valuesByTimePeriod, {
      chart: 'cost',
      sum: selectedDisplay.value === 'day',
    });

    if (!Object.keys(avgByDay).length && Object.keys(valuesByTimePeriod).length) {
      setNoCost(true);
      setStatus('noData');
      return allData;
    }
    Object.keys(valuesByTimePeriod).forEach((key) => {
      const dateKeys = Object.keys(valuesByTimePeriod[key]);
      const formattedDatapoints: PointType[] = [];

      dateKeys.forEach((datum) => {
        const cost = avgByDay[key] ? (avgByDay[key][datum] ? avgByDay[key][datum] : 0) : 0;
        const format = selectedDisplay.value === 'hour' ? 'YYYY-MM-DD HH' : 'YYYY-MM-DD';

        if (cost > currentHighestCost) currentHighestCost = cost;

        if (cost > 0) {
          formattedDatapoints.push({
            x: dayjs(datum).utc().format(format),
            y: cost,
          });
        }
      });

      setHighestCost(currentHighestCost);

      if (formattedDatapoints.length) {
        allData.push({
          id: key,
          data: formattedDatapoints,
        });
      } else {
        return;
      }
    });
    return allData;
  }, [data, selectedDisplay.value]);

  const tickValues = useMemo(() => {
    switch (selectedDisplay.value) {
      case 'week':
        return 'every 1 days';
      case 'hour':
        return 'every 2 hours';
      case 'month':
      default:
        return 'every 3 days';
    }
  }, [selectedDisplay.value]);

  useEffect(() => {
    if (!formattedData.length) {
      setStatus('noData');
    } else if (Object.keys(data).length && formattedData.length) {
      setStatus(strings.noTranslate.resolved);
    }
  }, [data, selectedDisplay.value]);

  const tooltip = (point: any) => {
    return (
      <div className="cost-chart-tooltip">
        <div className="cost-chart-tooltip__content">
          <div
            className="cost-chart-tooltip__square"
            style={{
              backgroundColor: point.serieColor,
            }}
          ></div>
          <strong>{point.serieId}</strong>
        </div>
        <div>
          <strong>
            {selectedDisplay.value === 'hour'
              ? strings.efficiency.avgHourlyCost
              : strings.efficiency.totalDailyCost}
          </strong>
        </div>
        <strong>{dayjs(point.data.x).utc().format('MM/DD')}:</strong>
        {`  $${point.data.yFormatted}`}
      </div>
    );
  };

  const lineChartProps = {
    data: formattedData,
    margin: { top: 10, right: 50, bottom: 50, left: 70 },
    xScale: {
      type: 'time',
      format: selectedDisplay.value === 'hour' ? '%Y-%m-%d %H' : '%Y-%m-%d',
      useUTC: false,
      precision: selectedDisplay.value === 'hour' ? 'hour' : 'day',
    },
    xFormat: selectedDisplay.value === 'hour' ? 'time:%H' : 'time:%Y-%m-%d',
    yScale: {
      type: 'linear',
      min: 'auto',
      max: highestCost * 1.15,
      stacked: false,
      reverse: false,
    },
    axisBottom: {
      format: (value: Date) => {
        if (selectedDisplay.value === 'hour') {
          return dayjs(value).utc().format('hh A');
        } else {
          return dayjs(value).utc().format('MM/DD');
        }
      },
      tickValues: `${tickValues}`,
    },
    axisLeft: {
      format: (value: number) => `$${value.toFixed(2)}`,
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
    },
    colors: colors,
    tooltip: tooltip,
  };

  return (
    <Card style={{ marginTop: '2rem' }} data-cy="cost-chart-card">
      <Card.Body style={{ padding: '0' }}>
        <Card.Header
          style={{ borderBottom: COLORS.BORDER.TABLE_HEADER, padding: '0.5rem 0 0.5rem 1.25rem' }}
        >
          <h1 className="cost-chart-title">{strings.general.TotalClusterCost}</h1>
          <div className="cost-chart-dropdown">
            <div className="cost-chart-dropdown__container">
              <SelectDropdown
                label={strings.ariaLabels.timeperiodDropdown}
                options={costDropdownOptions}
                defaultValue={selectedDisplay}
                handleChartTypeChange={handleDropdownChange}
              />
            </div>
          </div>
        </Card.Header>
        {status === 'pending' && (
          <div className="cost-chart-loading-spinner">
            <LoadingSpinner />
          </div>
        )}
        {noCost && (
          <div className="cost-chart-no-data">
            <h1>{strings.efficiency.noCostDataToDisplay}</h1>
          </div>
        )}
        {status === 'noData' && !noCost && (
          <div className="cost-chart-no-data">
            <h1>{strings.efficiency.installPrometheusForData}</h1>
            {cluster && (
              <Button
                variant="primary"
                onClick={() =>
                  router().push({
                    name: REPORT_HUB,
                    params: { cluster, org: route.params.org, report: 'prometheus-metrics' },
                  })
                }
              >
                {strings.efficiency.installPrometheus}
              </Button>
            )}
          </div>
        )}
        {status === strings.noTranslate.resolved && (
          <div
            className="cost-chart-container"
            aria-label={strings.ariaLabels.capacityCostChart}
            role="img"
          >
            <LineChart {...lineChartProps} />
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default CostChart;
