import React, { useState, useEffect, useMemo } from 'react';
import { WaffleTooltipData } from '@nivo/waffle';

import { Card } from '@fairwindsops/ui-components';
import SelectDropdown from '~reactComponents/SelectDropdown/SelectDropdown.react';
import LoadingSpinner from '~reactComponents/LoadingSpinner/LoadingSpinner.react';
import HexagonOutlineIcon from '~reactComponents/Icons/HexagonOutline.icon.react';
import HexagonIcon from '~reactComponents/Icons/Hexagon.icon.react';
import Legend from './Legend/Legend.react';
import HexbinChart from './HexbinChart.react';

import { NodeDataType, Utilization, Node, WaffleInput } from '../../../Efficiency.types.react';

import { hasKey } from '~reactHelpers';
import { handleChartTypeChange } from '../../../Efficiency.helpers.react';
import { chartSettingsByNodeCount, dropdownOptions } from '../components.config.react';
import { strings } from '~utils/strings';
import { COLORS } from '~utils/styling';

import './NodeHexbinChart.react.scss';

const NodeHexbinChart = ({ data }: { data: NodeDataType[] }) => {
  const [selectedDisplay, setSelectedDisplay] = useState<string>('cpu');
  const [nodes, setNodes] = useState<Node[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    const formattedNodes = data.map((datum) => {
      const utilization = {
        CPULimits: datum.Utilization.cpuLimitsFraction,
        CPURequests: datum.Utilization.cpuRequestsFraction,
        MemoryLimits: datum.Utilization.memoryLimitsFraction,
        MemoryRequests: datum.Utilization.memoryRequestsFraction,
        role: datum.Labels['kubernetes.io/role'],
      };
      return {
        id: `${datum.Name}`,
        value: 1,
        label: datum.Name,
        color: COLORS.CORE.WHITE,
        utilization: utilization,
      };
    });

    setNodes(formattedNodes);
    setTimeout(() => setLoading(false), 5000);
  }, [data]);

  const chartSettings = useMemo(() => {
    let settings: Record<string, number> = {};
    chartSettingsByNodeCount.forEach((setting) => {
      if (nodes.length >= setting.minCount && nodes.length < setting.maxCount) {
        settings = setting;
      }
    });

    return {
      numCols: Math.round(Math.sqrt(nodes.length) * settings?.multiplier),
      chartHeight: settings?.height,
    };
  }, [data, nodes, selectedDisplay]);

  function getPoints(input: WaffleInput) {
    const x = input.x;
    const y = input.y;
    const size = input.size;
    const position = input.position;
    const colNum = position % chartSettings.numCols;
    const rowNum = (position - colNum) / chartSettings.numCols;
    const offsetRow = rowNum % 2 == 0;

    const multiplier = nodes.length > 55 ? 0.45 : 0.5;
    const rad = size * multiplier;
    const xOffset = rad * Math.sin(Math.PI / 3);
    const yOffset = rad * Math.cos(Math.PI / 3);
    const rowOffset = offsetRow ? rad : 0;
    return [
      [x + rowOffset, y + rad],
      [x + rowOffset + xOffset, y + yOffset],
      [x + rowOffset + xOffset, y - yOffset],
      [x + rowOffset, y - rad],
      [x + rowOffset - xOffset, y - yOffset],
      [x + rowOffset - xOffset, y + yOffset],
    ]
      .map((p) => p.join(','))
      .join(' ');
  }

  function getBorderColor(utilization: Utilization) {
    let color = COLORS.CORE.WHITE;
    if (!utilization) return color;

    if (utilization) {
      const key = `${selectedDisplay === 'cpu' ? strings.general.CPU : strings.general.Memory}${
        strings.general.Limits
      }`;
      if (hasKey(utilization, key)) {
        if (utilization[key] > 1.2) {
          color = COLORS.CHARTS.HEXBIN.BORDER_DANGER;
        } else if (utilization[key] < 1) {
          color = COLORS.CHARTS.HEXBIN.BORDER_FINE;
        } else {
          color = COLORS.CHARTS.HEXBIN.BORDER_GOOD;
        }
      }
    }

    return color;
  }

  function getFillColor(utilization: Utilization) {
    let color = COLORS.CORE.WHITE;
    if (!utilization) return color;

    if (Object.keys(utilization).length > 0) {
      const key = `${selectedDisplay === 'cpu' ? strings.general.CPU : strings.general.Memory}${
        strings.general.Requests
      }`;
      if (hasKey(utilization, key)) {
        if (utilization[key] > 0.85) {
          color = COLORS.CHARTS.HEXBIN.FILL_DANGER;
        } else if (utilization[key] < 0.75) {
          color = COLORS.CHARTS.HEXBIN.FILL_FINE;
        } else {
          color = COLORS.CHARTS.HEXBIN.FILL_GOOD;
        }
      }
    }
    return color;
  }

  const CustomCell = (input: WaffleInput) => {
    return (
      <polygon
        stroke={getBorderColor(input.data?.utilization)}
        stroke-width={nodes.length > 100 ? '3' : '5'}
        fill={getFillColor(input.data?.utilization)}
        points={getPoints(input)}
        onMouseEnter={input.onHover}
        onMouseMove={input.onHover}
        onMouseLeave={input.onLeave}
      />
    );
  };

  const CustomToolTip = (node: WaffleTooltipData & { utilization: Utilization }) => {
    const { utilization, id } = node;
    const limits =
      selectedDisplay === 'cpu'
        ? Math.round(utilization.CPULimits * 100)
        : Math.round(utilization.MemoryLimits * 100);
    const requests =
      selectedDisplay === 'cpu'
        ? Math.round(utilization.CPURequests * 100)
        : Math.round(utilization.MemoryRequests * 100);
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexFlow: 'column wrap',
          zIndex: 1000,
          width: '8.5rem',
        }}
      >
        <div>
          <span style={{ fontSize: '1rem', display: 'flex', alignItems: 'center' }}>
            <HexagonOutlineIcon fill={getBorderColor(utilization)} />
            <strong
              style={{ paddingLeft: '0.25rem', paddingRight: '0.5rem' }}
            >{`${strings.general.Limits}:`}</strong>
            {` ${limits}%`}
          </span>
        </div>
        <div style={{ paddingTop: '0.5rem' }}>
          <span style={{ fontSize: '1rem', display: 'flex', alignItems: 'center' }}>
            <HexagonIcon fill={getFillColor(utilization)} />
            <strong
              style={{ paddingLeft: '0.25rem', paddingRight: '0.5rem' }}
            >{`${strings.general.Requests}:`}</strong>
            {` ${requests}%`}
          </span>
        </div>
        <h2
          style={{
            fontSize: '1rem',
            width: '100%',
            marginTop: '0.5rem',
            textAlign: 'center',
          }}
        >
          {id}
        </h2>
        {utilization.role && (
          <h3
            style={{
              fontSize: '1rem',
              width: '100%',
              marginTop: '0.5rem',
              textAlign: 'center',
              textTransform: strings.noTranslate.capitalize,
            }}
          >
            {utilization.role}
          </h3>
        )}
      </div>
    );
  };

  return (
    <Card style={{ marginTop: '2rem' }} data-cy="node-capacity-card">
      <Card.Body style={{ padding: '0' }}>
        <Card.Header
          style={{ borderBottom: COLORS.BORDER.TABLE_HEADER, padding: '0.5rem 0 0.5rem 1.25rem' }}
        >
          <h1 className="node-hexbin__title">{`${strings.general.Node} ${strings.navigation.Capacity}`}</h1>
          <div className="node-hexbin__dropdown">
            <div className="node-hexbin__dropdown-container">
              <SelectDropdown
                label={strings.ariaLabels.dataDropdown}
                options={dropdownOptions}
                handleChartTypeChange={(value) => handleChartTypeChange(value, setSelectedDisplay)}
              />
            </div>
          </div>
        </Card.Header>
        {loading && (
          <div className="node-hexbin__loading-spinner">
            <LoadingSpinner />
          </div>
        )}
        {!loading && !nodes.length && (
          <h2 className="node-hexbin__no-data">{strings.noNodesToDisplay}</h2>
        )}
        {!loading && nodes.length > 0 && (
          <div className="node-hexbin-container">
            <div
              style={{ height: chartSettings.chartHeight, width: '100%' }}
              aria-label={strings.ariaLabels.nodeHexbinChart}
              role="img"
            >
              <HexbinChart
                nodes={nodes}
                chartSettings={chartSettings}
                CustomCell={CustomCell}
                CustomToolTip={CustomToolTip}
              />
            </div>
            <Legend />
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default NodeHexbinChart;
