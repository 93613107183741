import React, { useEffect, useState, useMemo } from 'react';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { Button, Card, Dropdown, DropdownButton } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';
import { FixedSizeList } from 'react-window';

import CreateTicketModal from '~reactComponents/createTicketModal/CreateTicketModal.react';
import ActionItemModal from '../../ActionItemsList/modals/ActionItemModal/ActionItemModal.react';
import LoadingSpinner from '~reactComponents/LoadingSpinner/LoadingSpinner.react';
import PillBadge from '~reactComponents/PillBadge/PillBadge.react';

import HorizontalEllipsis from '~assetIcons/horizontalEllipsis.svg';
import CreateIcon from '~assetIcons/create.svg';
import TableIcon from '~assetIcons/table.svg';
import ExportIcon from '~assetIcons/export.svg';
import PencilIcon from '~assetIcons/pencil.svg';
import DeleteIcon from '~assetIcons/delete.svg';
import ShareIcon from '~assetIcons/copy-purple.svg';
import CloseIcon from '~reactComponents/Icons/Close.icon.react';

import {
  ActionItem,
  ActionItemFilters,
  HeaderData,
  List,
  ListTableProps,
  TicketModal,
} from '../../ActionItems.types.react';
import { createCSVFile } from '../../ActionItems.helpers.react';

import { sendRequest } from '~utils/request';
import logger from '~utils/logger';
import { getCurrentTimezone, safeClickHandler } from '~reactHelpers';
import { strings } from '~utils/strings';

import './ListsTable.react.scss';

dayjs.extend(timezone);

const ListsTableComponent = ({
  list,
  deleteActionItem,
  route,
  router,
  deleteList,
  editList,
  selectedCluster,
  setLists,
  name,
  isOwner,
  totalResults,
  loadActionItems,
}: ListTableProps): JSX.Element => {
  const org = route?.params?.org;
  const baseURL = `/v0/organizations/${org}`;
  const timeZone = getCurrentTimezone();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [showTicketModal, setTicketModal] = useState<TicketModal>({ show: false, list: false });
  const [selectedActionItem, setSelectedActionItem] = useState<ActionItem | undefined>(undefined);
  const [actionItemResolved, setActionItemResolved] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if ((totalResults === 1 && !list?.ActionItems) || actionItemResolved > 0) {
      loadActionItems(list);
    }
  }, [actionItemResolved, totalResults]);

  const getActionItems = async () => {
    setIsLoading(true);
    await loadActionItems(list);
    await updateComponent(list);
  };

  const updateComponent = (listWithActionItems: List, actionItems?: ActionItem[]) => {
    if (actionItems) listWithActionItems = { ...listWithActionItems, ActionItems: actionItems || [] };
    setLists((prevState) => {
      const fixed = prevState.filter((list) => list.Name !== listWithActionItems.Name);
      return [...fixed, listWithActionItems];
    });
    setIsLoading(false);
  };

  const dateInfo = useMemo(() => {
    const currentDate = timeZone ? dayjs().tz(timeZone) : dayjs();
    let differenceString = '';
    let timePeriod = '';
    const difference = currentDate.diff(timeZone ? dayjs(list.CreatedAt).tz(timeZone) : list.CreatedAt, 'day');
    if (difference === 0) {
      differenceString = strings.dateOptions.today;
    }
    if (difference === 1) {
      differenceString = '1';
      timePeriod = strings.dateOptions.dayAgo;
    }
    if (difference > 1 && difference < 32) {
      differenceString = `${difference}`;
      timePeriod = strings.dateOptions.daysAgo;
    }
    const months = difference / 30;
    if (months < 12 && months >= 1) {
      differenceString = months < 1.6 ? '1' : Math.round(months).toString();
      timePeriod = months < 1.6 ? strings.dateOptions.monthAgo : strings.dateOptions.monthsAgo;
    }
    if (months > 12) {
      const years = Math.round(months / 12);
      differenceString = years < 2 ? '1' : Math.round(years).toString();
      timePeriod = years < 2 ? strings.dateOptions.yearAgo : strings.dateOptions.yearsAgo;
    }

    return { difference: differenceString, timePeriod };
  }, []);

  const headerData = useMemo((): HeaderData | null => {
    if (Object.keys(list).length && (list?.ActionItems || list.ActionItems === null)) {
      const data = {
        fixed: 0,
        resolved: 0,
        newItems: 0,
      };
      const currentDate = timeZone ? dayjs().tz(timeZone) : dayjs();

      list?.ActionItems?.forEach((item: ActionItem) => {
        if (actionItemResolved === item.ID) {
          item.Resolution = strings.noTranslate.resolved;
        }
        const firstSeenDifference = currentDate.diff(
          timeZone ? dayjs(item.FirstSeen).tz(timeZone) : item.FirstSeen,
          'day',
        );
        if (firstSeenDifference < 8) {
          data.newItems++;
        }
        if (item.Resolution !== '') {
          data.resolved++;
        }
        if (item.Fixed) {
          data.fixed++;
        }
      });
      return data;
    }
    return null;
  }, [list]);

  const removeActionItem = (itemID: number): void => {
    list.ActionItemIndex = list?.ActionItems?.findIndex((item: ActionItem) => item.ID === itemID);
    deleteActionItem(list);
  };

  const getActionItemInfo = (id: number): void => {
    setShowModal(true);
    findActionItem(id);
  };

  const findActionItem = async (id: number): Promise<void> => {
    let response;
    try {
      response = await sendRequest('GET', `/v0/organizations/${org}/action-items/${id}`, {}, null);
    } catch (e) {
      logger.logError('error_retrieving_action_item_for_list', e);
    }
    logger.logEvent(`action-items-list:selected ${response.Category} category item`);
    setSelectedActionItem(response);
  };

  const switchToTableView = (filters: ActionItemFilters) => {
    filters[strings.noTranslate.from] = ['lists-view'];
    filters['listName'] = [list.Name];
    router().push({ path: `/orgs/${org}/action-items`, query: filters });
  };

  const exportCSVFile = async () => {
    if (org) {
      const actionItems = list.ActionItems;
      await loadActionItems(list);
      createCSVFile(list.ActionItems || [], org, '', list.Name);
    }
  };

  const copyLink = async () => {
    const searchParams = new URLSearchParams(`name=${list.Name}`);
    const link = `${location.origin}/orgs/${org}/action-items/lists?${searchParams.toString()}`;
    await navigator.clipboard.writeText(link);
    toast.success(<b>Link copied!</b>);
  };

  const closeModal = () => {
    setTicketModal({ show: false, list: false });
  };

  list.ActionItems?.sort((a, b) => {
    if (a.Resolution === '' && b.Resolution !== '') {
      return -1;
    }
    if (b.Resolution === '' && a.Resolution !== '') {
      return 1;
    }
    return 0;
  });

  const openTicketLink = safeClickHandler(() => {
    if (!list.TicketLink) {
      return;
    }
    window.open(list.TicketLink, '_blank');
  });

  const createTicketModal = async () => {
    logger.logEvent('action-items-list:create ticket clicked');
    await loadActionItems(list);
    if (
      list.ActionItems &&
      list.ActionItems.every(
        (item) =>
          item.Fixed ||
          item.Resolution === strings.willNotFixResolution ||
          item.Resolution === strings.workingAsAttendedResolution,
      )
    ) {
      toast.error(strings.general.cantCreateTicketForList);
      return;
    }
    return setTicketModal({ show: true, list: true });
  };

  return (
    <Card className="lists-table-container">
      {selectedActionItem && (
        <ActionItemModal
          route={route}
          show={showModal}
          setTicketModal={setTicketModal}
          actionItem={selectedActionItem}
          baseURL={baseURL}
          router={router}
          resolveItem={setActionItemResolved}
          setShowModal={setShowModal}
          isEditable={list.isOwner || isOwner || false}
        />
      )}
      {showTicketModal.show && (
        <CreateTicketModal
          route={route}
          router={router}
          showModal={showTicketModal.show}
          closeModal={closeModal}
          listID={showTicketModal.list ? list.ID : undefined}
          items={[selectedActionItem] as ActionItem[]}
        />
      )}
      <div className="lists-header">
        <div className="header-left">
          <h2 className="header-title">{name || list?.Name}</h2>
          {list && Object.keys(list).length > 0 && (
            <span className="table-info">
              <span>
                {strings.actionItemsList.createdBy} <strong>{list.CreatedBy || 'Insights'}</strong>,{' '}
                <strong>{dateInfo.difference}</strong> {dateInfo.timePeriod}
              </span>
            </span>
          )}
        </div>
        <div className="header-right">
          {headerData && (
            <div className="ai-fixed">
              {`${list?.ActionItems?.length || 0} ${strings.actionItemsList.total}, ${
                headerData?.fixed
              } ${strings.actionItemsList.fixed},${' '}
              ${headerData?.resolved} ${strings.actionItemsList.resolved}, ${
                headerData?.newItems
              } ${strings.actionItemsList.new}`}
            </div>
          )}
          <DropdownButton
            title={<img src={HorizontalEllipsis} alt="open list menu" />}
            className="delete-list-btn"
            aria-haspopup
          >
            <Dropdown.Item key="list-item-1" className="export-item" onClick={() => exportCSVFile()}>
              <div className="dropdown-item__content">
                <img className="export-icon" src={ExportIcon} alt="export icon" />
                <span>{strings.general.Export}</span>
              </div>
            </Dropdown.Item>
            <Dropdown.Item key="list-item-2" className="share-list" onClick={() => copyLink}>
              <div className="dropdown-item__content" onClick={copyLink}>
                <img className="share-icon" src={ShareIcon} alt="share link icon" />
                <span>{strings.general.ShareList}</span>
              </div>
            </Dropdown.Item>
            {(list?.isOwner || isOwner) && (
              <>
                <Dropdown.Item key="list-item-3" onClick={() => editList(list)} className="edit-item" aria-haspopup>
                  <div className="dropdown-item__content">
                    <img className="pencil-icon" src={PencilIcon} alt="pencil icon" />
                    <span>{strings.general.EditList}</span>
                  </div>
                </Dropdown.Item>
                {!list.TicketLink && (
                  <Dropdown.Item
                    key="list-item-4"
                    className="create-ticket"
                    aria-haspopup
                    onClick={() => {
                      return createTicketModal();
                    }}
                  >
                    <div className="dropdown-item__content">
                      <img className="create-icon" src={CreateIcon} alt="create ticket icon" />
                      <span>{strings.general.CreateTicket}</span>
                    </div>
                  </Dropdown.Item>
                )}
                {list.TicketLink && (
                  <Dropdown.Item key="list-item-4" className="create-ticket" aria-haspopup onClick={openTicketLink}>
                    <div className="dropdown-item__content">
                      <img className="create-icon" src={CreateIcon} alt="view ticket icon" />
                      <span>{strings.general.ViewTicket}</span>
                    </div>
                  </Dropdown.Item>
                )}
              </>
            )}
            {list.Type === 'query' && (
              <Dropdown.Item key="list-item-5" onClick={() => switchToTableView(list.Filters)} className="table-view">
                <div className="dropdown-item__content">
                  <img className="table-icon" src={TableIcon} alt="table icon" />
                  <span>{strings.actionItemsList.tableView}</span>
                </div>
              </Dropdown.Item>
            )}
            {(list?.isOwner || isOwner) && (
              <Dropdown.Item
                key="list-item-6"
                aria-haspopup
                onClick={() => deleteList(list.ID)}
                className="delete-item"
              >
                <div className="dropdown-item__content">
                  <img src={DeleteIcon} alt="delete icon" />
                  <span>{strings.actionItemsList.deleteList}</span>
                </div>
              </Dropdown.Item>
            )}
          </DropdownButton>
        </div>
      </div>
      {name && isLoading && (
        <div className="no-items-row tr loading">
          <div className="td default-container">
            <LoadingSpinner />
          </div>
        </div>
      )}
      {!isLoading && !headerData && list?.ActionItems !== null && !list?.ActionItems?.length && (
        <div className="no-items-row get-items-button-div">
          <Button
            variant="primary"
            onClick={getActionItems}
            className="load-action-items-btn"
            data-cy="load-list-button"
          >
            {strings.actionItemsList.loadActionItems}
          </Button>
        </div>
      )}
      {!isLoading && headerData && list?.ActionItems !== null && !list?.ActionItems?.length && (
        <div className="no-items-row get-items-button-div">{strings.actionItemsList.noActionItems}</div>
      )}
      {!isLoading && list?.ActionItems?.length && list?.ActionItems?.length > 0 && (
        <div role="table">
          <FixedSizeList
            height={350}
            itemCount={list?.ActionItems?.length || 0}
            itemSize={50}
            width={'100%'}
            itemData={list.ActionItems}
          >
            {({ data, index, style }) => {
              return (
                <div className="action-item-row" style={style} tabIndex={0} role="row">
                  <div className="action-item-info" onClick={() => getActionItemInfo(data[index].ID)} aria-haspopup>
                    <span role="cell" className="severity">
                      <PillBadge severity={data[index].Severity} />
                    </span>
                    <span
                      role="cell"
                      className={
                        data[index].Fixed || data[index].Resolution !== ''
                          ? 'action-item-title line-through'
                          : 'action-item-title'
                      }
                    >
                      {`${data[index].Title} - ${data[index].ResourceKind} ${data[index].ResourceName}`}
                    </span>
                  </div>
                  {list.Type === 'manual' && (
                    <div
                      role="cell"
                      className="delete-action-item"
                      onClick={() => removeActionItem(data[index].ID)}
                      aria-haspopup
                    >
                      <CloseIcon width="12px" height="12px" />
                    </div>
                  )}
                </div>
              );
            }}
          </FixedSizeList>
        </div>
      )}
      {!isLoading && !list?.ActionItems && headerData && (
        <div className="no-items-row tr">
          <div className="td default-container">{strings.actionItemsList.noActionItemsFound}</div>
        </div>
      )}
      <Toaster />
    </Card>
  );
};

export default ListsTableComponent;
