import React from 'react';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { Controlled as CodeMirror } from '@leifandersen/react-codemirror2';
import toast from 'react-hot-toast';

import { Card } from '@fairwindsops/ui-components';
import CopyIcon from '~reactIcons/CopyIcon.react';

import { formatData } from '~views/organization/efficiency/Efficiency.helpers.react';

import { ResourceUsageDataProps } from '../../Costs.types.react';
import { getCurrentTimezone } from '~utils/global.helpers.react';
import { strings } from '~utils/strings.js';

import './ResourceUsageData.react.scss';

dayjs.extend(timezone);

const ResourceUsageData = ({
  summaries,
  limits,
  selectedDisplay,
  dates,
}: ResourceUsageDataProps) => {
  const timeZone = getCurrentTimezone();

  const BottomData = ({ section }: { section: string }) => {
    if (Object.keys(limits).length) {
      const formattedCurrentValue =
        selectedDisplay.value === strings.noTranslate.cpuRaw
          ? limits.settings[section][selectedDisplay.value]
          : formatData(limits.settings[section][selectedDisplay.value], selectedDisplay.value);

      const formattedRecommendedValue =
        selectedDisplay.value === strings.noTranslate.cpuRaw
          ? limits.recommendations[section][selectedDisplay.value]
          : formatData(
              limits.recommendations[section][selectedDisplay.value],
              selectedDisplay.value,
            );

      const selectedDisplayValue =
        selectedDisplay.value === strings.noTranslate.cpuRaw ? 'mCPU' : 'GB';

      return (
        <div className="resource-usage-data__data">
          <div className="resource-usage-data__data-container">
            <strong>{`${strings.efficiency.Current}:`}</strong>
            <span>
              {formattedCurrentValue
                ? `${
                    Number.isInteger(formattedCurrentValue)
                      ? formattedCurrentValue
                      : formattedCurrentValue.toFixed(2)
                  } ${selectedDisplayValue}`
                : 'unset'}
            </span>
          </div>
          <div className="resource-usage-data__data-container">
            <strong>{`${strings.efficiency.Recommended}:`}</strong>
            <span>
              {formattedRecommendedValue
                ? `${
                    Number.isInteger(formattedRecommendedValue)
                      ? formattedRecommendedValue
                      : formattedRecommendedValue.toFixed(2)
                  } ${selectedDisplayValue}`
                : 'unset'}
            </span>
          </div>
          <div className="resource-usage-data__data-container">
            <strong>{`${strings.efficiency.Difference}:`}</strong>
            <span>
              {Number.isNaN(formattedRecommendedValue - formattedCurrentValue)
                ? 'N/A'
                : `${
                    Number.isInteger(formattedRecommendedValue - formattedCurrentValue)
                      ? formattedRecommendedValue - formattedCurrentValue
                      : (formattedRecommendedValue - formattedCurrentValue).toFixed(2)
                  } ${selectedDisplayValue}`}
            </span>
          </div>
        </div>
      );
    }
    return <></>;
  };

  const codeSample = (section: string) => {
    if (Object.keys(limits).length) {
      const sections = [strings.noTranslate.requests, strings.noTranslate.limits];
      const types = [strings.noTranslate.cpu, strings.noTranslate.memory];
      let sample = '';

      sections.forEach((codeSection) => {
        sample +=
          codeSection === strings.noTranslate.requests ? `${codeSection}:` : `\n${codeSection}:`;
        types.forEach((type) => {
          if (limits[section][codeSection][type]) {
            sample += `\n  ${type}: ${limits[section][codeSection][type]}`;
          }
        });
      });

      return sample;
    }
    return '';
  };

  const copyCode = async () => {
    await navigator.clipboard.writeText(codeSample('recommendations'));
    toast.success(strings.efficiency.copiedValues);
  };

  const CodeSampleDisplay = ({ section }: { section: string }) => (
    <CodeMirror
      value={codeSample(section)}
      className="costs-code-mirror"
      options={{
        tabSize: 4,
        theme: 'material',
        lineNumbers: true,
        line: true,
        readOnly: true,
      }}
    />
  );

  return (
    <>
      <Card className="resource-usage-data">
        <Card.Body className="resource-usage-data__container" padded>
          <div>
            <p className="resource-usage-data__title">
              {`${strings.navigation.Costs}: ${
                timeZone
                  ? dayjs(dates.start).tz(timeZone).format('MM/DD/YYYY')
                  : dayjs(dates.start).format('MM/DD/YYYY')
              } - ${
                timeZone
                  ? dayjs(dates.end).tz(timeZone).format('MM/DD/YYYY')
                  : dayjs(dates.end).format('MM/DD/YYYY')
              }`}
            </p>
            <div className="resource-usage-data__data-container">
              <strong>
                {selectedDisplay.value === strings.noTranslate.cpuRaw
                  ? strings.efficiency.avgCPUPerPod
                  : strings.efficiency.avgMemoryPerPod}
              </strong>
              <span>{`$${(
                summaries[0]?.costs?.actual &&
                summaries[0]?.costs?.actual[
                  selectedDisplay.value === strings.noTranslate.cpuRaw ? 'cpu' : 'memory'
                ] / summaries[0]?.resources.averagePodCount
              )?.toFixed(2)}`}</span>
            </div>
            <div className="resource-usage-data__data-container">
              <strong>{strings.efficiency.costWithRec}</strong>
              <span>{`$${(
                summaries[0]?.costs?.recommendation &&
                summaries[0]?.costs?.recommendation[
                  selectedDisplay.value === strings.noTranslate.cpuRaw ? 'cpu' : 'memory'
                ] / summaries[0]?.resources.averagePodCount
              )?.toFixed(2)}`}</span>
            </div>
          </div>
          <div>
            <p className="resource-usage-data__title">{strings.efficiency.Requests}</p>
            <BottomData section="requests" />
          </div>
          <div>
            <p className="resource-usage-data__title">{strings.efficiency.Limits}</p>
            <BottomData section="limits" />
          </div>
        </Card.Body>
      </Card>
      <div className="resource-usage-code">
        <div className="resource-usage-code__container">
          <h2 className="resource-usage-code__title">{strings.efficiency.Current}</h2>
          <CodeSampleDisplay section="settings" />
        </div>
        <div className="resource-usage-code__container">
          <div className="resource-usage-code__header">
            <h2 className="resource-usage-code__title">{strings.efficiency.Recommended}</h2>
            <div className="resource-usage-code__copy-icon" onClick={copyCode}>
              <CopyIcon />
            </div>
          </div>
          <CodeSampleDisplay section="recommendations" />
        </div>
      </div>
    </>
  );
};

export default ResourceUsageData;
