import React, { useState, useEffect, useMemo } from 'react';
import { Column, useTable, useSortBy, useFlexLayout } from 'react-table';
import Fuse from 'fuse.js';

import { Card } from '@fairwindsops/ui-components';
import SelectDropdown from '~reactComponents/SelectDropdown/SelectDropdown.react';
import SearchFilterBar from '~reactComponents/SearchFilterBar/SearchFilterBar.react';
import SortIcon from '~reactComponents/Icons/SortIcon.icon.react';
import LoadingSpinner from '~reactComponents/LoadingSpinner/LoadingSpinner.react';

import { TABLE_COLUMNS } from './NodeTable.config.react';
import { NodeDataType, FormattedDataType } from '../../../Efficiency.types.react';
import { handleChartTypeChange } from '../../../Efficiency.helpers.react';

import { dropdownOptions } from '../components.config.react';
import { SortStatus } from '~globalTypes';
import { strings } from '~utils/strings';
import { COLORS } from '~utils/styling';

import './NodeTable.react.scss';

type NodeTableProps = {
  data: NodeDataType[];
  cluster: string;
};

const NodeTable = ({ data, cluster }: NodeTableProps) => {
  const [status, setStatus] = useState<string>('idle');
  const [allData, setAllData] = useState<FormattedDataType[]>([]);
  const [selectedDisplay, setSelectedDisplay] = useState<string>('cpu');
  const [searchValue, setSearchValue] = useState<string>('');

  useEffect(() => {
    setStatus('pending');
    let formattedData: FormattedDataType[] = [];
    if (data.length) {
      formattedData = formatDataForColumns(data);
      setStatus('done');
    }
    setAllData(formattedData);
  }, [data, selectedDisplay, searchValue === '']);

  const searchInstance = useMemo(() => {
    return new Fuse(data as NodeDataType[], {
      findAllMatches: true,
      threshold: 0.0,
      ignoreLocation: true,
      keys: ['Name'],
    });
  }, [data]);

  const tableData = useMemo(() => allData, [allData, selectedDisplay]);
  const columns = useMemo<Column[]>(
    () => TABLE_COLUMNS(selectedDisplay),
    [allData, selectedDisplay],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: tableData,
      autoResetSortBy: false,
      initialState: {
        sortBy: [
          {
            id: 'role',
            desc: false,
          },
          {
            id: 'name',
            desc: false,
          },
        ],
      },
    },
    useFlexLayout,
    useSortBy,
  );

  const formatDataForColumns = (data: NodeDataType[]) => {
    return data.map((node) => {
      return {
        name: node.Name,
        available: node.Allocatable[selectedDisplay],
        requests: node.Utilization[`${selectedDisplay}RequestsFraction`] * 100,
        limits: node.Utilization[`${selectedDisplay}LimitsFraction`] * 100,
        role: node.Labels['kubernetes.io/role'],
        instanceType: node.Labels['node.kubernetes.io/instance-type'],
      };
    });
  };

  const searchNodes = (event: unknown) => {
    const typedEvent = event as Event | null;
    if (typedEvent) {
      const inputSearchValue = typedEvent.target as HTMLInputElement;
      const results = searchInstance.search(inputSearchValue.value).map((res) => res.item);

      setAllData(formatDataForColumns(results));
      setSearchValue(inputSearchValue.value);
    }
  };

  return (
    <Card style={{ marginTop: '2rem' }} data-cy="nodes-card">
      <Card.Body style={{ padding: '0' }}>
        <Card.Header
          style={{ borderBottom: COLORS.BORDER.TABLE_HEADER, padding: '0.5rem 0 0.5rem 1.25rem' }}
        >
          <h1 className="node-table-title">{`${strings.general.Nodes} ${
            selectedDisplay === 'cpu' ? strings.general.CPU : strings.general.Memory
          }`}</h1>
          <div className="node-table-dropdown">
            <SearchFilterBar searchFunction={searchNodes} className="node-table-dropdown__search">
              <SelectDropdown
                label={strings.ariaLabels.dataDropdown}
                options={dropdownOptions}
                handleChartTypeChange={(value) => handleChartTypeChange(value, setSelectedDisplay)}
              />
            </SearchFilterBar>
          </div>
        </Card.Header>
        {(status === 'idle' || status === 'pending') && (
          <div className="node-table__loading-spinner">
            <LoadingSpinner />
          </div>
        )}
        {status === 'done' && (
          <table
            {...getTableProps()}
            className="node-table"
            aria-label={`${strings.SummaryOfNodes} ${selectedDisplay} ${strings.general.for} ${cluster}`}
          >
            <thead className="node-table-header">
              {headerGroups.map((headerGroup) => (
                <tr className="header-row" {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                      <span className="node-table-header__icon">
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <SortIcon
                              sortStatus={SortStatus.Descending}
                              width="1rem"
                              height="1rem"
                            />
                          ) : (
                            <SortIcon
                              sortStatus={SortStatus.Ascending}
                              width="1rem"
                              height="1rem"
                            />
                          )
                        ) : (
                          <SortIcon sortStatus={SortStatus.None} width="1rem" height="1rem" />
                        )}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} className="node-table-body">
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </Card.Body>
    </Card>
  );
};

export default NodeTable;
