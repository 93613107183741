import Joi from 'joi';
import { StylesConfig } from 'react-select';
import { OptionType } from '~utils/global.types.react';
import { COLORS } from '~utils/styling';
import { strings } from '~utils/strings';

export const qosOptions = ['critical', 'guaranteed', 'burstable', 'limited', 'burstable_plus'];
export const customizingNumbersOptions = ['CPUPerHour', 'GBPerHour', 'DiskPerMonth', 'GBPerTransmit', 'GBPerReceived'];

export const defaultInstance = {
  CloudProvider: '',
  AmountOfMemory: 0,
  CostPerNode: 0,
  ID: 0,
  Name: '',
  NumOfCpus: 0,
  PlanType: '',
  CPUCostPerCorePerHour: 0,
  MemoryCostPerGigabytePerHour: 0,
};

export const validationSchema = Joi.object({
  instanceType: Joi.object({
    provider: Joi.string(),
    label: Joi.string().allow(''),
    value: Joi.number().integer(),
  }),
  cloudProvider: Joi.object({
    label: Joi.string(),
    value: Joi.string(),
  }),
  QoS: Joi.string(),
  costs: Joi.object({
    CPUPerHour: Joi.number().greater(0).precision(10).messages({
      'number.min': strings.efficiency.costError,
    }),
    GBPerHour: Joi.number().greater(0).precision(10).messages({
      'number.min': strings.efficiency.costError,
    }),
    DiskPerMonth: Joi.number().greater(0).precision(10).messages({
      'number.min': strings.efficiency.costError,
    }),
    GBPerTransmit: Joi.number().greater(0).precision(10).messages({
      'number.min': strings.efficiency.costError,
    }),
    GBPerReceived: Joi.number().greater(0).precision(10).messages({
      'number.min': strings.efficiency.costError,
    }),
  }),
});

export const customStyles: StylesConfig<OptionType, true> = {
  control: (provided) => ({
    ...provided,
    width: '99%',
    minHeight: '2rem',
    height: 'calc(1.5em + 0.75rem + 2px)',
    fontSize: '1rem',
    fontWeight: '400',
    lineHeight: '1.5',
    color: COLORS.CORE.DROPDOWN_FONT,
    backgroundColor: COLORS.CORE.WHITE,
    backgroundClip: 'padding-box',
    border: `${COLORS.BORDER.CONTEXT_MENU}`,
    borderRadius: '0.25rem',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: 0,
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: strings.noTranslate.costsPadding,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    padding: strings.noTranslate.smallBottomPadding,
  }),
  menu: (provided) => ({
    ...provided,
    width: '11rem',
  }),
  menuList: (provided) => ({
    ...provided,
    textTransform: strings.noTranslate.capitalize,
  }),
  multiValueRemove: (base) => ({ ...base, display: 'none' }),
};
