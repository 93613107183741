import React, { useState, useMemo, useEffect } from 'react';
import Select from 'react-select';
import { Defs } from '@nivo/core';
import dayjs, { ManipulateType } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { area, curveMonotoneX } from 'd3-shape';
import { Toaster } from 'react-hot-toast';

import SelectDropdown from '~reactComponents/SelectDropdown/SelectDropdown.react';
import LoadingSpinner from '~reactComponents/LoadingSpinner/LoadingSpinner.react';
import { Card } from '@fairwindsops/ui-components';
import ResourceUsageData from '../ResourceUsageData/ResourceUsageData.react';
import LineChart from '~reactComponents/charts/LineChart/LineChart.react';

import {
  ACTION_ITEMS,
  ACTION_ITEMS_CLUSTER,
} from '~reactComponents/NavigationReact/Navigation.config.react';

import { qosLabels } from '../../Costs.config.react';
import {
  labels,
  legendLabels,
  usageChartKeys,
  usageLineColors,
  styleById,
  dropdownOptions,
  customSelectStyles,
  settingsOptions,
} from './ResourceUsage.config.react';

import {
  ResourceUsageChartProps,
  LineChartData,
  LimitsType,
  ContainerType,
} from '../../Costs.types.react';
import { OptionType } from '~utils/global.types.react';

import { formatData } from '~views/organization/efficiency/Efficiency.helpers.react';
import { hasKey, getCurrentTimezone } from '~utils/global.helpers.react';
import { sendRequest } from '~utils/request';
import logger from '~utils/logger';
import { strings } from '~utils/strings';
import { COLORS } from '~utils/styling';

import './ResourceUsageChart.react.scss';

dayjs.extend(timezone);

const ResourceUsageChart = ({
  route,
  period,
  selectedFilters,
  org,
  timeRange,
  summaries,
  router,
}: ResourceUsageChartProps) => {
  /* eslint-disable-next-line no-console */
  const timeZone = getCurrentTimezone();
  const [data, setData] = useState([]);
  const [highestUsage, setHighestUsage] = useState<number>(0);
  const [limits, setLimits] = useState<LimitsType>({});
  const [selectedDisplay, setSelectedDisplay] = useState<OptionType>({
    value: strings.noTranslate.cpuRaw,
    label: strings.general.CPU,
  });
  const [selectedQoS, setSelectedQoS] = useState<OptionType>({
    value: '',
    label: strings.efficiency.noQoS,
  });
  const [selectedContainer, setSelectedContainer] = useState<OptionType>({
    value: '',
    label: strings.efficiency.allContainers,
  });
  const [containers, setContainers] = useState<OptionType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [settingsOption, setSettingsOption] = useState<string>('current');
  const [currentState, setCurrentState] = useState<string>('mounting');
  const [hiddenIds, setHiddenIds] = useState([]);

  const [isActionItemsLinkShown, setIsActionItemsLinkShown] = useState<boolean>(false);

  const getResourcesPerPod = async (container?: string) => {
    let response;
    try {
      response = await sendRequest(
        'GET',
        `/v0/organizations/${org}/clusters/${
          selectedFilters.clusters[0].value
        }/workloads/recommendation?kind=${selectedFilters.kinds[0].value}&namespace=${
          selectedFilters.namespaces[0].value
        }&workload=${selectedFilters.workloads[0].value}${
          container ? `&container=${container}` : ''
        }`,
        {},
        null,
      );
    } catch (e) {
      logger.logError('error_retrieving_workloads_recommendations', e);
    }
    setLimits(response);
  };

  const getResources = async (containerValue?: string) => {
    let container = containerValue || '';
    if (!container && selectedFilters?.containers?.length) {
      container = selectedFilters?.containers[0]?.value;
    }

    let podTimeseriesUrl = `/v0/organizations/${org}/resources-pod-timeseries?endDate=${
      timeRange.end
    }&startDate=${timeRange.start}&clusters=${selectedFilters.clusters[0].value}&kinds=${
      selectedFilters.kinds[0].value
    }&namespaces=${selectedFilters.namespaces[0].value}&workloads=${
      selectedFilters.workloads[0].value
    }${container ? `&containers=${container}` : ''}`;

    podTimeseriesUrl += strings.noTranslate.addNetworkAndStorageTrue;

    const requests = [
      sendRequest(
        'GET',
        `/v0/organizations/${org}/clusters/${
          selectedFilters.clusters[0].value
        }/workloads/recommendation?kind=${selectedFilters.kinds[0].value}&namespace=${
          selectedFilters.namespaces[0].value
        }&workload=${selectedFilters.workloads[0].value}${
          container ? `&container=${container}` : ''
        }`,
        {},
        null,
      ),
      sendRequest('GET', podTimeseriesUrl, {}, null),
    ];
    try {
      const [retrievedLimits, retrievedTimeseries] = await Promise.allSettled(requests);
      if (retrievedTimeseries.value.length > 1) {
        setData(retrievedTimeseries.value);
        setLimits(retrievedLimits.value);
      } else {
        setData([]);
        setLimits({});
      }
      if (retrievedLimits.value.qos) {
        setSelectedQoS({ value: retrievedLimits.value.qos, label: retrievedLimits.value.qos });
      } else {
        setSelectedQoS({ value: 'guaranteed', label: strings.qosRecs.Guaranteed });
      }

      const containersToDisplay = retrievedLimits.value?.containers?.map(
        (container: ContainerType) => ({
          value: container.container,
          label: container.container,
        }),
      );
      containersToDisplay.unshift({
        value: '',
        label: strings.efficiency.allContainers,
      });
      if (currentState === 'mounting') setContainers(containersToDisplay);
    } catch (e) {
      logger.logError('error_retrieving_workloads_recommendations', e);
    }
    if (selectedFilters?.containers?.length) {
      setSelectedContainer({ value: container, label: container });
    }

    setCurrentState('mounted');
  };

  const shouldCountActionItems = () =>
    selectedFilters?.clusters?.length ||
    selectedFilters?.workloads?.length ||
    selectedFilters?.namespaces?.length ||
    selectedFilters?.kinds?.length ||
    data?.container;

  const buildQueryStringFromJSON = (jsonObject: Record<string, any>) => {
    const params = new URLSearchParams();
    for (const key in jsonObject) {
      /* eslint-disable no-prototype-builtins */
      if (jsonObject.hasOwnProperty(key)) {
        params.append(key, jsonObject[key]);
      }
    }

    return params.toString();
  };

  const countNumberOfActionItems = async () => {
    /* eslint-disable-next-line no-console */
    const response = await sendRequest(
      'GET',
      `/v0/organizations/${org}/action-items?${buildQueryStringFromJSON({
        from: 'costs',
        ...(selectedFilters?.clusters?.length && {
          Cluster: selectedFilters.clusters[0].value,
        }),
        ...(selectedFilters?.workloads?.length && {
          ResourceName: selectedFilters.workloads[0].value,
        }),
        ...(selectedFilters?.namespaces?.length && {
          ResourceNamespace: selectedFilters.namespaces[0].value,
        }),
        ...(selectedFilters?.kinds?.length && {
          ResourceKind: selectedFilters.kinds[0].value,
        }),
        ...(selectedContainer.value && {
          ResourceContainer: selectedContainer.value,
        }),
        Fixed: false,
        Resolution: 'None',
        ReportType: 'prometheus-metrics',
        pageSize: 0,
        page: 0,
      }).toString()}`,
      { returnHeaders: true },
      null,
    );

    const headers = response.headers;

    setIsActionItemsLinkShown(headers['total-size'] && +headers['total-size'] >= 1);
  };

  useEffect(() => {
    if (shouldCountActionItems()) {
      countNumberOfActionItems();
    }
  }, [selectedFilters, data]);

  useEffect(() => {
    setCurrentState('mounting');
    setIsLoading(true);
    getResources();
  }, [selectedFilters]);

  useEffect(() => {
    if (selectedFilters && selectedQoS.value && limits?.qos !== selectedQoS.value) {
      setIsLoading(true);
      const data = {
        qos: selectedQoS.value,
        workload: selectedFilters.workloads[0].value,
        kind: selectedFilters.kinds[0].value,
        namespace: selectedFilters.namespaces[0].value,
      };
      const changeQoS = async () => {
        data.container = selectedContainer?.value ? selectedContainer?.value : '';
        try {
          await sendRequest(
            'PATCH',
            `/v0/organizations/${org}/clusters/${selectedFilters.clusters[0].value}/workloads`,
            { data, headers: { 'content-type': 'application/json' } },
            null,
          );
          getResourcesPerPod(selectedContainer?.value ? selectedContainer?.value : '');
        } catch (e) {
          logger.logError('error_changing_qos_usage_chart', e);
        }
      };
      changeQoS().then(() =>
        getResourcesPerPod(selectedContainer.value ? selectedContainer.value : ''),
      );
    }
  }, [selectedQoS, settingsOption, setSettingsOption]);

  const setRequestLimitsOption = (option: OptionType) => {
    setSettingsOption(option.value);
    getResources();
  };

  const setContainer = (container: OptionType) => {
    setIsLoading(true);
    setSelectedContainer(container);
    getResources(container.value);
  };
  const formattedData = useMemo(() => {
    const finalData: LineChartData[] | [] = [];
    let highestAmount = 0;
    if (data && data?.length > 0 && limits && Object.keys(limits)?.length) {
      [...data].forEach((dataPoint) => {
        usageChartKeys.forEach((key) => {
          if (hiddenIds.includes(key)) return;
          const foundMatch = finalData.find((datum) => datum.id === key);
          let yValue = 0;
          if (!key.includes(strings.noTranslate.settings)) {
            if (
              hasKey(dataPoint.resources.recommendation, key) &&
              hasKey(dataPoint.resources.recommendation[key], selectedDisplay.value)
            ) {
              yValue = limits.recommendations[key][selectedDisplay.value];
            } else if (
              hasKey(dataPoint.resources, key) &&
              hasKey(dataPoint.resources[key], selectedDisplay.value)
            ) {
              yValue = dataPoint.resources[key][selectedDisplay.value];
            }
          } else if (key.includes(strings.noTranslate.settings)) {
            const splitKey = key.split('-');
            if (
              settingsOption === strings.noTranslate.historical &&
              dataPoint.resources.settings &&
              dataPoint.resources.settings[splitKey[1]]
            ) {
              yValue = dataPoint.resources.settings[splitKey[1]][selectedDisplay.value];
            } else {
              yValue = limits.settings[splitKey[1]][selectedDisplay.value] || 0;
            }
          }

          const formattedYValue =
            selectedDisplay.value === strings.noTranslate.cpuRaw
              ? yValue
              : formatData(yValue, selectedDisplay.value);

          if (foundMatch) {
            foundMatch.data.push({
              x: dayjs(dataPoint.time)
                .subtract(dayjs().utcOffset(), strings.noTranslate.minutes as ManipulateType)
                .toDate(),
              y: formattedYValue,
            });
          } else {
            const lineData = {
              id: key,
              label: labels[key],
              color: hasKey(usageLineColors, key) ? usageLineColors[key] : usageLineColors.limits,
              data: [
                {
                  x: dayjs(dataPoint.time)
                    .subtract(dayjs().utcOffset(), strings.noTranslate.minutes as ManipulateType)
                    .toDate(),
                  y: formattedYValue,
                },
              ],
            };
            finalData.push(lineData);
          }
          if (formattedYValue > highestAmount) highestAmount = formattedYValue;
        });
      });
    }
    setHighestUsage(highestAmount || 0);
    setIsLoading(false);
    return finalData;
  }, [data, limits, route.fullPath, selectedDisplay, hiddenIds]);

  const AreaLayer1 = ({
    series,
    xScale,
    yScale,
    innerHeight,
  }: {
    series: LineChartData[];
    xScale: (x: number) => number;
    yScale: (y: number) => number;
    innerHeight: number;
  }) => {
    const limits = series.filter((serie) => serie.id === 'limits') || [];
    const requests = series.filter((serie) => serie.id === 'requests') || [];
    const areaGenerator = area()
      .x((d: any) => xScale(d.data.x))
      .y0((d: any) => {
        let bottomPoint;
        if (requests[0]) {
          requests[0].data.forEach((dataPoint: any) => {
            const updatedDatapoint = timeZone
              ? dayjs(dataPoint.data.x).tz(timeZone).valueOf()
              : dataPoint.data.x.getTime();
            const updatedD = timeZone ? dayjs(d.data.x).tz(timeZone).valueOf() : d.data.x.getTime();
            if (updatedDatapoint === updatedD) bottomPoint = dataPoint.data.y;
          });
        }
        return Math.min(
          innerHeight,
          yScale(d.data.y === bottomPoint || !bottomPoint ? d.data.y : bottomPoint),
        );
      })
      .y1((d: any) => yScale(d.data.y))
      .curve(curveMonotoneX);

    return (
      <>
        <Defs
          defs={[
            {
              id: 'recommended-data',
              type: 'linearGradient',
              colors: [
                { offset: 0, color: COLORS.CHARTS.USAGE_LINE.RECOMMENDED_FILL, opacity: 0.1 },
                { offset: 100, color: COLORS.CHARTS.USAGE_LINE.RECOMMENDED_FILL, opacity: 0.1 },
              ],
            },
          ]}
        />
        <path
          d={areaGenerator(limits[0]?.data || {})}
          fill="url(#recommended-data)"
          fillOpacity={0.6}
        />
      </>
    );
  };

  const AreaLayer2 = ({
    series,
    xScale,
    yScale,
    innerHeight,
  }: {
    series: LineChartData[];
    xScale: (x: number) => number;
    yScale: (y: number) => number;
    innerHeight: number;
  }) => {
    const limits = series.filter((serie) => serie.id === 'settings-limits') || [];
    const requests = series.filter((serie) => serie.id === 'settings-requests') || [];
    const areaGenerator = area()
      .x((d: any) => xScale(d.data.x))
      .y0((d: any) => {
        let bottomPoint;
        if (requests) {
          requests[0].data.forEach((dataPoint: any) => {
            const updatedDatapoint = timeZone
              ? dayjs(dataPoint.data.x).tz(timeZone).valueOf()
              : dataPoint.data.x.getTime();
            const updatedD = timeZone ? dayjs(d.data.x).tz(timeZone).valueOf() : d.data.x.getTime();
            if (updatedDatapoint === updatedD) bottomPoint = dataPoint.data.y;
          });
        }
        return Math.min(
          innerHeight,
          yScale(d.data.y === bottomPoint || !bottomPoint ? d.data.y : bottomPoint),
        );
      })
      .y1((d: any) => yScale(d.data.y))
      .curve(curveMonotoneX);

    return (
      <>
        <Defs
          defs={[
            {
              id: 'actual-data',
              type: 'linearGradient',
              colors: [
                { offset: 0, color: COLORS.CHARTS.USAGE_LINE.ACTUAL_FILL, opacity: 0.3 },
                {
                  offset: 100,
                  color: COLORS.CHARTS.USAGE_LINE.ACTUAL_FILL,
                  opacity: 0.3,
                },
              ],
            },
          ]}
        />
        <path d={areaGenerator(limits[0]?.data || {})} fill="url(#actual-data)" fillOpacity={0.6} />
      </>
    );
  };

  const DashedLine = ({
    series,
    lineGenerator,
    xScale,
    yScale,
  }: {
    series: LineChartData[];
    lineGenerator: (data: { x: number; y: number }[]) => any;
    xScale: (x: number) => number;
    yScale: (y: number) => number;
  }) => {
    return series.map(({ id, data, color }) => (
      <path
        key={id}
        d={lineGenerator(
          data.map((d) => ({
            x: xScale(timeZone ? dayjs(d.data.x).tz(timeZone) : dayjs(d.data.x)),
            y: yScale(d.data.y),
          })),
        )}
        fill="none"
        stroke={color}
        style={hasKey(styleById, id) ? styleById[id] : styleById.default}
      />
    ));
  };

  const tooltip = (slice: any) => {
    const avgUsage = slice.points.filter(
      (point: any) => point.serieId === strings.noTranslate.avgUsage,
    )[0];

    const duplicatedData: any = {};

    const originalArray = slice.points.filter((point: any) => {
      if (duplicatedData[point.data.x] && duplicatedData[point.data.x] === point.serieId) {
        return false;
      }

      duplicatedData[point.data.x] = point.serieId;

      return point.serieId !== strings.noTranslate.avgUsage;
    });

    if (!hiddenIds.includes(strings.noTranslate.avgUsage)) originalArray.splice(5, 0, avgUsage);
    return (
      <div className="resource-usage__tooltip">
        {originalArray.map((point: any) => {
          return (
            <div className="resource-usage__tooltip-container">
              <div className="resource-usage__row-container">
                <div
                  className="resource-usage__square"
                  style={{
                    backgroundColor: point.serieColor,
                  }}
                ></div>
                <strong>{labels[point.serieId]}:</strong>
              </div>
              {selectedDisplay?.value === 'memoryRaw'
                ? ` ${Number(point.data.y).toFixed(2)} GB`
                : ` ${Number(point.data.y).toFixed(0)} mCPU`}
            </div>
          );
        })}
      </div>
    );
  };

  const lineChartProps = {
    data: formattedData,
    margin: { top: 0, right: 30, bottom: 85, left: 80 },
    xScale: {
      type: 'time',
      format: period === 'daily' ? '%Y-%m-%d' : '%Y-%m-%d %H',
      useUTC: false,
      precision: period === 'daily' ? 'day' : 'hour',
    },
    xFormat: period === 'daily' ? 'time:%Y-%m-%d' : 'time:%H',
    yScale: {
      type: 'linear',
      min: 0,
      max: highestUsage * 1.15,
      stacked: false,
      reverse: false,
    },
    colors: ({ color }: { color: string }) => color,
    legends: [
      {
        data: Object.keys(legendLabels).map((item) => {
          return {
            color: hiddenIds.includes(item)
              ? 'rgba(1, 1, 1, .1)'
              : hasKey(usageLineColors, item)
                ? usageLineColors[item]
                : 'rgba(1, 1, 1, .1)',
            id: item,
            label: hasKey(legendLabels, item) ? legendLabels[item] : '',
          };
        }),
        onClick: (datum: any) => {
          setHiddenIds((state) =>
            state.includes(String(datum.id))
              ? state.filter((item) => item !== datum.id)
              : [...state, String(datum.id)],
          );
        },
        anchor: 'bottom-left',
        translateY: 80,
        direction: 'row',
        itemHeight: 40,
        itemWidth: 150,
      },
    ],
    axisBottom: {
      format: (value: Date) => {
        const formatter = timeZone ? dayjs(value).tz(timeZone) : dayjs(value);
        return period === 'daily' ? formatter.format('MM/D') : formatter.format('MM/DD: hA');
      },
      tickValues: period === 'daily' ? 'every 1 days' : strings.noTranslate.every4Hours,
      tickRotation: -45,
    },
    axisLeft: {
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legendOffset: -45,
      format: (value: number) => {
        if (Number.isInteger(value) || highestUsage < 0.55 || value > highestUsage) {
          return `${value} ${selectedDisplay?.label === 'Memory' ? 'GB' : 'mCPU'}`;
        }
        return '';
      },
    },
    layers: [
      'grid',
      'markers',
      'areas',
      DashedLine,
      AreaLayer1,
      AreaLayer2,
      'slices',
      'axes',
      'points',
      'legends',
      'mesh',
      'crosshair',
    ],
    sliceTooltip: tooltip,
  };

  return (
    <>
      <Card className="resource-usage">
        <Card.Header className="resource-usage__header">
          <div className="resource-usage__header-container">
            <h1 className="resource-usage__chart-title">{strings.efficiency.resourcesPerPod}</h1>
            {isActionItemsLinkShown ? (
              <a
                className="resource-usage__chart-action-items-link"
                href={
                  router().resolve({
                    name: ACTION_ITEMS,
                    params: { org },
                    query: {
                      from: 'costs',
                      ...(selectedFilters?.clusters?.length && {
                        Cluster: selectedFilters.clusters[0].value,
                      }),
                      ...(selectedFilters?.workloads?.length && {
                        ResourceName: selectedFilters.workloads[0].value,
                      }),
                      ...(selectedFilters?.namespaces?.length && {
                        ResourceNamespace: selectedFilters.namespaces[0].value,
                      }),
                      ...(selectedFilters?.kinds?.length && {
                        ResourceKind: selectedFilters.kinds[0].value,
                      }),
                      ...(selectedContainer &&
                        selectedContainer.value !== '' && {
                          ResourceContainer: selectedContainer.value,
                        }),
                      Fixed: false,
                      Resolution: 'None',
                      ReportType: 'prometheus-metrics',
                    },
                  }).href
                }
                target={strings.noTranslate.self}
              >
                See Action Items
              </a>
            ) : null}
          </div>
          <div className="resource-usage__dropdown-container">
            <div className="resource-usage__qos-dropdown">
              <Select
                aria-label={strings.ariaLabels.qosDropdown}
                className="select-dropdown-component"
                value={selectedQoS}
                options={qosLabels}
                isSearchable={false}
                onChange={(value: unknown) => setSelectedQoS(value)}
                name="chart-dropdown"
                styles={customSelectStyles}
              />
            </div>
            {containers?.length > 2 && (
              <div className="resource-usage__containers">
                <SelectDropdown
                  label={strings.ariaLabels.containerDropdown}
                  options={containers}
                  handleChartTypeChange={(value: unknown) => setContainer(value)}
                  defaultValue={selectedContainer}
                />
              </div>
            )}
            <div className="resource-usage__settings-option-dropdown">
              <SelectDropdown
                label={strings.ariaLabels.settingsOption}
                options={settingsOptions}
                handleChartTypeChange={(value: unknown) => setRequestLimitsOption(value)}
              />
              <div className="resource-usage__display-dropdown">
                <SelectDropdown
                  label={strings.ariaLabels.dataDropdown}
                  options={dropdownOptions}
                  handleChartTypeChange={(value: unknown) => setSelectedDisplay(value)}
                />
              </div>
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <div
            aria-label={strings.ariaLabels.resourcesPerPodChart}
            role="img"
            className="resource-usage__chart"
          >
            {isLoading && <LoadingSpinner containerClassNames="resource-usage__spinner" />}
            {!isLoading && formattedData?.length > 2 && <LineChart {...lineChartProps} />}
            {!isLoading && (!formattedData?.length || formattedData.length < 2) && (
              <div className="costs-over-time-chart__no-data">
                {strings.general.noDataToDisplay}
              </div>
            )}
          </div>
        </Card.Body>
      </Card>
      <ResourceUsageData
        summaries={summaries}
        limits={limits}
        selectedDisplay={selectedDisplay}
        dates={timeRange}
      />
      <Toaster />
    </>
  );
};

export default ResourceUsageChart;
