import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Card } from 'react-bootstrap';
import {
  Column,
  FilterTypes,
  TableInstance,
  useAsyncDebounce,
  useColumnOrder,
  useFilters,
  useFlexLayout,
  usePagination,
  useResizeColumns,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import toast, { Toaster } from 'react-hot-toast';
import { useContextMenu } from 'react-contexify';

import { Breadcrumbs, LayoutReact } from '@fairwindsops/ui-components';
import ContextMenu from '../ContextMenu/ContextMenu.react';
import ConfirmationDialog from '~reactComponents/ConfirmationDialog/ConfirmationDialog.react';
import CreateTicketModal from '~reactComponents/createTicketModal/CreateTicketModal.react';
import DescriptionPanel from '~reactComponents/DescriptionPanel/DescriptionPanel.react';
import LastSyncedDate from '~reactComponents/LastSyncedDate/LastSyncedDate.react';
import Pagination from '~reactComponents/pagination/pagination.react';
import Table from '~reactComponents/Table/Table.react';
import SelectColumnFilter from '../SelectColumnFilter/SelectColumnFilter.react';
import TableCardHeader from '../TableCardHeader/TableCardHeader.react';
import ImpactedWorkloadsTable from '../../components/ImpactedWorkloadsTable/ImpactedWorkloadsTable.react';
import ImpactedRepositories from '../../components/ImpactedRepositoriesTable/ImpactedRepositoriesTable.react';
import Information from '../../components/Information/Information.react';
import RiskReduction from '../../components/RiskReduction/RiskReduction.react';
import HorizontalEllipsis from '~assetIcons/horizontalEllipsis.svg';

import { COLUMNS_ORDER, CONTENT, PAGE_SIZE, TABLE_COLUMNS, TOP_ROW } from './ImageDetailItemsTable.config.react';
import {
  ORG_DASHBOARD,
  REACT_VULNERABILITIES_IMAGE_DETAIL,
} from '~reactComponents/NavigationReact/Navigation.config.react';

import { DescriptionPanelProps } from '~reactComponents/DescriptionPanel/DescriptionPanel.types.react';
import { Labels, ImageDetailItem, Member, AffectedImage } from './ImageDetailItemsTable.types.react';
import {
  isSingleSelectionAction,
  MultiSelectedAction,
  SingleSelectedAction,
} from '../../../ReactVulnerabilities.types.react';
import { FiltersMapType, CommonLabels, ImageSummary, IRoute, IRouter, Organization } from '~globalTypes';

import { handleAssigneeSelection, handleResolutionSelection } from './ImageDetailItemsTable.helpers.react';
import { exportActionItems, exportCSV, PAGES as EXPORT_PAGES } from '../../../ReactVulnerabilities.helpers.react';
import {
  convertFiltersToURL,
  getCurrentTimezone,
  handlePageChange,
  PAGES,
  VULN_ITEMS_TABLE_IMAGE_DETAIL_CONTEXT_MENU_ID,
} from '~reactHelpers';

import { sendRequest } from '~utils/request';
import logger from '~logger';
import { strings } from '~utils/strings';

import './ImageDetailItemsTable.react.scss';
import MultipleConfirmationDialog from '~reactComponents/MultipleConfirmationDialog/MultipleConfirmationDialog.react';
import { VulnerabilityTicketMode } from '~reactComponents/createTicketModal/CreateTicketModal.types.react';
import { vexillaShould } from '~utils/feature-flags';

dayjs.extend(relativeTime);

type ImageDetailItemsTableProps = {
  baseURL: string;
  organization: Organization;
  route: IRoute;
  savedSelectionsKey?: string;
  sha: string;
  router: () => IRouter;
};

const ImageDetailItemsTable = ({
  baseURL,
  organization,
  route,
  savedSelectionsKey,
  sha,
  router,
}: ImageDetailItemsTableProps) => {
  const [imageDetailItems, setImageDetailItems] = useState<ImageDetailItem[]>([]);
  const [selectedImageDetailItem, setSelectedImageDetailItem] = useState<ImageDetailItem | null>(null);
  const imageDetailItemFilters = useRef<FiltersMapType>({});
  const initialFilters = useRef<any[]>([]);
  const [totalRowCount, setTotalRowCount] = useState<number>(0);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [globalSearch, setGlobalSearch] = useState<string | null>(null);
  const [imageSummary, setImageSummary] = useState<ImageSummary>();
  const [isImageSummaryLoaded, setIsImageSummaryLoaded] = useState<boolean>(false);
  const [members, setMembers] = useState<Member[]>([]);
  const [isCreateTicketModalShown, setIsCreateTicketModalShown] = useState<boolean>(false);
  const [descriptionItem, setDescriptionItem] = useState<DescriptionPanelProps>({} as DescriptionPanelProps);
  const [isConfirmModalShown, setIsConfirmModalShown] = useState<boolean>(false);

  const skipTableResetRef = useRef<boolean>();
  const sortBy = useRef<{ orderBy: string; desc: boolean }>({ orderBy: '', desc: false });
  const isMounted = useRef<boolean>(false);
  // store the number of total items. It will be used to export all/filtered items.
  const totalExportItems = useRef<number>(0);
  const totalExportFilteredItems = useRef<number>(0);
  const isFirstLoaded = useRef<boolean>(true);
  // store the current action (create tickets, update assignee, or update resolution)
  const selectedAction = useRef<SingleSelectedAction | MultiSelectedAction | null>();
  // store the selected resolution and selected email
  const selectedResolution = useRef<string>('');
  const selectedEmail = useRef<string>('');
  // number of modified action items;
  const nModifiedActionItems = useRef<string>('');
  const vulnerabilityTicketMode = useRef<VulnerabilityTicketMode>('per_action_item');

  const { show: showContextMenu } = useContextMenu({
    id: VULN_ITEMS_TABLE_IMAGE_DETAIL_CONTEXT_MENU_ID,
  });

  const imageTitle = useMemo(() => {
    if (imageSummary && imageSummary.name) {
      return imageSummary.name;
    }
  }, [imageSummary?.name]);

  useEffect(() => {
    isMounted.current = true;
    initQueries();
    loadImageSummary();
    getMembers();
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (selectedImageDetailItem) {
      getAffectedImages();
    } else {
      setDescriptionItem({} as DescriptionPanelProps);
    }
  }, [selectedImageDetailItem]);

  useEffect(() => {
    fetchImageDetailItemsDebounced();
  }, [globalSearch]);

  useEffect(() => {
    // After the table has updated, always remove the flag
    skipTableResetRef.current = false;
  });

  // Restores user selection for hidden/ordered columns
  const tableColumnsStorage: Record<string, string[]> = useMemo(
    () => (savedSelectionsKey ? JSON.parse(localStorage.getItem(savedSelectionsKey) || '{}') : {}),
    [],
  );

  const tableHiddenColumns = useMemo(() => {
    return tableColumnsStorage?.hiddenColumns || [];
  }, []);

  const data = React.useMemo(() => {
    return imageDetailItems;
  }, [imageDetailItems]);

  const ColumnFiltering = (tableInstance: TableInstance) => (
    <SelectColumnFilter tableInstance={tableInstance} filters={imageDetailItemFilters.current} setLoaded={setLoaded} />
  );

  const columns = React.useMemo<Column<ImageDetailItem>[]>(
    () => TABLE_COLUMNS(ColumnFiltering),
    [imageDetailItems, imageDetailItemFilters.current],
  );

  const filterTypes = React.useMemo<FilterTypes<ImageDetailItem>>(
    () => ({
      text: (rows, ids, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[ids[0]];
          return rowValue !== undefined
            ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    [],
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      filterTypes,
      initialState: {
        pageSize: PAGE_SIZE,
        filters: initialFilters.current,
        columnOrder: tableColumnsStorage?.columnOrder || COLUMNS_ORDER,
        hiddenColumns: tableHiddenColumns,
      },
      manualFilters: true,
      manualPagination: true,
      autoResetHiddenColumns: false,
      manualSortBy: true,
      autoResetFilters: !skipTableResetRef.current,
      autoResetSortBy: !skipTableResetRef.current,
      autoResetPage: !skipTableResetRef.current,
      autoResetExpanded: !skipTableResetRef.current,
      autoResetGroupBy: !skipTableResetRef.current,
      autoResetSelectedRows: !skipTableResetRef.current,
      autoResetRowState: !skipTableResetRef.current,
    },
    useFilters,
    useSortBy,
    usePagination,
    useResizeColumns,
    useRowSelect,
    useFlexLayout,
    useColumnOrder,
  );

  useEffect(() => {
    if (savedSelectionsKey) {
      localStorage.setItem(
        savedSelectionsKey,
        JSON.stringify({
          hiddenColumns: tableInstance.state.hiddenColumns,
          columnOrder: tableInstance.state.columnOrder,
        }),
      );
    }
  }, [tableInstance.state.hiddenColumns, tableInstance.state.columnOrder]);

  useEffect(() => {
    if (initialFilters?.current.length > 0 && loaded) {
      tableInstance.setAllFilters(initialFilters.current);
    }
  }, [initialFilters.current, loaded]);

  useEffect(() => {
    let currentSha = sha;
    if (route?.query?.sha) {
      currentSha = route.query.sha;
    }
    const fetchData = async () => {
      try {
        const filters = await sendRequest(
          'GET',
          `${baseURL}/images/${currentSha}/vulnerabilities-filters`,
          { showErrorAlert: false },
          null,
        );
        imageDetailItemFilters.current = {
          ...imageDetailItemFilters.current,
          ...transformFilters(filters),
        };
      } catch (e) {
        console.error('ERROR: ', e);
        logger.logError('error_loading_image_detail_items_page: ', e);
        toast.error('Error loading page. Please try again!');
      }
    };
    fetchData();
  }, [organization]);

  const hasTicket = imageSummary?.ticketLink === undefined ? false : true;
  const handleViewTicket = hasTicket ? () => window.open(imageSummary?.ticketLink, '_blank') : undefined;

  const transformFilters = (filters: FiltersMapType) => {
    if (!filters) return;
    for (const key in filters) {
      if (key === Labels.severityLabels) {
        filters[key] = (filters[key] as string[])?.map((filterValue) => filterValue.toLowerCase());
      }
    }
    return filters;
  };

  const initQueries = () => {
    const navigation = window.performance.getEntriesByType('navigation') as any;
    if ((navigation[0].type === 'reload' || navigation[0].type === 'navigate') && window.location.search) {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set('redirect', 'true');
      if (route?.path?.includes(strings.noTranslate.allImages)) {
        searchParams.set(strings.noTranslate.resolved, 'false');
        fixURLFromLinks(searchParams);
      } else {
        router()
          .replace({ path: `${route.path}?${searchParams.toString()}` })
          .catch(() => {});
      }
    }
  };

  const fixURLFromLinks = (searchParams: URLSearchParams, path?: string) => {
    const currentPath = path ? path : `/orgs/${organization.Name}/vulnerabilities/image/${searchParams.get('sha')}`;
    searchParams.delete('sha');
    router().replace({
      path: `${currentPath}?${searchParams.toString()}`,
    });
  };

  const loadImageSummary = async () => {
    if (!route?.params?.selectedImage) {
      return;
    }
    try {
      setIsImageSummaryLoaded(false);
      const tag = route?.query?.tag ? route.query.tag : await getTag();
      if (!tag) {
        return;
      }
      const searchParams = new URLSearchParams(window.location.search);
      const rawImageId = searchParams.get(strings.vulnerabilities.imageId);
      let imageIdQuery = '';
      if (rawImageId) {
        imageIdQuery = `&imageId=${encodeURIComponent(rawImageId)}`;
      }

      const response = await sendRequest(
        'GET',
        `${baseURL}/images/${
          route.params.selectedImage === strings.noTranslate.allImages ? route?.query?.sha : route.params.selectedImage
        }/${tag}/summary?resolved=${route?.query?.resolved ? route?.query?.resolved : 'false'}${imageIdQuery}`,
        {},
        null,
      );
      if (isMounted.current) {
        setImageSummary(response);
        setIsImageSummaryLoaded(true);
      }
    } catch (e) {
      logger.logError('error_retrieving_image_summary: ', e);
      toast.error('Could not retrieve image_summary');
    }
  };

  const getTag = async () => {
    if (!route?.params?.selectedImage) {
      return null;
    }
    try {
      const searchParams = new URLSearchParams(window.location.search);
      const rawImageId = searchParams.get(strings.vulnerabilities.imageId);
      let imageIdQuery = '';
      if (rawImageId) {
        imageIdQuery = `&imageId=${encodeURIComponent(rawImageId)}`;
      }

      const response = await sendRequest(
        'GET',
        `${baseURL}/images/${
          route.params.selectedImage === strings.noTranslate.allImages ? route?.query?.sha : route.params.selectedImage
        }/summary?resolved=${route?.query?.resolved || 'false'}${imageIdQuery}`,
        {},
        null,
      );
      return response?.tag ? response.tag : null;
    } catch (e) {
      logger.logError('error_retrieving_image_tag: ', e);
    }
  };

  const getMembers = async (): Promise<void> => {
    try {
      const retrievedMembers = await sendRequest('GET', `${baseURL}/memberships`, { showErrorAlert: false }, null);
      setMembers(retrievedMembers);
    } catch (e) {
      logger.logError('error_get_memberships', e);
    }
  };

  const getAffectedImages = async () => {
    if (!selectedImageDetailItem) return;
    let response;
    try {
      response = await sendRequest(
        'GET',
        `${baseURL}/vulnerabilities/${selectedImageDetailItem.code}/images`,
        {},
        null,
      );
    } catch (e) {
      logger.logError('error_retrieving_vuln_affected_images: ', e);
      toast.error('Could not retrieve vuln affected images');
    }
    buildDescriptionItem(response);
  };

  const buildDescriptionItem = (affectedImages: AffectedImage[]) => {
    const severityLabel = selectedImageDetailItem?.severityLabel;
    setDescriptionItem({
      selectedItem: {
        metadata: TOP_ROW(selectedImageDetailItem),
        content: CONTENT(selectedImageDetailItem, affectedImages, organization.Name, router),
        created: null,
        updated: null,
        dateReported: selectedImageDetailItem!.createdAt,
        title: selectedImageDetailItem!.title ? selectedImageDetailItem!.title : selectedImageDetailItem!.code,
        severity: severityLabel
          ? `${severityLabel.charAt(0).toUpperCase()}${severityLabel.slice(1).toLowerCase()}`
          : null,
        category: null,
      },
      setSelectedItem: setSelectedImageDetailItem,
      page: '',
      openContextMenu: null,
    });
  };

  const hydrateColumnFilters = (searchParams: URLSearchParams) => {
    const columnFilters: any[] = [];
    for (const item of searchParams.entries()) {
      if (tableInstance.allColumns.some((col) => col.id === item[0])) {
        const filterIdx = columnFilters.findIndex((filter) => filter.id === item[0]);
        if (filterIdx >= 0) {
          columnFilters[filterIdx].value.push({
            value: item[1],
            label: item[1],
          });
        } else {
          columnFilters.push({
            id: item[0],
            value: [
              {
                value: item[1],
                label: item[1],
              },
            ],
          });
        }
      } else {
        hydrateSecondaryFilters(item);
      }
    }
    initialFilters.current = columnFilters;
  };

  const hydrateSecondaryFilters = (item: [string, string]) => {
    switch (item[0]) {
      case 'search':
        setGlobalSearch(item[1]);
        break;
      case 'pageSize':
        tableInstance.state.pageSize = Number(item[1]) || PAGE_SIZE;
        break;
      case 'page': {
        tableInstance.state.pageIndex = Number(item[1]) || 0;
        tableInstance.gotoPage(Number(item[1]) || 0);
        break;
      }
      case 'orderBy': {
        sortBy.current = {
          ...sortBy.current,
          orderBy: item[0] === 'orderBy' ? item[1] : '',
        };
        break;
      }
      case 'desc': {
        sortBy.current = {
          ...sortBy.current,
          desc: item[1] === 'true',
        };
        if (sortBy.current.orderBy) {
          tableInstance.setSortBy([
            {
              id: sortBy.current.orderBy,
              desc: sortBy.current.desc,
            },
          ]);
        }
        break;
      }
      default:
        return;
    }
  };

  const fetchImageDetailItems = useCallback(async () => {
    const { pageIndex, pageSize, sortBy, filters } = tableInstance.state;
    // When data gets updated with this function, set a flag
    // to disable all of the auto resetting
    skipTableResetRef.current = true;

    let searchParams: URLSearchParams;

    if (!window.location.search.includes('redirect')) {
      searchParams = new URLSearchParams();
      searchParams.set('page', pageIndex.toString());
      searchParams.set('desc', 'true');
    } else {
      searchParams = new URLSearchParams(window.location.search);
      searchParams.delete('redirect');
      searchParams.set('page', searchParams.get('page') ? (searchParams.get('page') as string) : pageIndex.toString());
    }

    if (!searchParams.has('pageSize')) {
      searchParams.set('pageSize', pageSize.toString());
    }

    if (!loaded && isMounted.current) {
      hydrateColumnFilters(searchParams);
    }

    if (globalSearch) {
      searchParams.set('search', globalSearch);
    } else {
      searchParams.delete('search');
    }

    searchParams = convertFiltersToURL(filters, searchParams);

    if (sortBy[0]) {
      searchParams.set('orderBy', `${sortBy[0].id === Labels.HasFixedVersion ? Labels.Fixed : sortBy[0].id}`);
      searchParams.set('desc', `${sortBy[0].desc}`);
    } else {
      searchParams.delete('orderBy');
      searchParams.delete('desc');
    }

    if (globalSearch) {
      searchParams.set('search', globalSearch);
    }

    searchParams.set(strings.noTranslate.resolved, route?.query?.resolved || 'false');

    try {
      const currentSha = sha === strings.noTranslate.allImages ? route?.query?.sha : sha;

      const response = await sendRequest(
        'GET',
        `${baseURL}/images/${currentSha}/vulnerabilities?${searchParams.toString()}`,
        { returnHeaders: true },
        null,
      );
      const data = response.data;
      if (isMounted.current) {
        setImageDetailItems(data.vulnerabilities);
        setTotalRowCount(data.total);
        if (isFirstLoaded.current) {
          totalExportItems.current = data.total;
          isFirstLoaded.current = false;
        }
        totalExportFilteredItems.current = data.total;
        tableInstance.pageCount = data.total;
        setLoaded(true);
        const routeName = router().currentRoute?.name;

        if (routeName && routeName.includes(REACT_VULNERABILITIES_IMAGE_DETAIL)) {
          if (route?.path?.includes(strings.noTranslate.allImages)) {
            const splitPath = route.fullPath?.split('sha=')[1].split('&')[0];
            searchParams.set(strings.noTranslate.resolved, 'false');
            fixURLFromLinks(searchParams, splitPath);
          } else {
            router()
              .replace({ path: `${route.path}?${searchParams.toString()}` })
              .catch(() => {});
          }
        }
      }
    } catch (e) {
      console.error(e);
      logger.logError('error_fetching_action_items: ', e);
      toast.error('Error fetching Action Items');
    }
  }, [loaded, globalSearch]);

  const fetchImageDetailItemsDebounced = useAsyncDebounce(fetchImageDetailItems, 250);

  const totalPage = useMemo(
    () => Math.ceil(totalRowCount / tableInstance.state.pageSize),
    [totalRowCount, tableInstance.state.pageSize],
  );

  const handlePaginationChange = (page: number): void => {
    if (page < totalPage && tableInstance.state.pageIndex !== page) {
      tableInstance.gotoPage(page);
      setLoaded(false);
    }
  };

  const showCreateTicketModal = async () => {
    if (route?.query?.resolved === 'true') {
      toast.error(strings.general.cantCreateTicketForResolvedVulnerability);
      return;
    }
    nModifiedActionItems.current = await getCreateTicketNumberOfModifiedAI('per_action_item');
    const perActionItemAction = () => {
      setIsConfirmModalShown(false);
      setIsCreateTicketModalShown(true);
      vulnerabilityTicketMode.current = 'per_action_item';
    };
    if (!vexillaShould(strings.featureFlags.imageVulnsTicketPerImage)) {
      selectedAction.current = {
        title: CommonLabels.CreateTicketsConfirmTitle,
        content: CommonLabels.CreateTicketsConfirmContent.replace('#', nModifiedActionItems.current),
        confirmButtonText: CommonLabels.CreateTickets,
        action: perActionItemAction,
      };
    } else {
      const createPerImageAction = () => {
        setIsConfirmModalShown(false);
        setIsCreateTicketModalShown(true);
        vulnerabilityTicketMode.current = 'per_image';
      };
      selectedAction.current = {
        title: CommonLabels.CreateTicketsConfirmTitle,
        content: `${CommonLabels.CreateTicketsMultipleConfirmContentSingleImageTicket.replace('#', nModifiedActionItems.current)}`,
        confirmationButtons: [
          hasTicket
            ? {
                text: CommonLabels.ViewImageTicket,
                action: handleViewTicket,
              }
            : {
                text: CommonLabels.CreateTicketsPerImageSingleImage,
                action: createPerImageAction,
              },
          {
            text: CommonLabels.CreateTicketsPerActionItem,
            action: perActionItemAction,
          },
        ],
      };
    }
    setIsConfirmModalShown(true);
  };

  const getCreateTicketNumberOfModifiedAI = async (mode: string): Promise<string> => {
    let response;
    try {
      let url = `/v0/organizations/${organization.Name}/images/vulnerabilities/tickets?dryRun=true`;
      if (mode) {
        url = `${url}&mode=${mode}`;
      }
      response = await sendRequest(
        'PATCH',
        url,
        {
          data: {
            Provider: 'Jira',
            ProjectName: '',
            Labels: ['bug'],
            imageSHAs: [sha],
          },
        },
        null,
      );
    } catch (e) {
      logger.logError(
        'react-vulnerabilities-image-detail:error_getting_number_of_modified_action_items_for_creating_tickets',
        e,
      );
      toast.error(<b>Error getting the number of modified Action Items. Please try again.</b>);
    }
    return String(response.TotalAffected);
  };

  const onResolutionHandled = async (resolution: { value: string; label: string }) => {
    await getNumberOfModifiedAIUR();
    selectedAction.current = {
      title: CommonLabels.ResolveItemsConfirmTitle,
      content: CommonLabels.ResolveItemsConfirmContent.replace(CommonLabels.Hash, nModifiedActionItems.current),
      confirmButtonText: CommonLabels.UpdateResolution,
      action: () => {
        setIsConfirmModalShown(false);
        handleResolution();
      },
    };
    selectedResolution.current = resolution.value;
    setIsConfirmModalShown(true);
  };

  const getNumberOfModifiedAIUR = async () => {
    let response;
    try {
      response = await await handleResolutionSelection('', sha, baseURL, true);
    } catch (e) {
      logger.logError(
        'react-vulnerabilities-image-detail:error_getting_number_of_modified_action_items_for_updating-resolution',
        e,
      );
      toast.error(<b>Error getting the number of modified Action Items. Please try again.</b>);
    }
    nModifiedActionItems.current = String(response.TotalAffected);
  };

  const handleResolution = async () => {
    try {
      const response = await handleResolutionSelection(selectedResolution.current, sha, baseURL);
      toast.success(
        <b>{CommonLabels.UpdateResolveSuccessMessage.replace(CommonLabels.Hash, String(response.TotalAffected))}</b>,
      );
      logger.logEvent('react-vulnerabilities-image-detail:resolve-item', {
        imageSHAs: [sha],
        resolution: selectedResolution.current,
      });
    } catch (e) {
      logger.logError('error_updating_resolution', e);
      toast.error(<b>Error updating the resolution. Please try again.</b>);
    }
  };

  const onAssigneeHandled = async (email: string) => {
    await getNumberOfModifiedAIUA();
    selectedAction.current = {
      title: CommonLabels.AssignItemsConfirmTitle,
      content: CommonLabels.AssignItemsConfirmContent.replace(CommonLabels.Hash, nModifiedActionItems.current),
      confirmButtonText: CommonLabels.UpdateAssignee,
      action: () => {
        setIsConfirmModalShown(false);
        handleAssignee();
      },
    };
    selectedEmail.current = email;
    setIsConfirmModalShown(true);
  };

  const getNumberOfModifiedAIUA = async () => {
    let response;
    try {
      response = await handleAssigneeSelection('', sha, baseURL, true);
    } catch (e) {
      logger.logError(
        'react-vulnerabilities-image-detail:error_getting_number_of_modified_action_items_for_updating-assignee',
        e,
      );
      toast.error(<b>Error getting the number of modified Action Items. Please try again.</b>);
    }
    nModifiedActionItems.current = String(response.TotalAffected);
  };

  const handleAssignee = async () => {
    try {
      const response = await handleAssigneeSelection(selectedEmail.current, sha, baseURL);
      if (selectedEmail.current) {
        toast.success(
          <b>{CommonLabels.UpdateAssigneeSuccessMessage.replace(CommonLabels.Hash, String(response.TotalAffected))}</b>,
        );
      } else {
        toast.success(<b>Action item has been unassigned</b>);
      }
      logger.logEvent('react-vulnerabilities-image-detail:assign-item', {
        imageSHAs: [sha],
        assigneeEmail: selectedEmail.current?.length ? selectedEmail.current : null,
      });
      fetchImageDetailItemsDebounced();
    } catch (e) {
      toast.error(<b>Error updating the assignee. Please try again.</b>);
      logger.logError('error_updating_assignee', e);
    }
  };

  const handleExport = async (action: string) => {
    const searchParams = new URLSearchParams(window.location.search);
    const rawImageId = searchParams.get(strings.vulnerabilities.imageId);
    let imageIdQuery = '';
    if (rawImageId) {
      imageIdQuery = `&imageId=${encodeURIComponent(rawImageId)}`;
    }
    exportCSV({
      action,
      exportAllCallback: () =>
        exportActionItems({
          from: EXPORT_PAGES.IMAGE_DETAIL,
          apiEndpoint: `${baseURL}/images/${sha}/vulnerabilities?${new URLSearchParams(
            `pageSize=${totalExportItems.current}${imageIdQuery}`,
          ).toString()}`,
          orgName: organization.Name,
        }),
      exportFilteredCallback: () =>
        exportActionItems({
          from: EXPORT_PAGES.IMAGE_DETAIL,
          apiEndpoint: `${baseURL}/images/${sha}/vulnerabilities?${searchParams.toString()}`,
          orgName: organization.Name,
        }),
      totalExportFilteredItems: totalExportFilteredItems.current,
      searchParams,
      logEventKey: 'react-vulnerabilities-image-detail',
    });
  };

  const getBreadcrumbImageTitle = (imageTitle: string | undefined) => {
    if (imageTitle) {
      return imageTitle?.length > 64 ? `${imageTitle.substring(0, 65)}${CommonLabels.Ellipsis}` : imageTitle;
    }
    return CommonLabels.Empty;
  };

  const org = organization.Name;
  const imageDetailRoutesList = [
    {
      id: ORG_DASHBOARD,
      label: org,
      href: `/orgs/${org}/dashboard`,
    },
    {
      id: 'react-vulnerabilities-all-images',
      label: strings.navigation.Vulnerabilities,
      href: `/orgs/${org}/vulnerabilities/all-images`,
    },
    {
      id: 'react-vulnerabilities-all-images',
      label: strings.navigation.allImages,
      href: `/orgs/${org}/vulnerabilities/all-images`,
    },
    {
      id: 'last',
      label: getBreadcrumbImageTitle(imageTitle),
      href: ``,
      isActive: true,
    },
  ];

  const lastScanned = useMemo(() => {
    if (!imageSummary?.lastScanned) {
      return null;
    }

    const timeZone = getCurrentTimezone();

    const day = timeZone
      ? dayjs(imageSummary.lastScanned).tz(timeZone).format('MM/DD/YYYY')
      : dayjs(imageSummary.lastScanned).format('MM/DD/YYYY');
    const time = timeZone
      ? dayjs(imageSummary.lastScanned).tz(timeZone).format('h:mm A')
      : dayjs(imageSummary.lastScanned).format('h:mm A');

    return `${day} ${strings.general.at} ${time}`;
  }, [imageSummary]);

  return (
    <LayoutReact>
      <Toaster />
      <div>
        <div className="image-detail-header-container">
          <div className="image-detail-breadcrumb">
            <div className="vulnerabilities-all-images-breadcrumbs">
              <Breadcrumbs
                data={imageDetailRoutesList}
                onClick={(route: string) => {
                  handlePageChange(router, route);
                }}
              />
            </div>
          </div>
          <div className="image-detail-actions" data-cy="image-vulnerability-action-button">
            <button onClick={(e) => showContextMenu({ event: e, props: {} })}>
              <img src={HorizontalEllipsis} alt="ellipsis icon" />
            </button>
          </div>
        </div>
        <LastSyncedDate
          className="image-detail-last-synced-date"
          noneSyncedDateTitle={
            <>
              <strong className="image-detail__non-synced-date-title">{strings.vulnerabilities.lastScanned}:</strong>
              {strings.general.unavailable}
            </>
          }
          syncedDate={lastScanned || undefined}
          syncedDateTitle={strings.vulnerabilities.lastScanned}
          noneSyncedCircleClassname="image-detail__non-synced-date-circle"
        />
        <div className="image-detail-container">
          <div className="image-detail-left">
            <ImpactedWorkloadsTable
              orgName={organization.Name}
              imageSummary={imageSummary}
              loaded={isImageSummaryLoaded}
            />
            <ImpactedRepositories
              orgName={organization.Name}
              imageSummary={imageSummary}
              loaded={isImageSummaryLoaded}
            />
          </div>
          <div className="image-detail-right">
            <Information imageSummary={imageSummary} loaded={isImageSummaryLoaded} />
            <RiskReduction imageSummary={imageSummary} loaded={isImageSummaryLoaded} />
          </div>
        </div>
        <div className="vulnerabilities--charts-container" aria-hidden="true">
          <div className="image-detail-vulnerabilities-action-items--container">
            <Card body className="image-detail-vulnerabilities-action-items" data-cy="cves-card">
              <TableCardHeader
                globalSearch={globalSearch}
                handleExport={handleExport}
                isHidingColsSupported
                selectedImageDetailItem={selectedImageDetailItem}
                setGlobalSearch={setGlobalSearch}
                setLoaded={setLoaded}
                tableInstance={tableInstance}
                totalRowCount={totalRowCount}
              />
              <Table
                caption={Labels.ImageDetailVulnerabilitiesItemsTable}
                tableInstance={tableInstance}
                pagination={
                  <Pagination
                    currentPage={tableInstance.state.pageIndex}
                    pageChange={handlePaginationChange}
                    totalCount={totalPage}
                  />
                }
                onDataFetched={fetchImageDetailItemsDebounced}
                onItemSelected={setSelectedImageDetailItem}
                loaded={loaded}
                contextMenu={
                  <ContextMenu
                    members={members}
                    onCreateTicketClick={showCreateTicketModal}
                    onResolutionHandled={onResolutionHandled}
                    onAssigneeHandled={onAssigneeHandled}
                    onViewTicketClick={handleViewTicket}
                  />
                }
              />
            </Card>
            {isSingleSelectionAction(selectedAction.current) ? (
              <ConfirmationDialog
                cancelButtonClasses="custom-cancel-button"
                cancelButtonText={CommonLabels.Cancel}
                confirmButtonText={selectedAction.current.confirmButtonText}
                isModalShown={isConfirmModalShown}
                modalBodyClasses="custom-confirm-modal-body"
                modalContent={selectedAction.current.content}
                modalContentClasses="custom-confirm-modal-content"
                modalTitle={selectedAction.current.title}
                onConfirmClicked={selectedAction.current.action}
                onModalHidden={(isModalShown?: boolean) => setIsConfirmModalShown(isModalShown ? true : false)}
              />
            ) : (
              selectedAction.current && (
                <MultipleConfirmationDialog
                  modelSize="lg"
                  cancelButtonClasses="custom-cancel-button"
                  cancelButtonText={CommonLabels.Cancel}
                  isModalShown={isConfirmModalShown}
                  modalBodyClasses="custom-confirm-modal-body"
                  modalContent={selectedAction.current.content}
                  modalContentClasses="custom-confirm-modal-content"
                  modalTitle={selectedAction.current.title}
                  confirmationButtons={selectedAction.current.confirmationButtons?.map((button) => ({
                    text: button.text,
                    onClick: button.action,
                  }))}
                  onModalHidden={(isModalShown?: boolean) => setIsConfirmModalShown(isModalShown ? true : false)}
                />
              )
            )}
            {isCreateTicketModalShown && (
              <CreateTicketModal
                router={router}
                closeModal={() => setIsCreateTicketModalShown(false)}
                imageSHAs={[sha]}
                isFrom={PAGES.VulnerabilitiesAllImages}
                vulnerabilityTicketMode={vulnerabilityTicketMode.current}
                logEventKey="react-vulnerabilities-image-detail:create-ticket"
                route={route}
                showModal={isCreateTicketModalShown}
              />
            )}
            <DescriptionPanel
              selectedItem={descriptionItem?.selectedItem}
              setSelectedItem={setSelectedImageDetailItem}
              page={descriptionItem?.page}
              openContextMenu={descriptionItem?.openContextMenu}
            />
          </div>
        </div>
      </div>
    </LayoutReact>
  );
};

export default ImageDetailItemsTable;
