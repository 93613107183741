import React, { useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { subMonths } from 'date-fns';
import dayjs, { Dayjs } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { getCurrentTimezone } from '~reactHelpers';

import 'react-datepicker/dist/react-datepicker.css';
import './Datepicker.react.scss';
import AccordionDropdown from '~reactComponents/Icons/AccordionDropdown.react';

dayjs.extend(utc);
dayjs.extend(isBetween);
dayjs.extend(timezone);

const DATE_PICKER_OPTIONS = [
  'Last 7 days',
  'Last 30 days',
  'Last 365 days',
  'Last month',
  'Month to date',
  'Last quarter',
  'Quarter to date',
  'Last year',
  'Year to date',
];

type DatepickerProps = {
  filter: (start?: Dayjs | Date, end?: Dayjs | Date, dateRangeOption?: string) => void;
  startDate?: string;
  endDate?: string;
  minDate?: number;
  disabled?: boolean;
  className?: string;
  noValueLabel?: string;
  utc?: boolean;
  dateOptionSelected?: string;
  setDateOptionSelected?: React.Dispatch<React.SetStateAction<string | undefined>>;
  isClearable?: boolean;
  labelClass?: string;
};

type DateProp = {
  start?: Date;
  end?: Date;
};

// code here displays in local time, not utc
// dayjs automatically puts time in local time, adding utc keeps the results in utc as retrieved from api's

const Datepicker = ({
  filter,
  startDate,
  endDate,
  minDate = 3,
  disabled = false,
  className,
  noValueLabel: emptyValueLabel = 'FILTER',
  utc,
  dateOptionSelected,
  setDateOptionSelected,
  isClearable = true,
  labelClass,
}: DatepickerProps): JSX.Element => {
  let timeZone = getCurrentTimezone();
  if (utc) {
    timeZone = null;
  }
  const [dates, setDates] = useState<DateProp>(() => ({
    start: startDate ? (timeZone ? dayjs(startDate).tz(timeZone).toDate() : dayjs(startDate).toDate()) : undefined,
    end: endDate ? (timeZone ? dayjs(endDate).tz(timeZone).toDate() : dayjs(endDate).toDate()) : undefined,
  }));

  useEffect(() => {
    if (dateOptionSelected) {
      setDatesForRangeDateOption(dateOptionSelected);
    }
  }, [dateOptionSelected]);

  useEffect(() => {
    if (!startDate || !endDate) {
      return;
    }
    const start = startDate.includes('T00:00:00.000Z')
      ? timeZone
        ? dayjs(startDate).utc().tz(timeZone).toDate()
        : dayjs(startDate).utc().toDate()
      : timeZone
        ? dayjs(startDate).local().tz(timeZone).toDate()
        : dayjs(startDate).local().toDate();
    const end = endDate.includes('T00:00:00.000Z')
      ? dayjs(endDate).utc().toDate()
      : timeZone
        ? dayjs(endDate).local().tz(timeZone).toDate()
        : dayjs(endDate).local().toDate();
    setDates({ start, end });
  }, [startDate, endDate]);

  const formatDates = (
    start: string | Date | Dayjs | undefined | null,
    end: string | Date | Dayjs | undefined | null,
  ) => {
    if (!start || !end) {
      return emptyValueLabel;
    }
    const formattedStart = start?.slice()?.includes('T00:00:00.000Z')
      ? timeZone
        ? dayjs(start).utc().tz(timeZone)
        : dayjs(start).utc()
      : timeZone
        ? dayjs(start).local().tz(timeZone)
        : dayjs(start).local();
    const formattedEnd = end?.slice()?.includes('T00:00:00.000Z')
      ? timeZone
        ? dayjs(end).utc().tz(timeZone)
        : dayjs(end).utc()
      : timeZone
        ? dayjs(end).local().tz(timeZone)
        : dayjs(end).local();
    const checkForSameDay = formattedEnd.diff(formattedStart, 'day');

    return checkForSameDay > 0
      ? `${formattedStart.format('MM/DD/YY')} - ${formattedEnd.format('MM/DD/YY')}`
      : formattedStart.format('MM/DD/YY');
  };

  const setDatesForRangeDateOption = (option: string | undefined) => {
    const start = getStartDate(option);
    const end = getEndDate(option);
    setDates({
      start: start.local().toDate(),
      end: end.local().toDate(),
    });
    filter(start.local().toDate(), end.local().toDate(), option);
  };

  const getEndDate = (option: string | undefined): dayjs.Dayjs => {
    const today = timeZone ? dayjs().tz(timeZone) : dayjs();
    switch (option) {
      case 'Last month':
        return today.subtract(1, 'month').endOf('month');
      case 'Last quarter': {
        const lasQuarter = today.subtract(3, 'month');
        const month = lasQuarter.month();
        const quarterMod = month % 3;
        return lasQuarter.subtract(quarterMod - 2, 'month').endOf('month');
      }
      case 'Last year':
        return today.subtract(1, 'year').endOf('year');
      default:
        return today.endOf('day');
    }
  };

  const getStartDate = (option: string | undefined): dayjs.Dayjs => {
    const today = timeZone ? dayjs().tz(timeZone) : dayjs();
    switch (option) {
      case 'Last 7 days':
        return today.subtract(1, 'week').startOf('day');
      case 'Last 30 days':
        return today.subtract(1, 'month').startOf('day');
      case 'Last 365 days':
        return today.subtract(1, 'year').startOf('day');
      case 'Last month':
        return today.subtract(1, 'month').startOf('month');
      case 'Month to date':
        return today.startOf('month');
      case 'Last quarter': {
        const lasQuarter = today.subtract(3, 'month');
        const month = lasQuarter.month();
        const quarterMod = month % 3;
        return lasQuarter.subtract(quarterMod, 'month').startOf('month');
      }
      case 'Quarter to date': {
        const month = today.month();
        const quarterMod = month % 3;
        return today.subtract(quarterMod, 'month').startOf('month');
      }
      case 'Last year':
        return today.subtract(1, 'year').startOf('year');
      case 'Year to date':
        return today.startOf('year');
      default:
        return today.subtract(1, 'week').startOf('day');
    }
  };

  const selectDates = (dates: unknown) => {
    if (setDateOptionSelected) {
      setDateOptionSelected(undefined);
    }
    const typedDates = dates as Date[];
    const [start, end] = typedDates;
    const today = timeZone ? dayjs().tz(timeZone) : dayjs();
    let formattedStart = timeZone ? dayjs(start).tz(timeZone) : dayjs(start);
    let formattedEnd = timeZone ? dayjs(end).tz(timeZone) : dayjs(end);

    if (formattedStart.isSame(formattedEnd)) {
      formattedStart = formattedStart.startOf('day');
      formattedEnd = formattedEnd.endOf('day');
    }

    setDates({
      start: start ? formattedStart.local().toDate() : start,
      end: end ? formattedEnd.local().toDate() : end,
    });
    filter(
      start ? formattedStart.local().toDate() : start,
      today.startOf('day').isSame(formattedEnd.startOf('day'))
        ? today.toDate()
        : end
          ? formattedEnd.local().toDate()
          : end,
      dateOptionSelected,
    );
  };

  const clear = () => {
    setDates({});
    filter();
  };

  const Calendar = () => {
    return (
      <DatePicker
        disabled={disabled}
        selected={dates.start}
        onChange={selectDates}
        startDate={dates.start}
        endDate={dates.end}
        selectsRange={true}
        minDate={subMonths(new Date(), minDate)}
        maxDate={new Date()}
        showMonthDropdown
        showYearDropdown
        scrollableMonthYearDropdown
        dayClassName={(date) => {
          if (dates.start && dates.end) {
            if (timeZone) {
              return dayjs(date)
                .utc()
                .tz(timeZone)
                .isBetween(dayjs(dates.start).utc().tz(timeZone), dayjs(dates.end).utc().tz(timeZone), 'day', '[]')
                ? 'inRange'
                : 'notInRange';
            }
            return dayjs(date).utc().isBetween(dayjs(dates.start).utc(), dayjs(dates.end).utc(), 'day', '[]')
              ? 'inRange'
              : 'notInRange';
          }
          return '';
        }}
        inline
      />
    );
  };

  return (
    <Dropdown className={`datepicker-dropdown-btn dropdown-btn ${disabled ? 'disabled' : ''}`}>
      <Dropdown.Toggle className={className}>
        <span className={labelClass}>{dateOptionSelected ? dateOptionSelected : formatDates(startDate, endDate)}</span>
        {(!isClearable || !dates.end) && (
          <div className="accordion-arrow">
            <AccordionDropdown />
          </div>
        )}
      </Dropdown.Toggle>
      {isClearable && dates.end && (
        <div>
          <button type="button" className="react-datepicker__close-icon" aria-label="Close" onClick={clear}></button>
        </div>
      )}
      {setDateOptionSelected ? (
        <Dropdown.Menu className="date-picker-splitter">
          <div className="datepicker-dropdown-options">
            <DatePickerOptions
              options={DATE_PICKER_OPTIONS}
              dateOptionSelected={dateOptionSelected}
              setDateOptionSelected={setDateOptionSelected}
              selectDates={setDatesForRangeDateOption}
            />
          </div>
          <Calendar />
        </Dropdown.Menu>
      ) : (
        <Dropdown.Menu>
          <Calendar />
        </Dropdown.Menu>
      )}
    </Dropdown>
  );
};

type DropdownProp = {
  options: string[];
  dateOptionSelected?: string;
  setDateOptionSelected: React.Dispatch<React.SetStateAction<string | undefined>>;
  selectDates: (option: string | undefined) => void;
};

export const DatePickerOptions = ({
  options,
  dateOptionSelected,
  setDateOptionSelected,
  selectDates,
}: DropdownProp): JSX.Element => {
  const dropdownItems = options.map((option) => {
    return (
      <Dropdown.Item
        className={dateOptionSelected === option ? 'datepicker-dropdown-option-selected' : ''}
        key={option}
        onSelect={() => {
          setDateOptionSelected(option);
          selectDates(option);
        }}
        focusFirstItemOnShow={true}
      >
        {option}
      </Dropdown.Item>
    );
  });

  return <>{dropdownItems}</>;
};

export default Datepicker;
