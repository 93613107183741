import React from 'react';
import { ResponsiveLine, Layer, Serie } from '@nivo/line';
import { ScaleSpec } from '@nivo/scales';
import { LegendProps } from '@nivo/legends';
import { AxisProps } from '@nivo/axes';
import { ValueFormat, DatumValue, Theme } from '@nivo/core';
import { OrdinalColorScaleConfig } from '@nivo/colors';

type LineChartProps = {
  data: Serie[];
  margin?: Record<string, number>;
  xScale?: ScaleSpec | undefined;
  xFormat?: ValueFormat<DatumValue>;
  yScale?: ScaleSpec | undefined;
  legends?: LegendProps[];
  axisBottom?: AxisProps | undefined;
  axisLeft?: AxisProps | undefined;
  colors?: OrdinalColorScaleConfig;
  layers?: Layer[];
  theme?: Theme;
  pointSize?: number;
  dotColor?: string;
  sliceTooltip?: (slice: any) => JSX.Element;
  tooltip?: (point: any) => JSX.Element;
};

const LineChart = ({
  data,
  margin,
  xScale,
  xFormat,
  yScale,
  legends,
  axisBottom,
  axisLeft,
  layers,
  sliceTooltip,
  tooltip,
  colors,
  pointSize,
  dotColor,
  theme,
}: LineChartProps) => {
  return (
    <ResponsiveLine
      role="figure"
      data={data}
      margin={margin}
      xScale={xScale}
      xFormat={xFormat}
      yScale={yScale}
      yFormat=" >-.2f"
      curve="monotoneX"
      legends={legends}
      axisTop={null}
      axisRight={null}
      axisBottom={axisBottom || null}
      axisLeft={axisLeft || null}
      colors={colors}
      layers={layers}
      lineWidth={2}
      theme={theme}
      enablePoints={Boolean(tooltip)}
      pointSize={pointSize ? pointSize : 7}
      pointColor={dotColor}
      pointBorderColor={dotColor}
      pointBorderWidth={2}
      pointLabelYOffset={-12}
      enableGridX={false}
      enableGridY={false}
      enableSlices={sliceTooltip ? 'x' : false}
      enableCrosshair={Boolean(sliceTooltip)}
      useMesh={Boolean(tooltip)}
      sliceTooltip={sliceTooltip ? ({ slice }) => sliceTooltip(slice) : undefined}
      tooltip={tooltip ? ({ point }) => tooltip(point) : undefined}
    />
  );
};

export default LineChart;
