import React from 'react';
import { COLORS } from '~utils/styling';

interface ExpandIconProps {
  className?: string;
  width?: string;
  height?: string;
}

function ExpandIcon(props: ExpandIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="8"
      viewBox="0 0 10 8"
      fill="none"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.757 0.00180739C9.9592 0.0379444 10.0695 0.293111 9.95167 0.481827L5.22577 7.19969C5.10791 7.34966 4.89384 7.35207 4.77391 7.19969L0.0480125 0.481827C-0.0715424 0.290501 0.0427409 0.0136517 0.273943 0H9.72574C9.7361 0.000602283 9.74645 0.0012051 9.757 0.00180739Z"
        fill={COLORS.CORE.PRIMARY}
      />
    </svg>
  );
}

export default ExpandIcon;
